import * as jsYaml from 'js-yaml';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import ErrorPage from './app/error-page';
import AppRouter from './app/router.config';
import { MainConfig } from '@wacp/context';

fetch('./config/config.yaml')
  .then((response) => response.text())
  .then((yaml) => {
    const yamlCfg: MainConfig = jsYaml.load(yaml) as MainConfig;

    ReactDOM.render(
      <StrictMode>
        <AppRouter config={yamlCfg} />
      </StrictMode>,
      document.getElementById('root')
    );
  })
  .catch(() =>
    ReactDOM.render(
      <StrictMode>
        <ErrorPage />
      </StrictMode>,
      document.getElementById('root')
    )
  );
