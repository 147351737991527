import { ApiProvider } from '@wacp/api';
import { AuthProvider } from '@wacp/auth';
import { WolkBreadcrumbsProvider } from '../breadcrumbs/breadcrumbs-provider';
import { ThemeProvider } from '../theme-provider/theme-provider';
import WolkHeader from './header/header';
import styles from './page-shell.module.scss';
import PageWrapper from './page-wrapper/page-wrapper';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ConfigContext, MainConfig } from '@wacp/context';

export function PageShell({ config }: { config: MainConfig; }) {
  console.debug('PageShell - config received: ', JSON.stringify(config));

  return (
    <ConfigContext.Provider value={config}>
      <AuthProvider>
        <ToastContainer />
        <ApiProvider>
          <ThemeProvider>
            <WolkBreadcrumbsProvider>
              <div className={`${styles['shell-wrapper']}`}>
                <WolkHeader />
                <PageWrapper />
              </div>
            </WolkBreadcrumbsProvider>
          </ThemeProvider>
        </ApiProvider>
      </AuthProvider>
    </ConfigContext.Provider>
  );
}

export default PageShell;
