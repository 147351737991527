import { useEffect, useState } from "react";
import { BaseRoute, LicenseDetails, useBreadcrumbs } from "../../../../index";
import { Customer, CustomerLicenceDetailsResponseDto, User, useRest, UserRole } from "@wacp/api";
import { useOutletContext, useParams } from "react-router-dom";
import { AuthorisedUser } from '@wacp/auth';

/* eslint-disable-next-line */
export interface LicenseInfoProps {
}

export function LicenseInfo(props: LicenseInfoProps) {
  const [license, setLicense] = useState<CustomerLicenceDetailsResponseDto>();
  const [shouldReload, setShouldReload] = useState<boolean>(false);

  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  const { setBreadcrumbs } = useBreadcrumbs();
  const { licenseId } = useParams();

  const rest = useRest();

  const setReload = () => {
    setShouldReload(true);
  }

  const fetchData = () => {
    const routeState = Number(licenseId);
    rest?.customerLicenceApi.details(routeState).then(({ data }) => {
      setLicense(data);
    });
  }

  useEffect(() => {
    if (!rest || !licenseId) {
      console.debug('License details - no api yet');
      return;
    }
    fetchData();
  }, [licenseId, rest]);

  useEffect(() => {
    if (license && !!setBreadcrumbs) {
      if (organisation && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `${BaseRoute.MY_ORGANISATION}`,
          },
          {
            name: license?.customerLicence.licenceId,
            path: `licences/${license?.customerLicence.id}`,
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: `/${BaseRoute.ORGANISATIONS}`,
          },
          {
            name: license?.customerLicence.customer.fullName,
            path: `/${BaseRoute.ORGANISATIONS}/${license?.customerLicence.customer.id}`,
          },
          {
            name: license?.customerLicence.licenceId,
            path: `${license?.customerLicence.id}`,
          },
        ]);
      }
    }
  }, [license, setBreadcrumbs]);

  useEffect(() => {
    if (shouldReload) {
      fetchData();
    }
  }, [shouldReload])

  return <LicenseDetails license={license as CustomerLicenceDetailsResponseDto} reloadPage={setReload}/>;
}

export default LicenseInfo;
