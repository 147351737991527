import { PageRequest, SortDirection } from './index';

export class CustomerPageRequest extends PageRequest {
  name: string;
  customerType: string;

  constructor(page: number, size: number, sort: string, direction: SortDirection, name: string, customerType: string) {
    super(page, size, sort, direction);

    this.name = name;
    this.customerType = customerType;
  }
}
