import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { CustomerRepo, CustomerRepoCreationDto } from './model';

export interface ICustomerRepoApiClient {
  create(data: CustomerRepoCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<CustomerRepo[]>>;
  read(id: number): Promise<ApiResponse<CustomerRepo>>;
  update(id: number, data: CustomerRepoCreationDto): Promise<ApiResponse<void>>;
}

export class CustomerRepoApi implements ICustomerRepoApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER_REPO;
    this.apiClient = apiClient;
  }

  async create(data: CustomerRepoCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<CustomerRepoCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<CustomerRepo[]>> {
    return await this.apiClient.get<CustomerRepo[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<CustomerRepo>> {
    return await this.apiClient.get<CustomerRepo>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: CustomerRepoCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<CustomerRepoCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
