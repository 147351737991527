/* eslint-disable-next-line */
import React, { useState } from 'react';
import { WolkBreadcrumb } from '../breadcrumbs/breadcrumbs';

export interface WolkBreadcrumbsContextType {
  breadcrumbs: WolkBreadcrumb[] | null;
  setBreadcrumbs: (breadcrumbs: WolkBreadcrumb[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const WolkBreadcrumbsContext = React.createContext<WolkBreadcrumbsContextType>(
  null!
);

export function WolkBreadcrumbsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [breadcrumbs, setBreadcrumbs] = useState<WolkBreadcrumb[]>([]);

  // const setBreadcrumbs = (b: WolkBreadcrumb[]) =>  setBreadcrumbsState(b);

  return (
    <WolkBreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </WolkBreadcrumbsContext.Provider>
  );
}

export function useBreadcrumbs() {
  return React.useContext(WolkBreadcrumbsContext);
}
