import { ReactNode } from 'react';
import styles from './labeled-value.module.scss';

/* eslint-disable-next-line */
export interface LabeledValueProps {
  children: ReactNode;
  label: string;
}

export function LabeledValue({ children, label }: LabeledValueProps) {
  return (
    <div className={styles['key-value']}>
      <span className={styles['key']}>{label}</span>:&nbsp;
      <span className={styles['value']}>{children}</span>
    </div>
  );
}

export default LabeledValue;
