import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { ProductCreationDto, ProductPackageResponseDto, ProductResponseDto } from './model';
import { ModuleResponseDto, Product, Version, VersionDetailedResponseDto } from '../+shared';

export interface IProductApiClient {
  create(data: ProductCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  detailedListOfProductPackagesInVersion(id: number): Promise<ApiResponse<ProductPackageResponseDto[]>>;
  details(): Promise<ApiResponse<ProductResponseDto[]>>;
  duplicateProduct(id: number): Promise<ApiResponse<number>>;
  list(): Promise<ApiResponse<Product[]>>;
  listOfModulesInProduct(id: number): Promise<ApiResponse<ModuleResponseDto[]>>;
  listOfVersions(id: number): Promise<ApiResponse<VersionDetailedResponseDto[]>>;
  listOfVersionsInProduct(id: number): Promise<ApiResponse<Version[]>>;
  read(id: number): Promise<ApiResponse<Product>>;
  update(id: number, data: ProductCreationDto): Promise<ApiResponse<void>>;
}

export class ProductApi implements IProductApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.PRODUCT;
    this.apiClient = apiClient;
  }

  async create(data: ProductCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<ProductCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async detailedListOfProductPackagesInVersion(id: number): Promise<ApiResponse<ProductPackageResponseDto[]>> {
    return await this.apiClient.get<ProductPackageResponseDto[]>(`${this.apiBase}/${id}/productPackagesInDetail`);
  }

  async details(): Promise<ApiResponse<ProductResponseDto[]>> {
    return await this.apiClient.get<ProductResponseDto[]>(`${this.apiBase}/details`);
  }

  async duplicateProduct(id: number): Promise<ApiResponse<number>> {
    return await this.apiClient.post<null, number>(`${this.apiBase}/${id}/duplicate`, null);
  }

  async list(): Promise<ApiResponse<Product[]>> {
    return await this.apiClient.get<Product[]>(this.apiBase);
  }

  async listOfModulesInProduct(id: number): Promise<ApiResponse<ModuleResponseDto[]>> {
    return await this.apiClient.get<ModuleResponseDto[]>(`${this.apiBase}/${id}/modules`);
  }

  async listOfVersions(id: number): Promise<ApiResponse<VersionDetailedResponseDto[]>> {
    return await this.apiClient.get<VersionDetailedResponseDto[]>(`${this.apiBase}/${id}/details`);
  }

  async listOfVersionsInProduct(id: number): Promise<ApiResponse<Version[]>> {
    return await this.apiClient.get<Version[]>(`${this.apiBase}/${id}/versions`);
  }

  async read(id: number): Promise<ApiResponse<Product>> {
    return await this.apiClient.get<Product>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: ProductCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<ProductCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
