import {
  CustomerLicence,
  CustomerLicencePageRequest,
  LicenceStatus,
  RenewalType,
  SortDirection,
  useRest
} from '@wacp/api';
import {
  BaseRoute,
  humanise,
  OverviewLayout,
  useBreadcrumbs,
  WolkTable,
  WolkTableAction,
  WolkTableFilter,
  WolkTablePager,
  WolkTableSortDirection,
} from '@wacp/shared-components';
import { FormEvent, useContext, useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusLg } from "react-bootstrap-icons";
import moment from "moment";
import { ConfigContext } from '@wacp/context';

/* eslint-disable-next-line */
export interface OverviewProps {}

export function Overview(props: OverviewProps) {
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rest = useRest();

  const appConfig = useContext(ConfigContext);

  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status') ?? '');
  const [expiringSoonFilter, setExpiringSoonFilter] = useState(searchParams.get('expiringSoon') === 'true');
  const [data, setData] = useState<CustomerLicence[]>([]);
  const [makeFilterSortRequest, setMakeFilterSortRequest] = useState(false);
  const [sort, setSort] = useState({
    name: searchParams.get('sort') ?? 'validToDate,licenceId',
    direction: WolkTableSortDirection.DESCENDING,
  });
  const [totalPages, setTotalPages] = useState<number>();
  const [pageProps, setPageProps] = useState({
    pageNumber: 0,
    itemsPerPage: 10
  });

  useEffect(() => {
    breadcrumbs.setBreadcrumbs([
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'Licences',
        path: BaseRoute.LICENSES,
      }
    ]);
  }, []);

  useEffect(() => {
    if (makeFilterSortRequest) {
      fetchData();
      setMakeFilterSortRequest(false);
    }
  }, [makeFilterSortRequest]);

  useEffect(() => {
    fetchData();
  }, [pageProps]);

  const openDetails = (rowData: CustomerLicence) => {
    navigate(`${rowData.id}`, { state: { customer: rowData } });
  };

  const addNewLicence = () => {
    navigate('create');
  }

  const clearFilter = () => {
    setNameFilter('');
    setStatusFilter('');
    setExpiringSoonFilter(false);
    setMakeFilterSortRequest(true);
  };

  const applyFilter = (event: FormEvent) => {
    setPageProps({
      pageNumber: 0,
      itemsPerPage: pageProps.itemsPerPage
    });
    event.preventDefault();
    setMakeFilterSortRequest(true);
  };

  const onSort = (field: string, direction: WolkTableSortDirection) => {
    setSort({
      name: field,
      direction: direction,
    });
    setMakeFilterSortRequest(true);
  };

  function fetchData() {
    const page = pageProps.pageNumber;
    const size = pageProps.itemsPerPage;
    const sortBy = sort.name;
    const sortDirection = sort.direction === WolkTableSortDirection.ASCENDING ? SortDirection.ASC : SortDirection.DESC;
    rest?.customerLicenceApi.listPaged(new CustomerLicencePageRequest(page, size, sortBy, sortDirection, nameFilter, statusFilter, expiringSoonFilter)).then(response => {
      setTotalPages(response.data.totalPages);
      setData(response.data.content);
    })
  }

  const expiringSoon = (licence: CustomerLicence) => {
    if (licence.validToDate && licence.licenceStatus === LicenceStatus.VALID) {
      const thresholdInDays = appConfig?.expiringSoonThresholdInDays ?? 7;
      const validToDate = new Date(licence.validToDate);
      const validToDateMinusThreshold = validToDate.setDate(validToDate.getDate() - thresholdInDays);
      const now = Date.now();

      const isOneTime = licence.renewalType === RenewalType.ONE_TIME;
      // Add a day to validToDate because license expires at the end of the day
      const isNotExpired = (licence.validToDate + 86400000) > now;
      const isInExpiringSoonWindow = now > validToDateMinusThreshold;
      return isOneTime && isNotExpired && isInExpiringSoonWindow;
    } else {
      return false;
    }
  };

  return (
    <div className={'layout layout-standard'}>
      <OverviewLayout
        actions={[
          <WolkTableAction icon={<PlusLg/>} onClick={addNewLicence}/>
        ]}
        filter={
          <WolkTableFilter
            onClearFilter={clearFilter}
            onApplyFilter={applyFilter}
            filterValues={[nameFilter,statusFilter, expiringSoonFilter]}
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={nameFilter}
              onChange={(event) => setNameFilter(event.target.value)}
            />
            <Form.Select
              value={statusFilter}
              onChange={(event) => {
                setStatusFilter(event.target.value);
                applyFilter(event);
              }}
            >
              <option value="">All</option>
              {Object.keys(LicenceStatus).map((value, index) => (
                <option key={`${value}_${index}`} value={value}>{humanise(value)}</option>
              ))}
            </Form.Select>
            <Form.Check
              inline
              type="checkbox"
              name="expiringSoon"
              label="Expiring soon"
              checked={expiringSoonFilter}
              onChange={(event) => setExpiringSoonFilter(event.target.checked)}
            />
          </WolkTableFilter>
        }
        table={
          <WolkTable
            columnConfigurations={[
              {
                header: 'Licence ID',
                mapper: (rowData) => rowData.licenceId,
                onSort: (direction) =>
                  onSort('licenceId', direction),
              },
              {
                header: 'Issued to',
                mapper: (rowData) => rowData.customer.fullName,
                onSort: (direction) =>
                  onSort('customer_fullName', direction),
              },
              {
                header: 'Product',
                mapper: (rowData) => rowData.product.name,
                onSort: (direction) =>
                  onSort('product_name', direction),
              },
              {
                header: 'Valid From',
                mapper: (rowData) => rowData.insertDate ? moment(rowData.validFromDate).format('DD/MM/yyyy') : '-',
                onSort: (direction) => onSort('validFromDate', direction),
              },
              {
                header: 'Expiry Date',
                mapper: (rowData) => rowData.validToDate ? moment(rowData.validToDate).format('DD/MM/yyyy') : '-',
                onSort: (direction) => onSort('validToDate', direction),
                backgroundColor: (rowData) => expiringSoon(rowData) ? 'orange' : undefined,
              },
              {
                header: 'Renewal Type',
                mapper: (rowData) => humanise(rowData.renewalType),
                onSort: (direction) =>
                  onSort('renewalType', direction),
              },
              {
                header: 'Status',
                mapper: (rowData) => humanise(rowData.licenceStatus),
                onSort: (direction) =>
                  onSort('licenceStatus', direction),
              },
              {
                header: 'Type',
                mapper: (rowData) => humanise(rowData.licenceType),
                onSort: (direction) =>
                  onSort('licenceType', direction),
              },
            ]}
            data={data}
            onRowClicked={openDetails}
          />
        }
        paging={
          !!totalPages &&
          <WolkTablePager
            onPagePropsChanged={(pageNumber: number, itemsPerPage: number) => {setPageProps({ pageNumber, itemsPerPage })}}
            totalPages={totalPages} />
        }
      />
    </div>
  );
}

export default Overview;
