/* eslint-disable-next-line */
import { EntityType, RequestBase, RequestStatus } from "@wacp/api";
import { BaseRoute, humanise, LabeledValue } from "@wacp/shared-components";
import moment from 'moment';
import { Link } from 'react-router-dom';

export interface RequestInfoProps {
  requestInfo?: RequestBase;
}

export function RequestInfo({ requestInfo }: RequestInfoProps) {

  const getCurrentVersionUrl = (entityType: EntityType, entityId: number, customerId: number): string => {
    switch (entityType) {
      case EntityType.CUSTOMER:
        return `/${BaseRoute.ORGANISATIONS}/${entityId}`;
      case EntityType.CUSTOMER_LICENCE:
        return `/${BaseRoute.ORGANISATIONS}/${customerId}/${BaseRoute.LICENSES}/${entityId}`;
      default:
        return `/${BaseRoute.ORGANISATIONS}/${customerId}/users/${entityId}`;
    }
  }

  return (
    <div>
      {
        !!requestInfo &&
        <div>
          <section>
            <h3>{ requestInfo.entityType === EntityType.CUSTOMER_LICENCE ? 'Licence Order' : 'Request Info' }</h3>
            <LabeledValue label="Status">
              {humanise(requestInfo.requestStatus)}
            </LabeledValue>
            <LabeledValue label="Type">
              {humanise(requestInfo.requestType)}
            </LabeledValue>
          </section>
          <hr/>
          <section>
            <LabeledValue label="Requested By">
              {requestInfo.insertUser ? requestInfo.insertUser.name : 'External User'}
            </LabeledValue>
            <LabeledValue label="Requested Date">
              {requestInfo.insertDate && <input type={"date"} value={moment(requestInfo.insertDate).format('yyyy-MM-DD').valueOf()} style={{border: "none"}} readOnly={true}/> }
            </LabeledValue>
            <LabeledValue label="Issued By">
              {requestInfo.updateUser ? requestInfo.updateUser.name : '-'}
            </LabeledValue>
            <LabeledValue label="Issued Date">
              {requestInfo.updateDate && <input type={"date"} value={moment(requestInfo.updateDate).format('yyyy-MM-DD').valueOf()} style={{border: "none"}} readOnly={true}/> }
            </LabeledValue>
          </section>
          <hr/>
          <section>
            {
              !!requestInfo.entityId &&
              !!requestInfo.customer &&
              !!requestInfo.customer.id &&
              requestInfo.requestStatus === RequestStatus.APPROVED &&
              <LabeledValue label="Current Licence">
                <Link to={getCurrentVersionUrl(requestInfo.entityType, requestInfo.entityId, requestInfo.customer?.id)} target="_blank" rel="noopener noreferrer">click here</Link>
              </LabeledValue>
            }
            <LabeledValue label="Comment">
              {requestInfo.comment ? requestInfo.comment : '-'}
            </LabeledValue>
          </section>
        </div>
      }
    </div>
  );
}
