import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { RenewLicenceKeyDto } from './model';

export interface IRenewableLicenceKeyApiClient {
  renew(data: RenewLicenceKeyDto): Promise<ApiResponse<string>>;
}

export class RenewableLicenceKeyApi implements IRenewableLicenceKeyApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.RENEWABLE_LICENCE_KEY;
    this.apiClient = apiClient;
  }

  async renew(data: RenewLicenceKeyDto): Promise<ApiResponse<string>> {
    return await this.apiClient.put<RenewLicenceKeyDto, string>(`${this.apiBase}/renewals`, data);
  }
}
