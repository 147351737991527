import {
  BaseRoute,
  LicenseForm,
  LicenseFormModes,
  LicenseFormObjectData,
  mapFormToUpsertDTO,
  UpsertLicenseForm,
  useBreadcrumbs,
  WolkModal,
  WolkUpsertWrapper
} from "@wacp/shared-components";
import { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  Customer,
  LicenceStatus,
  LicenceType,
  RenewalType,
  RequestLicenseDetailsDto,
  RequestLicenseUpdateDto,
  RequestRejectDto,
  RequestStatus,
  RequestType,
  User,
  useRest,
  UserRole,
} from '@wacp/api';
import { RequestInfo } from '../request-info/request-info';
import moment from 'moment';
import { AuthorisedUser } from '@wacp/auth';
import { toast } from 'react-toastify';

/* eslint-disable-next-line */
export interface LicenseDetailsProps {
}

const defaultValues: LicenseForm = {
  customer: '',
  product: undefined,
  licenseType: LicenceType.COMMERCIAL,
  endCustomer: '',
  advancedModules: [],
  requestedByUser: '',
  issuedByUser: '',
  licenseStatus: LicenceStatus.VALID,
  requestDate: moment().format('yyyy-MM-DD'),
  validFromDate: moment().format('yyyy-MM-DD'),
  expirationDate: moment().startOf("month").add(1, 'month').add(4, "day").format('yyyy-MM-DD'),
  renewalType: RenewalType.RECURRING,
  comment: '',
};

export function LicenseDetails(props: LicenseDetailsProps) {
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const navigate = useNavigate();

  const [licenseRequest, setLicenseRequest] = useState<RequestLicenseDetailsDto>();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();

  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);

  const { currentUser, authorisedUser } =
    useOutletContext<{
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  const rejectModalConfig = {
    title: 'Reject request',
    description: `Please enter a reject reason (optional):`,
    hasInput: true,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };
  const approveModalConfig = {
    title: 'Approve request',
    description: `Please enter a approve comment (optional):`,
    hasInput: true,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };

  const [requestInProgress, setRequestInProgress] = useState(false);
  const { requestId } = useParams();
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    if (rest) {
      const routeState = Number(requestId);
      rest.requestApi.getRequestLicense(routeState).then((requestResponse) => {
        setLicenseRequest(requestResponse.data);

        rest.customerApi.read(requestResponse.data.jsonData.customerId).then((customerResponse) => {
          setSelectedCustomer(customerResponse.data);
        })
      })
    }
  }, [rest, requestId]);

  useEffect(() => {
    if (licenseRequest) {
      breadcrumbs.setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Requests',
          path: BaseRoute.REQUESTS,
        },
        {
          name: 'Licence Orders',
          path: `licences/${requestId}`,
        },
      ]);
    }
  }, [licenseRequest]);

  const handleOnCancel = () => {
    navigate(-1);
  };

  const openRejectModal = () => {
    setShowRejectModal(true);
  };

  const openApproveModal = () => {
    setShowApproveModal(true);
  };

  const closeRejectModal = () => {
    setShowRejectModal(false);
  };

  const closeApproveModal = () => {
    setShowApproveModal(false);
  };

  const onRejectRequest = (formValues: any) => {
    if (licenseRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.BD) {
      setRequestInProgress(true);

      const requestRejectDto: RequestRejectDto = {
        comment: formValues.input
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .rejectRequest(routeState, requestRejectDto)
        .then((value) => {
          setRequestInProgress(false);
          formMethods.reset();
          navigate(`/${BaseRoute.REQUESTS}/licences`);
          toast('Request successfully rejected!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  }

  const handleOnUpdate = (formValue: any) => {
    if (licenseRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
      setRequestInProgress(true);

      const objectData: LicenseFormObjectData = {
        customer: selectedCustomer,
        requestedByUser: currentUser,
      };

      const dto = mapFormToUpsertDTO(formValue, objectData);

      const requestLicenseUpdateDto: RequestLicenseUpdateDto = {
        comment: licenseRequest.comment,
        modifiedByAdmin: false,
        jsonData: dto,
        insertUser: licenseRequest.insertUser?.id,
        updateUser: currentUser.id,
        requestStatus: RequestStatus.PENDING,
        requestType: licenseRequest.requestType
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .updateRequestLicense(routeState, requestLicenseUpdateDto)
        .then((value) => {
          setRequestInProgress(false);
          formMethods.reset();
          navigate(`/${BaseRoute.REQUESTS}/licences`);
          toast('Request successfully updated!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  };

  const onApproveRequest = (formValue: any) => {
    if (licenseRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.BD) {
      setRequestInProgress(true);

      const adminComment = formValue.input;

      const objectData: LicenseFormObjectData = {
        customer: selectedCustomer,
        issuedByUser: currentUser,
        requestedByUser: currentUser,
      };

      const dto = mapFormToUpsertDTO(formMethods.getValues(), objectData);

      const requestLicenseUpdateDto: RequestLicenseUpdateDto = {
        comment: adminComment ?? licenseRequest.comment,
        modifiedByAdmin: false,
        jsonData: dto,
        insertUser: licenseRequest.insertUser?.id,
        updateUser: currentUser.id,
        requestStatus: RequestStatus.APPROVED,
        requestType: licenseRequest.requestType
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .approveRequestLicense(routeState, requestLicenseUpdateDto)
        .then((value) => {
          setRequestInProgress(false);
          formMethods.reset();
          navigate(`/${BaseRoute.REQUESTS}/licences`);
          toast('Request successfully approved!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  };

  const isFormDisabled = (): boolean => {
    return !!licenseRequest && licenseRequest.requestStatus !== RequestStatus.PENDING;
  }
  const getFormMode = (): LicenseFormModes => {
    const isAdmin = authorisedUser?.role === UserRole.BD;
    return isAdmin ? LicenseFormModes.EDIT : getConsumerFormMode();
  }

  const getConsumerFormMode = (): LicenseFormModes => {
    return !!licenseRequest && licenseRequest.requestStatus === RequestStatus.PENDING && licenseRequest.requestType === RequestType.NEW_LICENCE_ORDER
      ? LicenseFormModes.EDIT : LicenseFormModes.UPGRADE;
  }

  const handleSelectedCustomer = (customer: Customer | undefined) => {
    setSelectedCustomer(customer);
  }

  return (
    <div className={`layout layout-full`}>
      <WolkModal
        openModal={showRejectModal}
        displayData={rejectModalConfig}
        onFormSubmit={onRejectRequest}
        onClose={closeRejectModal}
      />
      <WolkModal
        openModal={showApproveModal}
        displayData={approveModalConfig}
        onFormSubmit={onApproveRequest}
        onClose={closeApproveModal}
      />
      {
        !!licenseRequest &&
        !!selectedCustomer &&
        !!currentUser &&
        !!authorisedUser &&
        <WolkUpsertWrapper>
          <form className={`d-flex flex-column w-100`}>
            <Row className={`w-100 mb-3`}>
              <Col sm="3">
                <RequestInfo requestInfo={licenseRequest} />
              </Col>
              <Col sm="1"></Col>
              <Col sm="8">
                <FormProvider {...formMethods}>
                  <UpsertLicenseForm organisation={selectedCustomer}
                                     formMode={getFormMode()}
                                     user={currentUser}
                                     licenseCreationDto={licenseRequest.jsonData}
                                     defaultValues={defaultValues}
                                     onCustomerSelected={handleSelectedCustomer}
                                     isFormDisabled={isFormDisabled()}
                                     requestInsertUser={licenseRequest.insertUser}
                                     requestUpdateUser={licenseRequest.requestStatus === RequestStatus.APPROVED ? licenseRequest.updateUser : undefined}
                                     authorisedUser={authorisedUser} />
                </FormProvider>
              </Col>
            </Row>
          </form>
          <div
            className={`d-flex flex-row justify-content-end align-items-center`}
          >
            {requestInProgress && (
              <div
                className={`d-flex flex-row justify-content-center align-items-center me-1`}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <Button
              variant={`secondary`}
              disabled={requestInProgress}
              onClick={handleOnCancel}
              className={`me-1`}
            >
              Back
            </Button>
            {
              licenseRequest.requestStatus === RequestStatus.PENDING &&
              !!authorisedUser && authorisedUser.role === UserRole.CUSTOMER &&
              <Button
                type="submit"
                variant={`primary`}
                disabled={!formMethods.formState.isValid || requestInProgress}
                onClick={formMethods.handleSubmit(handleOnUpdate)}
                className={`me-1`}
              >
                Update
              </Button>
            }
            {
              licenseRequest.requestStatus === RequestStatus.PENDING &&
              !!authorisedUser && authorisedUser.role === UserRole.BD &&
              <>
                <Button
                  type="submit"
                  variant={`primary`}
                  disabled={!formMethods.formState.isValid || requestInProgress}
                  onClick={openApproveModal}
                  className={`me-1`}
                >
                  Approve
                </Button>
                <Button
                  variant={`danger`}
                  disabled={!formMethods.formState.isValid || requestInProgress}
                  onClick={openRejectModal}
                >
                  Reject
                </Button>
              </>
            }
          </div>
        </WolkUpsertWrapper>
      }
    </div>
  );
}

export default LicenseDetails;
