import { ApiResponse, IApiClient } from '../api-client';
import { CustomerCommentCreationDto, CustomerComment } from './model';
import { ApiEndpoint } from '../api-endpoint.enum';

export interface ICustomerCommentApiClient {
  create(data: CustomerCommentCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<CustomerComment[]>>;
  read(id: number): Promise<ApiResponse<CustomerComment>>;
  update(id: number, data: CustomerCommentCreationDto): Promise<ApiResponse<void>>;
}

export class CustomerCommentApi implements ICustomerCommentApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER_COMMENT;
    this.apiClient = apiClient;
  }

  async create(data: CustomerCommentCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<CustomerCommentCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<CustomerComment[]>> {
    return await this.apiClient.get<CustomerComment[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<CustomerComment>> {
    return await this.apiClient.get<CustomerComment>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: CustomerCommentCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<CustomerCommentCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
