import {
  OrganisationRequestPageRequest,
  RequestCustomerDetailsDto,
  RequestStatus,
  SortDirection,
  useRest,
  UserRole
} from "@wacp/api";
import {
  BaseRoute,
  humanise,
  OverviewLayout,
  useBreadcrumbs,
  WolkTable,
  WolkTableFilter, WolkTablePager,
  WolkTableSortDirection
} from "@wacp/shared-components";
import { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useAuth } from "@wacp/auth";

/* eslint-disable-next-line */
export interface OrganisationListProps {
}

export function OrganisationList(props: OrganisationListProps) {
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState<RequestCustomerDetailsDto[]>([]);
  const [organisationFilter, setOrganisationFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status') ?? '');
  const [makeFilterSortRequest, setMakeFilterSortRequest] = useState(true);
  const [sort, setSort] = useState({
    name: 'insertDate',
    direction: WolkTableSortDirection.DESCENDING,
  });
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [pageProps, setPageProps] = useState({
    pageNumber: 0,
    itemsPerPage: 10
  });

  useEffect(() => {
    if (auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setIsAdmin(activeUser.role === UserRole.BD);
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    breadcrumbs.setBreadcrumbs([
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'Requests',
        path: BaseRoute.REQUESTS,
      },
      {
        name: 'Organisations',
        path: 'organisations',
      }
    ]);
  }, []);

  useEffect(() => {
    if (makeFilterSortRequest) {
      fetchData();
      setMakeFilterSortRequest(false);
    }
  }, [makeFilterSortRequest]);

  useEffect(() => {
    fetchData();
  }, [pageProps]);

  const openDetails = (rowData: RequestCustomerDetailsDto) => {
    navigate(`${rowData.id}`);
  };

  const clearFilter = () => {
    setOrganisationFilter('');
    setStatusFilter('');
    setMakeFilterSortRequest(true);
  };

  const applyFilter = (event: FormEvent) => {
    setPageProps({
      pageNumber: 0,
      itemsPerPage: pageProps.itemsPerPage
    });
    event.preventDefault();
    setMakeFilterSortRequest(true);
  };

  const onSort = (field: string, direction: WolkTableSortDirection) => {
    setSort({
      name: field,
      direction: direction,
    });
    setMakeFilterSortRequest(true);
  };

  function fetchData() {
    const page = pageProps.pageNumber;
    const size = pageProps.itemsPerPage;
    const sortBy = sort.name;
    const sortDirection = sort.direction === WolkTableSortDirection.ASCENDING ? SortDirection.ASC : SortDirection.DESC;
    rest?.requestApi
      .listRequestCustomerPaged(new OrganisationRequestPageRequest(page, size, sortBy, sortDirection, organisationFilter, statusFilter))
      .then(({ data }) => {
        setTotalPages(data.totalPages);
        setData(data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={'layout layout-full p-0'}>
      <OverviewLayout
        filter={
          <WolkTableFilter
            onClearFilter={clearFilter}
            onApplyFilter={applyFilter}
            filterValues={[organisationFilter, statusFilter]}
          >
            <Form.Control
              hidden={!isAdmin}
              type="text"
              placeholder="Organisation"
              value={organisationFilter}
              onChange={(event) => setOrganisationFilter(event.target.value)}
            />
            <Form.Select
              value={statusFilter}
              onChange={(event) => {
                setStatusFilter(event.target.value);
                applyFilter(event);
              }}
            >
              <option value="">All</option>
              {Object.keys(RequestStatus).map((value, index) => (
                <option key={`${value}_${index}`} value={value} >{humanise(value)}</option>
              ))}
            </Form.Select>
          </WolkTableFilter>
        }
        table={
          <WolkTable
            columnConfigurations={[
              {
                header: 'Organisation',
                mapper: (rowData) => rowData.customerFullName ?? '-',
                onSort: (direction) => onSort('customerFullName', direction),
              },
              {
                header: 'Request Type',
                mapper: (rowData) => humanise(rowData.requestType),
                onSort: (direction) => onSort('requestType', direction),
              },
              {
                header: 'Status',
                mapper: (rowData) => humanise(rowData.requestStatus),
                onSort: (direction) => onSort('requestStatus', direction),
              },
              {
                header: 'Request Date',
                mapper: (rowData) => rowData.insertDate ? moment(rowData.insertDate).format('DD/MM/yyyy') : '-',
                onSort: (direction) => onSort('insertDate', direction),
              },
              {
                header: 'Comment',
                mapper: (rowData) => rowData.comment,
                onSort: (direction) => onSort('comment', direction),
              },
            ]}
            data={data}
            onRowClicked={openDetails}
          />
        }
        paging={
          !!totalPages &&
          <WolkTablePager
            onPagePropsChanged={(pageNumber: number, itemsPerPage: number) => {setPageProps({ pageNumber, itemsPerPage })}}
            totalPages={totalPages} />
        }
      />
    </div>
  );
}

export default OrganisationList;
