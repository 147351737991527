import { ApiResponse, IApiClient, PageResponse } from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { CustomerCreationDto, LicenceCreationDto, PageRequest } from '../+shared';
import {
  RequestCustomerDetailsDto,
  RequestCustomerUpdateDto,
  RequestLicenseDetailsDto, RequestLicenseUpdateDto,
  RequestRejectDto, RequestUserDetailsDto, RequestUserUpdateDto
} from './model';
import { UserCreationDto } from '../user';

export interface IRequestApiClient {
  createRequestCustomer(data: CustomerCreationDto): Promise<ApiResponse<number>>;
  createChangeRequestCustomer(id: number, data: CustomerCreationDto): Promise<ApiResponse<number>>;
  createRequestLicense(data: LicenceCreationDto): Promise<ApiResponse<number>>;
  createChangeRequestLicense(id: number, data: LicenceCreationDto): Promise<ApiResponse<number>>;
  createRequestUser(data: UserCreationDto): Promise<ApiResponse<number>>;
  createChangeRequestUser(id: number, data: UserCreationDto): Promise<ApiResponse<number>>;
  approveRequestCustomer(id: number, data: RequestCustomerUpdateDto): Promise<ApiResponse<void>>;
  approveRequestLicense(id: number, data: RequestLicenseUpdateDto): Promise<ApiResponse<void>>;
  updateRequestLicense(id: number, data: RequestLicenseUpdateDto): Promise<ApiResponse<void>>;
  approveRequestUser(id: number, data: RequestUserUpdateDto): Promise<ApiResponse<void>>;
  getRequestCustomer(id: number): Promise<ApiResponse<RequestCustomerDetailsDto>>;
  getRequestLicense(id: number): Promise<ApiResponse<RequestLicenseDetailsDto>>;
  getRequestUser(id: number): Promise<ApiResponse<RequestUserDetailsDto>>;
  listRequestCustomer(): Promise<ApiResponse<RequestCustomerDetailsDto[]>>;
  listRequestCustomerPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<RequestCustomerDetailsDto[]>>>;
  listRequestLicense(): Promise<ApiResponse<RequestLicenseDetailsDto[]>>;
  listRequestLicensePaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<RequestLicenseDetailsDto[]>>>;
  listRequestUser(): Promise<ApiResponse<RequestUserDetailsDto[]>>;
  rejectRequest(id: number, data: RequestRejectDto): Promise<ApiResponse<void>>;
  countNewOrganisations(): Promise<ApiResponse<number>>;
  countNewLicences(): Promise<ApiResponse<number>>;
  countNewUsers(): Promise<ApiResponse<number>>;
  countNewOrganisationsForCustomer(id: number): Promise<ApiResponse<number>>;
  countNewLicencesForCustomer(id: number): Promise<ApiResponse<number>>;
  countNewUsersForCustomer(id: number): Promise<ApiResponse<number>>;
}

export class RequestApi implements IRequestApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.REQUEST;
    this.apiClient = apiClient;
  }

  async createRequestCustomer(data: CustomerCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<CustomerCreationDto, number>(
      `${this.apiBase}/createCustomers`,
      data
    );
  }

  async createChangeRequestCustomer(id: number, data: CustomerCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.put<CustomerCreationDto, number>(
      `${this.apiBase}/updateCustomers/${id}`,
      data
    );
  }

  async createRequestLicense(data: LicenceCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<LicenceCreationDto, number>(
      `${this.apiBase}/createLicences`,
      data
    );
  }

  async createChangeRequestLicense(id: number, data: LicenceCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.put<LicenceCreationDto, number>(
      `${this.apiBase}/updateLicences/${id}`,
      data
    );
  }

  async createRequestUser(data: UserCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<UserCreationDto, number>(
      `${this.apiBase}/createUsers`,
      data
    );
  }

  async createChangeRequestUser(id: number, data: UserCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.put<UserCreationDto, number>(
      `${this.apiBase}/updateUsers/${id}`,
      data
    );
  }

  async approveRequestCustomer(id: number, data: RequestCustomerUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RequestCustomerUpdateDto, void>(
      `${this.apiBase}/${id}/approveCustomers`,
      data
    );
  }

  async approveRequestLicense(id: number, data: RequestLicenseUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RequestLicenseUpdateDto, void>(
      `${this.apiBase}/${id}/approveLicences`,
      data
    );
  }

  async updateRequestLicense(id: number, data: RequestLicenseUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RequestLicenseUpdateDto, void>(
      `${this.apiBase}/${id}/updateLicences`,
      data
    );
  }

  async approveRequestUser(id: number, data: RequestUserUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RequestUserUpdateDto, void>(
      `${this.apiBase}/${id}/approveUsers`,
      data
    );
  }

  async getRequestCustomer(id: number): Promise<ApiResponse<RequestCustomerDetailsDto>> {
    return await this.apiClient.get<RequestCustomerDetailsDto>(`${this.apiBase}/${id}/getCustomers`);
  }

  async getRequestLicense(id: number): Promise<ApiResponse<RequestLicenseDetailsDto>> {
    return await this.apiClient.get<RequestLicenseDetailsDto>(`${this.apiBase}/${id}/getLicences`);
  }

  async getRequestUser(id: number): Promise<ApiResponse<RequestUserDetailsDto>> {
    return await this.apiClient.get<RequestUserDetailsDto>(`${this.apiBase}/${id}/getUsers`);
  }

  async listRequestCustomer(): Promise<ApiResponse<RequestCustomerDetailsDto[]>> {
    return await this.apiClient.get<RequestCustomerDetailsDto[]>(`${this.apiBase}/getCustomers`);
  }

  async listRequestCustomerPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<RequestCustomerDetailsDto[]>>> {
    return await this.apiClient.get<PageResponse<RequestCustomerDetailsDto[]>>(`${this.apiBase}/getCustomers/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async listRequestLicense(): Promise<ApiResponse<RequestLicenseDetailsDto[]>> {
    return await this.apiClient.get<RequestLicenseDetailsDto[]>(`${this.apiBase}/getLicences`);
  }

  async listRequestLicensePaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<RequestLicenseDetailsDto[]>>> {
    return await this.apiClient.get<PageResponse<RequestLicenseDetailsDto[]>>(`${this.apiBase}/getLicences/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async listRequestUser(): Promise<ApiResponse<RequestUserDetailsDto[]>> {
    return await this.apiClient.get<RequestUserDetailsDto[]>(`${this.apiBase}/getUsers`);
  }

  async rejectRequest(id: number, data: RequestRejectDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RequestRejectDto, void>(
      `${this.apiBase}/${id}/rejectRequest`,
      data
    );
  }

  async countNewOrganisations(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestCustomers`);
  }

  async countNewLicences(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestLicences`);
  }

  async countNewUsers(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestUsers`);
  }

  async countNewOrganisationsForCustomer(id: number): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestCustomers/${id}`);
  }

  async countNewLicencesForCustomer(id: number): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestLicences/${id}`);
  }

  async countNewUsersForCustomer(id: number): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/countPendingRequestUsers/${id}`);
  }
}
