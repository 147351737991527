import { PageRequest, SortDirection } from './index';

export class LicenceRequestPageRequest extends PageRequest {
  organisation: string;
  requestedBy: string;
  requestStatus: string;

  constructor(page: number, size: number, sort: string, direction: SortDirection, organisation: string, requestedBy: string, requestStatus: string) {
    super(page, size, sort, direction);

    this.organisation = organisation;
    this.requestedBy = requestedBy;
    this.requestStatus = requestStatus;
  }
}
