import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { InstallationStepCreationDto } from './model';
import { InstallationStep, InstallationVariable } from '../+shared';

export interface IInstallationStepApiClient {
  create(data: InstallationStepCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  findAllVariablesForStep(id: number): Promise<ApiResponse<InstallationVariable[]>>;
  getTemplateForStep(id: number): Promise<ApiResponse<string>>;
  list(): Promise<ApiResponse<InstallationStep[]>>;
  read(id: number): Promise<ApiResponse<InstallationStep>>;
  update(id: number, data: InstallationStepCreationDto): Promise<ApiResponse<void>>;
}

export class InstallationStepApi implements IInstallationStepApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.INSTALLATION_STEP;
    this.apiClient = apiClient;
  }

  async create(data: InstallationStepCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<InstallationStepCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async findAllVariablesForStep(id: number): Promise<ApiResponse<InstallationVariable[]>> {
    return await this.apiClient.get<InstallationVariable[]>(`${this.apiBase}/${id}/variables`);
  }

  async getTemplateForStep(id: number): Promise<ApiResponse<string>> {
    return await this.apiClient.get<string>(`${this.apiBase}/${id}/template`);
  }

  async list(): Promise<ApiResponse<InstallationStep[]>> {
    return await this.apiClient.get<InstallationStep[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<InstallationStep>> {
    return await this.apiClient.get<InstallationStep>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: InstallationStepCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<InstallationStepCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
