import { useEffect, useState } from "react";
import { BaseRoute, LicenseFormModes, UpsertLicense, useBreadcrumbs } from "@wacp/shared-components";
import { useNavigate } from "react-router-dom";
import { User, useRest } from "@wacp/api";
import { AuthorisedUser, useAuth } from "@wacp/auth";

/* eslint-disable-next-line */
export interface CreateLicenseProps {}

export function CreateLicense(props: CreateLicenseProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const rest = useRest();
  const navigate = useNavigate();
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'Licences',
        path: BaseRoute.LICENSES,
      },
      {
        name: 'New licence',
        path: 'create',
      },
    ]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);

  return (
    <div className={`layout layout-standard`}>
      {
        !!currentUser &&
        !!authorisedUser &&
        <UpsertLicense
          formMode={LicenseFormModes.CREATE}
          user={currentUser}
          authorisedUser={authorisedUser}
          onComplete={() => navigate(-1)}
        />
      }
    </div>
  );
}

export default CreateLicense;
