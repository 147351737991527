import {
  BaseRoute,
  EMAIL_REGEX,
  humanise,
  PHONE_REGEX,
  PHONE_REGEX_EXAMPLE,
  useBreadcrumbs,
  WolkModal,
  WolkUpsertWrapper
} from "@wacp/shared-components";
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  CustomerType, Discount,
  RequestCustomerCreationDto,
  RequestCustomerDetailsDto,
  RequestCustomerUpdateDto,
  RequestRejectDto,
  RequestStatus,
  User,
  useRest,
  UserRole,
} from '@wacp/api';
import { RequestInfo } from '../request-info/request-info';
import { toast } from 'react-toastify';
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface OrganisationDetailsProps {
}

interface OrganisationUpsertForm {
  fullName: string;
  address: string;
  customerType: string;
  partnershipLevelType?: number;
  contactPersonPhone: string;
  billingAddress: string;
  vat: string;
  comment: string;
  purposeOfApplication: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonJobTitle: string;
}

function mapDTOToOrganisationForm(customer: RequestCustomerCreationDto): OrganisationUpsertForm {
  return {
    fullName: customer.fullName,
    address: customer.address,
    customerType: customer.customerType as CustomerType,
    partnershipLevelType: customer.partnershipLevelType,
    contactPersonPhone: customer.contactPersonPhone ?? '',
    billingAddress: customer.billingAddress ?? '',
    vat: customer.vat ?? '',
    comment: customer.comment ?? '',
    purposeOfApplication: customer.purposeOfApplication ?? '',
    contactPersonName: customer.contactPersonName ?? '',
    contactPersonEmail: customer.contactPersonEmail ?? '',
    contactPersonJobTitle: customer.contactPersonJobTitle ?? ''
  }
}

function mapOrganisationFormToDTO(organisationForm: OrganisationUpsertForm, currentUser: User): RequestCustomerCreationDto {
  return {
    fullName: organisationForm.fullName,
    address: organisationForm.address,
    customerType: organisationForm.customerType as CustomerType,
    partnershipLevelType: organisationForm.partnershipLevelType,
    contactPersonPhone: organisationForm.contactPersonPhone,
    billingAddress: organisationForm.billingAddress,
    vat: organisationForm.vat,
    comment: organisationForm.comment,
    insertUser: currentUser.id,
    purposeOfApplication: organisationForm.purposeOfApplication,
    contactPersonName: organisationForm.contactPersonName,
    contactPersonEmail: organisationForm.contactPersonEmail,
    contactPersonJobTitle: organisationForm.contactPersonJobTitle
  }
}

const defaultValues: OrganisationUpsertForm = {
  fullName: '',
  address: '',
  customerType: CustomerType.PROSPECT,
  contactPersonPhone: '',
  billingAddress: '',
  vat: '',
  comment: '',
  purposeOfApplication: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonJobTitle: ''
};

export function OrganisationDetails(props: OrganisationDetailsProps) {
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const auth = useAuth();
  const navigate = useNavigate();

  const [organisationRequest, setOrganisationRequest] = useState<RequestCustomerDetailsDto>();

  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);

  const [partnershipLevelTypeList, setPartnershipLevelTypeList] = useState<Discount[]>([]);

  const { currentUser, authorisedUser } =
    useOutletContext<{
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  const rejectModalConfig = {
    title: 'Reject request',
    description: `Please enter a reject reason (optional):`,
    hasInput: true,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };
  const approveModalConfig = {
    title: 'Approve request',
    description: `Please enter a approve comment (optional):`,
    hasInput: true,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };

  const [requestInProgress, setRequestInProgress] = useState(false);
  const { requestId } = useParams();
  const {
    handleSubmit,
    reset,
    getValues,
    control,
    clearErrors,
    trigger,
    watch,
    setValue,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const customerTypeFieldValue = watch('customerType');

  useEffect(() => {
    const routeState = Number(requestId);
    rest?.requestApi.getRequestCustomer(routeState).then(({ data }) => {
      setOrganisationRequest(data);

      const mappedData = mapDTOToOrganisationForm(data.jsonData);
      reset(mappedData);

      if (data.requestStatus === RequestStatus.PENDING) {
        trigger();
      }
    });
  }, [rest, requestId]);

  useEffect(() => {
    if (organisationRequest) {
      breadcrumbs.setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Requests',
          path: BaseRoute.REQUESTS,
        },
        {
          name: organisationRequest.jsonData.fullName,
          path: `organisations/${requestId}`,
        },
      ]);
    }
  }, [organisationRequest]);

  useEffect(() => {
    // if (customerTypeFieldValue === CustomerType.PARTNER) {
    //   setValue('partnershipLevelType', PartnershipLevelType.BRONZE);
    // } else {
    //   setValue('partnershipLevelType', PartnershipLevelType.NONE);
    // }
    setValue('partnershipLevelType', undefined);
  }, [customerTypeFieldValue])

  useEffect(() => {
    fetchPartnershipLevelTypes();
  }, [])

  const handleOnCancel = () => {
    reset();
    navigate(-1);
  };

  const openRejectModal = () => {
    setShowRejectModal(true);
  };

  const openApproveModal = () => {
    setShowApproveModal(true);
  };

  const closeRejectModal = () => {
    setShowRejectModal(false);
  };

  const closeApproveModal = () => {
    setShowApproveModal(false);
  };

  const fetchPartnershipLevelTypes = () => {
    const parnershipLevelTypeId = organisationRequest?.jsonData.partnershipLevelType;
    rest?.discountApi.list().then(({ data }) => {
      setPartnershipLevelTypeList(data);
      setValue('partnershipLevelType', parnershipLevelTypeId ?? data[0].id);
    });
  }

  const onRejectRequest = (formValues: any) => {
    if (organisationRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.BD) {
      setRequestInProgress(true);

      const requestRejectDto: RequestRejectDto = {
        comment: formValues.input,
        email: organisationRequest.jsonData.contactPersonEmail
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .rejectRequest(routeState, requestRejectDto)
        .then((value) => {
          setRequestInProgress(false);
          reset();
          navigate(`/${BaseRoute.REQUESTS}/organisations`);
          toast('Request successfully rejected!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  }

  const onApproveRequest = (formValues: any) => {
    if (organisationRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.BD) {
      setRequestInProgress(true);

      const adminComment = formValues.input;
      const dto = mapOrganisationFormToDTO(getValues(), currentUser);

      const requstCustomerUpdateDto: RequestCustomerUpdateDto = {
        comment: adminComment ?? organisationRequest.comment,
        modifiedByAdmin: false,
        jsonData: dto,
        insertUser: organisationRequest.insertUser?.id,
        updateUser: currentUser.id,
        requestStatus: RequestStatus.APPROVED,
        requestType: organisationRequest.requestType
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .approveRequestCustomer(routeState, requstCustomerUpdateDto)
        .then((value) => {
          setRequestInProgress(false);
          reset();
          navigate(`/${BaseRoute.REQUESTS}/organisations`);
          toast('Request successfully approved!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  }

  const handleOnUpdate = (formValue: OrganisationUpsertForm) => {
    if (organisationRequest && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
      setRequestInProgress(true);

      const dto = mapOrganisationFormToDTO(formValue, currentUser);

      const requstCustomerUpdateDto: RequestCustomerUpdateDto = {
        comment: organisationRequest.comment,
        modifiedByAdmin: organisationRequest.modifiedByAdmin,
        jsonData: dto,
        insertUser: organisationRequest.insertUser?.id,
        updateUser: currentUser.id,
        requestStatus: RequestStatus.PENDING,
        requestType: organisationRequest.requestType
      }

      const routeState = Number(requestId);
      rest?.requestApi
        .approveRequestCustomer(routeState, requstCustomerUpdateDto)
        .then((value) => {
          setRequestInProgress(false);
          reset();
          navigate(`/${BaseRoute.REQUESTS}/organisations`);
          toast('Request successfully updated!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        })
        .catch((reason) => {
          setRequestInProgress(false);
        });
    }
  };

  const emailIsUnique = async (email: string) => {
    const response = await rest.userApi.checkIfEmailExists({ email });
    return !response.data;
  };

  const isFormDisabled = (): boolean => {
    return !!organisationRequest && organisationRequest.requestStatus !== RequestStatus.PENDING;
  }

  return (
    <div className={`layout layout-full`}>
      <WolkModal
        openModal={showRejectModal}
        displayData={rejectModalConfig}
        onFormSubmit={onRejectRequest}
        onClose={closeRejectModal}
      />
      <WolkModal
        openModal={showApproveModal}
        displayData={approveModalConfig}
        onFormSubmit={onApproveRequest}
        onClose={closeApproveModal}
      />
      <WolkUpsertWrapper>
        <form className={`d-flex flex-column w-100`}>
          <Row className={`w-100 mb-3`}>
            <Col sm="3">
              <RequestInfo requestInfo={organisationRequest} />
            </Col>
            <Col sm="1"></Col>
            <Col sm="8">
              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Organisation name:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="fullName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Organisation name"
                        onFocus={() => clearErrors("fullName")}
                        {...field}
                      />
                    )}
                  />
                  {errors['fullName']?.type === 'required' && (
                    <Form.Text className={`form-error`}>
                      Organisation name is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Organisation Type:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="customerType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Select placeholder="Organisation type" onFocus={() => clearErrors("customerType")} {...field} disabled={isFormDisabled()}>
                        {Object.values(CustomerType)
                          .filter((type) => type !== CustomerType.APPLICANT)
                          .map((type) => (
                            <option key={type} value={type}>
                              {humanise(type)}
                            </option>
                          ))}
                      </Form.Select>
                    )}
                  />
                  {errors['customerType']?.type === 'required' && (
                    <Form.Text className={`form-error`}>
                      Organisation type is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>

              {
                customerTypeFieldValue === CustomerType.PARTNER && !!partnershipLevelTypeList &&
                <Form.Group as={Row} className={`w-100 mb-3`}>
                  <Form.Label column sm="4">
                    Partnership level:
                  </Form.Label>
                  <Col sm="8">
                    <Controller
                      name="partnershipLevelType"
                      control={control}
                      render={({ field }) => (
                        <Form.Select
                          placeholder="Partnership level"
                          {...field}
                        >
                          {partnershipLevelTypeList.map((p) => (
                            <option key={`${p.partnershipLevelType}_${p.id}`} value={p.id}>
                              {p.partnershipLevelType}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                  </Col>
                </Form.Group>
              }

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Business address:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="address"
                    control={control}
                    rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Business address"
                        {...field}
                      />
                    )}
                  />
                  {errors['address']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Business address is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Billing address:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="billingAddress"
                    control={control}
                    rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Billing address"
                        onFocus={() => clearErrors("billingAddress")}
                        {...field}
                      />
                    )}
                  />
                  {errors['billingAddress']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Billing address is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Contact person name:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="contactPersonName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Contact person name"
                        onFocus={() => clearErrors("contactPersonName")}
                        {...field}
                      />
                    )}
                  />
                  {errors['contactPersonName']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Contact person name is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Contact person email:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="contactPersonEmail"
                    control={control}
                    rules={{
                      required: true,
                      pattern: EMAIL_REGEX,
                      validate: emailIsUnique
                    }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Contact person email"
                        onFocus={() => clearErrors("contactPersonEmail")}
                        {...field}
                      />
                    )}
                  />
                  {errors['contactPersonEmail']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Contact person email is required</Form.Text>
                  )}
                  {errors['contactPersonEmail']?.type === 'pattern' && (
                    <Form.Text className={`form-error`}>
                      Wrong email format
                    </Form.Text>
                  )}
                  {errors['contactPersonEmail']?.type === 'validate' && (
                    <Form.Text className={`form-error`}>
                      This email address already exists
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Contact person phone:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="contactPersonPhone"
                    control={control}
                    rules={{
                      pattern: PHONE_REGEX
                    }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder={`Include an international country code (+ or 00), e.g. ${PHONE_REGEX_EXAMPLE}`}
                        onFocus={() => clearErrors("contactPersonPhone")}
                        {...field}
                      />
                    )}
                  />
                  {errors['contactPersonPhone']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Phone number is required</Form.Text>
                  )}
                  {errors['contactPersonPhone']?.type === 'pattern' && (
                    <Form.Text className={`form-error`}>
                      Please enter a phone number that contains the international
                      country code, e.g.
                      {PHONE_REGEX_EXAMPLE}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Contact person job title:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="contactPersonJobTitle"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Contact person job title"
                        {...field}
                      />
                    )}
                  />
                  {errors['contactPersonJobTitle']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Contact person job title is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  VAT number:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="vat"
                    control={control}
                    rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="VAT number"
                        onFocus={() => clearErrors("vat")}
                        {...field}
                      />
                    )}
                  />
                  {errors['vat']?.type === 'required' && (
                    <Form.Text className={`form-error`}>VAT number is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Purpose of application:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="purposeOfApplication"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        disabled={isFormDisabled()}
                        placeholder="Purpose of application"
                        {...field}
                      />
                    )}
                  />
                  {errors['purposeOfApplication']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Purpose of application is required</Form.Text>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Note:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        rows={10}
                        cols={10}
                        as="textarea"
                        disabled={isFormDisabled()}
                        placeholder="Add a note regarding the customer"
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </form>
        <div
          className={`d-flex flex-row justify-content-end align-items-center`}
        >
          {requestInProgress && (
            <div
              className={`d-flex flex-row justify-content-center align-items-center me-1`}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <Button
            variant={`secondary`}
            disabled={requestInProgress}
            onClick={handleOnCancel}
            className={`me-1`}
          >
            Back
          </Button>
          {
            organisationRequest?.requestStatus === RequestStatus.PENDING &&
            !!authorisedUser && authorisedUser.role === UserRole.CUSTOMER &&
            <Button
              type="submit"
              variant={`primary`}
              disabled={!isValid || requestInProgress}
              onClick={handleSubmit(handleOnUpdate)}
              className={`me-1`}
            >
              Update
            </Button>
          }
          {
            organisationRequest?.requestStatus === RequestStatus.PENDING &&
            !!authorisedUser && authorisedUser.role === UserRole.BD &&
            <>
              <Button
                type="submit"
                variant={`primary`}
                disabled={!isValid || requestInProgress}
                onClick={openApproveModal}
                className={`me-1`}
              >
                Approve
              </Button>
              <Button
                variant={`danger`}
                disabled={requestInProgress}
                onClick={openRejectModal}
              >
                Reject
              </Button>
            </>
          }
        </div>
      </WolkUpsertWrapper>
    </div>
  );
}

export default OrganisationDetails;
