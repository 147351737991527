import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Customer,
  CustomerLicenceDetailsResponseDto,
  LicenceStatus,
  LicenceType,
  RenewalType,
  User,
  useRest,
  UserRole
} from "@wacp/api";
import { LicenseForm, LicenseFormModes, LicenseFormObjectData, } from './upsert-license.model';
import { mapFormToUpsertDTO } from './upsert-license.utils';
import WolkUpsertWrapper from '../upsert-wrapper/upsert-wrapper';
import moment from 'moment';
import { AuthorisedUser } from '@wacp/auth';
import { toast } from 'react-toastify';
import { BaseRoute } from '../routing/base-route.enum';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { UpsertLicenseForm } from './upsert-license-form';
import { DetailsLicenseForm } from './details-license-form';

const defaultValues: LicenseForm = {
  customer: '',
  product: undefined,
  licenseType: LicenceType.COMMERCIAL,
  endCustomer: '',
  advancedModules: [],
  additionalDiscount: 0,
  requestedByUser: '',
  issuedByUser: '',
  licenseStatus: LicenceStatus.VALID,
  requestDate: moment().format('yyyy-MM-DD'),
  validFromDate: moment().format('yyyy-MM-DD'),
  expirationDate: moment().startOf("month").add(2, 'month').add(4, "day").format('yyyy-MM-DD'),
  renewalType: RenewalType.RECURRING,
  comment: '',
};

/* eslint-disable-next-line */
export interface UpsertProps {
  onComplete: () => void;
  user?: User;
  authorisedUser?: AuthorisedUser;
  formMode?: LicenseFormModes;
  license?: CustomerLicenceDetailsResponseDto;
  organisation?: Customer;
}

export function UpsertLicense({
  onComplete,
  user,
  authorisedUser,
  formMode,
  license,
  organisation,
}: UpsertProps) {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues,
  });
  const rest = useRest();
  const navigate = useNavigate();

  const handleOnCancel = () => {
    formMethods.reset();
    return onComplete();
  };

  const handleOnSubmit = (formValue: LicenseForm) => {
    let request$;
    let navigateToRequests = false;
    const isAdmin = authorisedUser?.role === UserRole.BD;
    const objectData: LicenseFormObjectData = {
      customer: selectedCustomer,
      issuedByUser: user as User,
      requestedByUser: user as User,
    };

    if (license && formMode !== LicenseFormModes.CREATE) {
      if (isAdmin) {
        request$ = () =>
          rest?.customerLicenceApi.update(
            Number(license.customerLicence.id),
            mapFormToUpsertDTO(formValue, objectData)
          ) ?? Promise.resolve();
      } else {
        navigateToRequests = true;
        request$ = () =>
          rest?.requestApi.createChangeRequestLicense(
            Number(license.customerLicence.id),
            mapFormToUpsertDTO(formValue, objectData)
          ) ?? Promise.resolve();
      }
    } else {
      if (isAdmin) {
        request$ = () =>
          rest?.customerLicenceApi.create(
            mapFormToUpsertDTO(formValue, objectData)
          ) ?? Promise.resolve();
      } else {
        navigateToRequests = true
        request$ = () =>
          rest?.requestApi.createRequestLicense(
            mapFormToUpsertDTO(formValue, objectData)
          ) ?? Promise.resolve();
      }
    }

    setRequestInProgress(true);
    request$()
      .then(({ data }) => {
        setRequestInProgress(false);

        showToast();

        if (navigateToRequests) {
          navigate(`/${BaseRoute.REQUESTS}/licences`);
        } else {
          return onComplete();
        }
      })
      .catch((error) => {
        setRequestInProgress(false);
      });
  };

  const getButtonTitle = (): string => {
    const isAdmin = authorisedUser?.role === UserRole.BD;
    let buttonTitle;
    if (isAdmin) {
      if (formMode === LicenseFormModes.CREATE) {
        buttonTitle = 'Add license';
      } else {
        buttonTitle = 'Update license'
      }
    } else {
      if (formMode === LicenseFormModes.CREATE) {
        buttonTitle = 'Create order';
      } else {
        buttonTitle = 'Create upgrade order';
      }
    }

    return buttonTitle;
  };

  const showToast = () => {
    const isAdmin = authorisedUser?.role === UserRole.BD;
    let message;

    if (isAdmin) {
      if (formMode === LicenseFormModes.CREATE) {
        message = 'Licence successfully created!';
      } else {
        message = 'Licence successfully updated!'
      }
    } else {
      if (formMode === LicenseFormModes.CREATE) {
        message = 'Request successfully created!';
      } else {
        message = 'Upgrade request successfully created!'
      }
    }

    toast(message, {
      position: 'bottom-center',
      type: 'success',
      autoClose: 3000,
    });
  };

  const handleSelectedCustomer = (customer: Customer | undefined) => {
    setSelectedCustomer(customer);
  }

  return (
    <div className={`layout layout-full p-0`}>
      <WolkUpsertWrapper>
        <FormProvider {...formMethods}>
          {
            !!license &&
            formMode === LicenseFormModes.VIEW &&
            <DetailsLicenseForm organisation={organisation}
                               user={user}
                               license={license}
                               authorisedUser={authorisedUser} />
          }
          {
            formMode !== LicenseFormModes.VIEW &&
            <UpsertLicenseForm organisation={organisation}
                               formMode={formMode}
                               user={user}
                               license={license}
                               defaultValues={defaultValues}
                               isFormDisabled={false}
                               onCustomerSelected={handleSelectedCustomer}
                               authorisedUser={authorisedUser} />
          }
        </FormProvider>
        {
          formMode !== LicenseFormModes.VIEW &&
          <div
            className={`d-flex flex-row justify-content-end align-items-center`}
          >
            {requestInProgress && (
              <div
                className={`d-flex flex-row justify-content-center align-items-center me-1`}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <Button
              variant={`secondary`}
              disabled={requestInProgress}
              onClick={handleOnCancel}
              className={`me-1`}
            >
              Cancel
            </Button>
            <Button
              variant={`primary`}
              disabled={(!formMethods.formState.isDirty && formMode !== LicenseFormModes.CREATE) || !formMethods.formState.isValid || requestInProgress}
              onClick={formMethods.handleSubmit(handleOnSubmit)}
            >
              {getButtonTitle()}
            </Button>
          </div>
        }
      </WolkUpsertWrapper>
    </div>
  );
}

export default UpsertLicense;
