import {
  CustomerLicenceDetailsResponseDto,
  LicenceCreationDto,
  LicenceStatus,
  LicenceType,
  ModulePriceListDto,
  ModuleResponseDto,
  ModuleType,
  RenewalType,
  User
} from "@wacp/api";
import { LicenseForm, LicenseFormModule, LicenseFormObjectData, } from './upsert-license.model';
import moment from 'moment';

export function mapDTOToForm(
  licenseDTO: CustomerLicenceDetailsResponseDto,
  users?: User[]
): LicenseForm {
  return {
    customer: licenseDTO.customerLicence.customer.fullName,
    product: licenseDTO.customerLicence.product.id,
    licenseType: licenseDTO.customerLicence.licenceType,
    endCustomer: licenseDTO.customerLicence.endCustomer,
    advancedModules: [],
    additionalDiscount: licenseDTO.customerLicence.additionalDiscount,
    requestedByUser: '',
    issuedByUser: '',
    licenseStatus: licenseDTO.customerLicence.licenceStatus,
    requestDate: moment(licenseDTO.customerLicence.requestDate).format(
      'yyyy-MM-DD'
    ),
    validFromDate: licenseDTO.licenceKey[0]?.validFromDate ? moment(licenseDTO.licenceKey[0].validFromDate).format(
      'yyyy-MM-DD'
    ) : '',
    expirationDate: licenseDTO.customerLicence.validToDate ? moment(licenseDTO.customerLicence.validToDate).format(
      'yyyy-MM-DD'
    ): '',
    renewalType: licenseDTO.customerLicence.renewalType,
    comment: licenseDTO.customerLicence.comment,
  };
}

export function mapCreationDTOToForm(
  licenseCreationDto: LicenceCreationDto,
): LicenseForm {
  return {
    customer: licenseCreationDto.customerName,
    product: licenseCreationDto.productId,
    licenseType: licenseCreationDto.licenceType,
    endCustomer: licenseCreationDto.endCustomer,
    advancedModules: [],
    additionalDiscount: licenseCreationDto.additionalDiscount,
    requestedByUser: '',
    issuedByUser: '',
    licenseStatus: licenseCreationDto.licenceStatus,
    requestDate: moment(licenseCreationDto.requestDate).format(
      'yyyy-MM-DD'
    ),
    validFromDate: licenseCreationDto.validFromDate ? moment(licenseCreationDto.validFromDate).format(
      'yyyy-MM-DD'
    ) : '',
    expirationDate: licenseCreationDto.validToDate ? moment(licenseCreationDto.validToDate).format(
      'yyyy-MM-DD'
    ): '',
    renewalType: licenseCreationDto.renewalType,
    comment: licenseCreationDto.comment,
  };
}

export function mapModulesDtoToForm(
  modules: ModulePriceListDto[],
  licenseModules?: ModuleResponseDto[]
): LicenseFormModule[] {
  return modules.map((modulePriceListDto) => {
    const licenseModule = licenseModules?.find(licenseModule => licenseModule.id === modulePriceListDto.module.id);
    const isBasicModule = modulePriceListDto.module.moduleType === ModuleType.BASIC;

    return {
      module: modulePriceListDto.module,
      value: !!licenseModule || isBasicModule,
      price: modulePriceListDto?.modulePriceList?.price || 0,
      discountPrice: modulePriceListDto?.discountPrice || 0,
    };
  });
}

export function mapFormToUpsertDTO(
  licenseFormValues: LicenseForm,
  objectData: LicenseFormObjectData
): LicenceCreationDto {
  return {
    productId: licenseFormValues.product ?? 0,
    licenceType: licenseFormValues.licenseType as LicenceType,
    renewalType: licenseFormValues.renewalType as RenewalType,
    licenceStatus: licenseFormValues.licenseStatus as LicenceStatus,
    customerId: objectData?.customer?.id ?? 0,
    customerName: objectData?.customer?.fullName ?? '',
    comment: licenseFormValues.comment,
    userId: objectData?.requestedByUser?.id ?? 0,
    updateUser: objectData?.requestedByUser?.id ?? 0,
    issuedByUser: objectData?.issuedByUser?.id ?? 0,
    insertUser: objectData?.issuedByUser?.id ?? 0,
    requestDate: Date.parse(licenseFormValues.requestDate),
    validFromDate: Date.parse(licenseFormValues.validFromDate),
    validToDate: Date.parse(licenseFormValues.expirationDate),
    modules: licenseFormValues.advancedModules
      ? licenseFormValues.advancedModules
          ?.filter((module) => module.value)
          ?.map((module) => module.module.id)
      : [],
    additionalDiscount: licenseFormValues.additionalDiscount,
    licenceTypeId: licenseFormValues.licenseType as LicenceType,
    endCustomer: licenseFormValues.endCustomer ?? ''
  };
}
