import { ReactElement, ReactNode, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function TabulatedLayout({
  children,
  outletContext
}: {
  children: ReactElement | ReactElement[];
  outletContext: any
}) {
  const navigate = useNavigate();
  if (!children) {
    throw Error('TabulatedLayout must contain at least one WolkTab child');
  }

  const tabDefs: {
    eventKey: string;
    title: string;
    disabled?: boolean;
    children: ReactNode;
    path: string;
  }[] = (Array.isArray(children) ? children : [children]).map(
    (def) => def.props
  );

  const location = useLocation();
  const initialTab = tabDefs.find(def => location.pathname.includes(def.path))?.eventKey;
  const [activeTab, activateTab] = useState(initialTab ?? tabDefs[0].eventKey);

  const changeTab = (key: string | null) => {
    const path = tabDefs.find(def => def.eventKey === key)?.path;
    if (path) {
      navigate(path);
    }
    activateTab(key ?? tabDefs[0].eventKey);
  }

  return (
    <Tabs
      variant="tabs"
      activeKey={activeTab}
      onSelect={(key) => changeTab(key)}
      className="mb-3"
    >
      {tabDefs.map((tab) => (
        <Tab
          key={tab.eventKey}
          eventKey={tab.eventKey}
          title={tab.title}
          disabled={tab.disabled ?? false}
          className="overflow-auto"
        >
          {activeTab === tab.eventKey && <Outlet context={outletContext}/>}
        </Tab>
      ))}
    </Tabs>
  );
}
