export const capitalise = (value: string): string => {
  return `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`;
};

export const titleCase = (value: string): string => {
  const splitStr = value.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const humanise = (value: string): string => {
  return capitalise((value ?? '').replaceAll('_', ' '));
};

export const compare = (first: string, second: string) => {
  return first.toLowerCase().localeCompare(second.toLowerCase());
};

export const contains = (value: string, searchPhrase: string) => {
  return !searchPhrase || (value ?? '').includes(searchPhrase);
};

export const containsIgnoreCase = (value: string, searchPhrase: string) => {
  return (
    !searchPhrase || (value ?? '').toLowerCase().includes(searchPhrase.toLowerCase())
  );
};
