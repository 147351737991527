export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export class PageRequest {
  page: string;
  size: string;
  sort: string;

  constructor(page: number, size: number, sort: string, direction: SortDirection) {
    this.page = String(page);
    this.size = String(size);
    this.sort = `${sort},${direction}`
  }
}
