import {
  BaseRoute,
  LicenseFormModes,
  UpsertLicense,
  useBreadcrumbs,
} from '../../../../index';
import { useEffect, useState } from 'react';
import { Customer, User, useRest, UserRole } from '@wacp/api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface CreateLicenseProps {}

export function CreateLicense(props: CreateLicenseProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  useEffect(() => {
    if (organisation && !!setBreadcrumbs) {
      if (currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `${BaseRoute.MY_ORGANISATION}`,
          },
          {
            name: 'Add license',
            path: 'licences/create',
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: `/${BaseRoute.ORGANISATIONS}`,
          },
          {
            name: organisation.fullName,
            path: `/${BaseRoute.ORGANISATIONS}/${organisation.id}`,
          },
          {
            name: 'Add license',
            path: 'create',
          },
        ]);
      }
    }
  }, [organisation, setBreadcrumbs]);

  return (
    <UpsertLicense
      organisation={organisation}
      formMode={LicenseFormModes.CREATE}
      user={currentUser}
      authorisedUser={authorisedUser}
      onComplete={() => navigate(-1)}
    />
  );
}

export default CreateLicense;
