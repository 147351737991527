import {Pagination, Form} from "react-bootstrap";
import { useEffect, useState } from "react";
import styles from './wolk-table.module.scss';

export interface WolkTablePagerProps {
  totalPages: number,
  onPagePropsChanged: (page: number, itemsPerPage: number) => void
}

export function WolkTablePager(props: WolkTablePagerProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const calculatePageArray = (current: number) => {
    const pages: number[] = Array.from({length: props.totalPages}, (_, i) => i);
    const pageArray: (number | null)[] = [];
    let lastWasNull = false;

    pages.forEach(page => {
      if (page === 0) {
        pageArray.push(page);
      } else if (current - 2 > page) {
        if (!lastWasNull) {
          pageArray.push(null);
        }
        lastWasNull = true;
      } else if (page + 2 >= current && page - 2 <= current) {
        pageArray.push(page);
        lastWasNull = false;
      } else if (page !== pages[pages.length -1]) {
        if (!lastWasNull) {
          pageArray.push(null);
        }
        lastWasNull = true;
      } else {
        pageArray.push(page);
      }
    });

    return pageArray;
  }

  useEffect(() => {
    setDisplayedPages(calculatePageArray(0));
  }, [props.totalPages]);

  const onPageChanged = (newPage: number) => {
    if (newPage < 0 || newPage > props.totalPages) {
      return;
    }

    setCurrentPage(newPage);
    setDisplayedPages(calculatePageArray(newPage));
    props.onPagePropsChanged(newPage, itemsPerPage);
  }

  const onItemsPerPageChanged = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    props.onPagePropsChanged(currentPage, itemsPerPage);
  }

  const [displayedPages, setDisplayedPages] = useState(calculatePageArray(currentPage));

  return (
    <Pagination className="d-flex justify-content-center">
      <Form.Select className={`${styles['paginator-items']}`} onChange={(event) => onItemsPerPageChanged(Number(event.target.value))} >
        <option>10</option>
        <option>20</option>
        <option>50</option>
        <option>100</option>
      </Form.Select>
      <Pagination.First onClick={() => onPageChanged(0)} disabled={currentPage === 0}/>
      <Pagination.Prev onClick={() => onPageChanged(currentPage - 1)} disabled={currentPage === 0}/>
      {displayedPages.map(page => {
        if (page !== null) {
          return <Pagination.Item active={page === currentPage} key={page}
                                  onClick={() => onPageChanged(page)}>{page + 1}</Pagination.Item>;
        } else {
          return <Pagination.Ellipsis/>;
        }
      })}
      <Pagination.Next onClick={() => onPageChanged(currentPage + 1)} disabled={currentPage === props.totalPages - 1}/>
      <Pagination.Last onClick={() => onPageChanged(props.totalPages - 1)}
                       disabled={currentPage === props.totalPages - 1}/>
    </Pagination>
  );
}

export default WolkTablePager;
