import { BaseRoute, TabulatedLayout, useBreadcrumbs, WolkTab, } from '@wacp/shared-components';
import { useEffect, useState } from "react";
import {User, useRest, UserRole} from '@wacp/api';
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface OverviewProps {}

export function Overview(props: OverviewProps) {
  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const auth = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          setIsAdmin(activeUser.role === UserRole.BD);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);

  useEffect(() => {
    breadcrumbs.setBreadcrumbs([
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'Requests',
        path: BaseRoute.REQUESTS,
      }
    ]);
  }, []);

  const adminTabs = <TabulatedLayout outletContext={{ currentUser, authorisedUser }}>
                      <WolkTab path={`/${BaseRoute.REQUESTS}/licences`} eventKey="licences" title="Licence Orders">
                      </WolkTab>
                      <WolkTab path={`/${BaseRoute.REQUESTS}/organisations`} eventKey="organisations" title="Organisations">
                      </WolkTab>
                    </TabulatedLayout>;

  const userTabs = <TabulatedLayout outletContext={{ currentUser, authorisedUser }}>
                      <WolkTab path={`/${BaseRoute.REQUESTS}/licences`} eventKey="licences" title="Licence Orders">
                      </WolkTab>
                    </TabulatedLayout>;

  return (
    <div className={`layout layout-standard d-flex flex-column`}>
      {
        !!currentUser &&
        !!authorisedUser &&
        isAdmin &&
        adminTabs
      }
      {
        !!currentUser &&
        !!authorisedUser &&
        !isAdmin &&
        userTabs
      }
    </div>
  );
}

export default Overview;
