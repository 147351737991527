import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { CustomerLicenceModule, CustomerLicenceModuleCreationDto } from './model';
import { CustomerLicence } from '../+shared';

export interface ICustomerLicenceModuleApiClient {
  create(data: CustomerLicence): Promise<ApiResponse<CustomerLicenceModule[]>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<CustomerLicenceModule[]>>;
  read(id: number): Promise<ApiResponse<CustomerLicenceModule>>;
  update(id: number, data: CustomerLicenceModuleCreationDto): Promise<ApiResponse<void>>;
}

export class CustomerLicenceModuleApi implements ICustomerLicenceModuleApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER_LICENCE_MODULE;
    this.apiClient = apiClient;
  }

  async create(data: CustomerLicence): Promise<ApiResponse<CustomerLicenceModule[]>> {
    return await this.apiClient.post<CustomerLicence, CustomerLicenceModule[]>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<CustomerLicenceModule[]>> {
    return await this.apiClient.get<CustomerLicenceModule[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<CustomerLicenceModule>> {
    return await this.apiClient.get<CustomerLicenceModule>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: CustomerLicenceModuleCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<CustomerLicenceModuleCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
