import style from "./overview-layout.module.scss"
import React, {ReactElement, ReactNode} from "react";
import WolkTableActions from "../table/wolk-table-actions";

export interface OverviewLayoutProps {
  actions?: ReactElement[],
  filter?: ReactNode,
  table: ReactNode,
  paging?: ReactNode
}

export function OverviewLayout(props: OverviewLayoutProps) {
  return (
    <div className={`d-flex flex-column w-100 h-100`}>
      <div className={`mb-3 d-flex flex-column flex-lg-row`}>
        {
          !!props.actions?.length &&
          <WolkTableActions>
            {props.actions.map((value, index) => React.cloneElement(value, { key: `${index}` }))}
          </WolkTableActions>
        }
        {
          !!props.actions?.length && !! props.filter &&
          <div className={`${style['horizontal-divider']} my-3 d-xs-block d-lg-none`}/>
        }
        {
          !!props.actions?.length && !! props.filter &&
          <div className={`${style['vertical-divider']} mx-3 d-xs-none d-lg-block`}/>
        }
        <div className={'flex-fill'}>
          {
            !!props.filter && props.filter
          }
        </div>
      </div>
      <div className={`overflow-hidden flex-fill`}>
        <div className={`overflow-auto h-100 w-100`}>
          {
            <div>
              { props.table }
            </div>
          }
        </div>
      </div>
      <div className={`d-flex flex-row justify-content-end align-items-center mt-3`}>
        {
          !!props.paging &&
          <div>
            { props.paging }
          </div>
        }
      </div>
    </div>
  );
}

export default OverviewLayout;
