import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { CustomerPackageCredentials, CustomerPackageCredentialsCreationDto } from './model';

export interface ICustomerPackageCredentialsApiClient {
  create(data: CustomerPackageCredentialsCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<CustomerPackageCredentials[]>>;
  read(id: number): Promise<ApiResponse<CustomerPackageCredentials>>;
  update(id: number, data: CustomerPackageCredentialsCreationDto): Promise<ApiResponse<void>>;
}

export class CustomerPackageCredentialsApi implements ICustomerPackageCredentialsApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER_PACKAGE_CREDENTIALS;
    this.apiClient = apiClient;
  }

  async create(data: CustomerPackageCredentialsCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<CustomerPackageCredentialsCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<CustomerPackageCredentials[]>> {
    return await this.apiClient.get<CustomerPackageCredentials[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<CustomerPackageCredentials>> {
    return await this.apiClient.get<CustomerPackageCredentials>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: CustomerPackageCredentialsCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<CustomerPackageCredentialsCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
