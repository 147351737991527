import { Breadcrumb } from 'react-bootstrap';
import styles from './breadcrumbs.module.scss';
import { useBreadcrumbs } from './breadcrumbs-provider';
import { Link } from 'react-router-dom';

export interface WolkBreadcrumb {
  name: string;
  path: string;
}

/* eslint-disable-next-line */
export interface BreadcrumbsProps {}

export function WolkBreadcrumbs(props: BreadcrumbsProps) {
  const breadcrumbsContext = useBreadcrumbs();

  return (
    <Breadcrumb>
      {breadcrumbsContext.breadcrumbs?.map((breadcrumb, index) => (
        <Breadcrumb.Item key={`${breadcrumb.name}_${index}`} active>
          <Link
            className={`${styles['breadcrumb-link']} ${
              breadcrumbsContext.breadcrumbs &&
              index === breadcrumbsContext.breadcrumbs.length - 1
                ? styles['disabled']
                : ''
            }`}
            to={breadcrumb.path}
          >
            {breadcrumb.name}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default WolkBreadcrumbs;
