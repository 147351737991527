import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak, { KeycloakInitOptions, KeycloakRoles } from "keycloak-js";
import React, { useContext } from "react";
import { ConfigContext } from '@wacp/context';
import { containsIgnoreCase } from '@wacp/shared-components';

//TODO: remove when BE is done with user roles
enum UserRole {
  BD = 'BD',
  CUSTOMER = 'CUSTOMER'
}

export interface AuthorisedUser {
  userName: string;
  name: string;
  role: UserRole
}

interface AuthContextType {
  logOut: () => Promise<void>;
  isAuthenticated: () => boolean;
  getActiveUser: () => Promise<AuthorisedUser | null>;
  onTokenChanged?: (token: string) => void;
}

const AUTH_STORAGE_KEY = 'auth';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  const appConfig = useContext(ConfigContext);
  if (!appConfig) {
    throw Error(
      'Auth provider - app configuration MUST contain auth configuration'
    );
  }

  //Handle refresh token rehydration
  const authStorageKey =
    (appConfig.storagePrefix ? `${appConfig.storagePrefix}-` : '') + AUTH_STORAGE_KEY;
  const refreshTokenKey = `${authStorageKey}-refresh-token`;
  const refreshToken = localStorage.getItem(refreshTokenKey) ?? undefined;
  const keyCloak = Keycloak(appConfig.authConfig);

  //handy-dandy auth functions
  const logOut = async () => keyCloak?.logout();

  const isAuthenticated = () => keyCloak?.authenticated ?? false;

  const getActiveUser = () => {
    const user = keyCloak
      ?.loadUserInfo()
      .then((response: any): AuthorisedUser | null => {

        return {
          userName: response.preferred_username,
          name: response.given_name,
          role: isAdmin(keyCloak.tokenParsed?.realm_access) ? UserRole.BD : UserRole.CUSTOMER
        }
      });

    return user ?? Promise.resolve(null);
  };

  const isAdmin = (keycloakRoles: KeycloakRoles | undefined) => {
    return keycloakRoles && keycloakRoles.roles.some(role => containsIgnoreCase(role, UserRole.BD));
  }

  const context: AuthContextType = {
    logOut,
    isAuthenticated,
    getActiveUser,
  };

  const initOptions: KeycloakInitOptions = {
    onLoad: 'login-required',
    refreshToken,
  };

  function handleTokens({
    refreshToken,
    token,
  }: {
    idToken?: string;
    refreshToken?: string;
    token?: string;
  }) {
    if (refreshToken) {
      localStorage.setItem(refreshTokenKey, refreshToken);
    }

    if (context.onTokenChanged && token) {
      context.onTokenChanged(token);
    }
  }

  return (
    <AuthContext.Provider value={context}>
      {keyCloak && (
        <ReactKeycloakProvider
          authClient={keyCloak}
          initOptions={initOptions}
          onTokens={handleTokens}
          autoRefreshToken={true}
        >
          {children}
        </ReactKeycloakProvider>
      )}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}
