import React, { ReactNode, useContext, useState } from "react";
import { IUserApiClient, UserApi } from "./user";
import { CustomerCommentApi, ICustomerCommentApiClient } from "./customer-comment";
import { CustomerLicenceApi, ICustomerLicenceApiClient } from "./customer-licence";
import { CustomerLicenceModuleApi, ICustomerLicenceModuleApiClient } from './customer-licence-module';
import { CustomerApi, ICustomerApiClient } from './customer';
import { CustomerPackageCredentialsApi, ICustomerPackageCredentialsApiClient } from './customer-package-credentials';
import { CustomerRepoApi, ICustomerRepoApiClient } from './customer-repo';
import { FeatureApi, IFeatureApiClient } from './feature';
import { IInstallationGuideApiClient, InstallationGuideApi } from './installation-guide';
import { IInstallationStepApiClient, InstallationStepApi } from './installation-step';
import {
  IInstallationVariableApiClient,
  InstallationVariableApi
} from './installation-variable';
import { ILicenceKeyApiClient, LicenceKeyApi } from './licence-key';
import { IModuleApiClient, ModuleApi } from './module';
import { IProductApiClient, ProductApi } from './product';
import { useAuth } from "@wacp/auth";
import { AxiosApiClient } from "./axios-api-client";
import { IProductPackageApiClient, ProductPackageApi } from './product-package';
import { IRenewableLicenceKeyApiClient, RenewableLicenceKeyApi } from './renewable-licence-key';
import { IRepoApiClient, RepoApi } from './repo';
import { IVersionApiClient, VersionApi } from './version';
import { ConfigContext } from '@wacp/context';
import { IRequestApiClient, RequestApi } from './request';
import { IResourceApiClient, ResourceApi } from './resource';
import { DiscountApi, IDiscountApiClient } from './discount';
import { LogsApi, ILogsApiClient } from "./logs/logs-api";

export interface Apis {
  customerApi: ICustomerApiClient,
  customerCommentApi: ICustomerCommentApiClient,
  customerLicenceApi: ICustomerLicenceApiClient,
  customerLicenceModuleApi: ICustomerLicenceModuleApiClient,
  customerPackageCredentialsApi: ICustomerPackageCredentialsApiClient,
  customerRepoApi: ICustomerRepoApiClient,
  featureApi: IFeatureApiClient,
  installationGuideApi: IInstallationGuideApiClient,
  installationStepApi: IInstallationStepApiClient,
  installationVariableApi: IInstallationVariableApiClient,
  licenceKeyApi: ILicenceKeyApiClient,
  moduleApi: IModuleApiClient,
  productApi: IProductApiClient,
  productPackageApi: IProductPackageApiClient,
  renewableLicenceKeyApi: IRenewableLicenceKeyApiClient,
  repoApi: IRepoApiClient,
  userApi: IUserApiClient,
  versionApi: IVersionApiClient,
  requestApi: IRequestApiClient,
  resourceApi: IResourceApiClient,
  discountApi: IDiscountApiClient,
  logsApi: ILogsApiClient,
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ApiContext = React.createContext<Apis>(null!);

export function ApiProvider({
  children
}: {
  children: ReactNode;
}) {
  const appConfig = useContext(ConfigContext);
  if (!appConfig?.apiConfig.baseUrl) {
    throw Error(
      'Api client - app configuration MUST contain api configuration'
    );
  }

  const auth = useAuth();
  const [authToken, setAuthToken] = useState('');
  auth.onTokenChanged = (token) => {
    setAuthToken(token)
  }

  const client = new AxiosApiClient(appConfig.apiConfig.baseUrl, authToken);

  const context: Apis = {
    customerApi: new CustomerApi(client),
    customerCommentApi: new CustomerCommentApi(client),
    customerLicenceApi: new CustomerLicenceApi(client),
    customerLicenceModuleApi: new CustomerLicenceModuleApi(client),
    customerPackageCredentialsApi: new CustomerPackageCredentialsApi(client),
    customerRepoApi: new CustomerRepoApi(client),
    featureApi: new FeatureApi(client),
    installationGuideApi: new InstallationGuideApi(client),
    installationStepApi: new InstallationStepApi(client),
    installationVariableApi: new InstallationVariableApi(client),
    licenceKeyApi: new LicenceKeyApi(client),
    moduleApi: new ModuleApi(client),
    productApi: new ProductApi(client),
    productPackageApi: new ProductPackageApi(client),
    renewableLicenceKeyApi: new RenewableLicenceKeyApi(client),
    repoApi: new RepoApi(client),
    userApi: new UserApi(client),
    versionApi: new VersionApi(client),
    requestApi: new RequestApi(client),
    resourceApi: new ResourceApi(client),
    discountApi: new DiscountApi(client),
    logsApi: new LogsApi(client),
  };

  const render = () => {
    if (authToken) {
      return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>
    } else {
      return <div/>
    }
  }

  return (
    render()
  )
}

export function useRest() {
  return React.useContext(ApiContext);
}
