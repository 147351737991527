import styles from './edit-license.module.scss';
import { useEffect, useState } from 'react';
import { CustomerLicenceDetailsResponseDto, User, useRest } from '@wacp/api';
import {
  BaseRoute,
  LicenseFormModes,
  LoadingIndicator,
  UpsertLicense,
  useBreadcrumbs,
} from '@wacp/shared-components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface EditLicenseProps {}

export function EditLicense(props: EditLicenseProps) {

  const { setBreadcrumbs } = useBreadcrumbs();
  const { license, currentUser, authorisedUser } =
    useOutletContext<{
      license: CustomerLicenceDetailsResponseDto;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (license && !!setBreadcrumbs) {
      setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Licences',
          path: `/${BaseRoute.LICENSES}`,
        },
        {
          name: license?.customerLicence.licenceId,
          path: `/${BaseRoute.LICENSES}/${license?.customerLicence.id}`,
        },
        {
          name: 'Edit',
          path: 'edit',
        },
      ]);
    }
  }, [license, setBreadcrumbs]);

  return (
    <>
      {
        !!license &&
        !!currentUser &&
        !!authorisedUser &&
        <UpsertLicense
          license={license}
          formMode={LicenseFormModes.EDIT}
          user={currentUser}
          authorisedUser={authorisedUser}
          onComplete={() => navigate(-1)}
        />
      }
      {!license && <LoadingIndicator />}
    </>
  );
}

export default EditLicense;
