import { Navigate, Route, Routes } from 'react-router-dom';
import Details from "./details/details";
import Overview from "./overview/overview";
import CreateLicense from "./create-license/create-license";
import LicenseInfo from "./details/license-info/license-info";
import EditLicense from "./details/edit-license/edit-license";
import UpgradeLicense from "./details/upgrade-license/upgrade-license";

/* eslint-disable-next-line */
export interface LicensesProps {}

export function Licenses(props: LicensesProps) {
  return (
    <Routes>
      <Route index element={<Overview />} />
      <Route path="create" element={<CreateLicense/>} />
      <Route path=":licenseId" element={<Navigate to={`info`}/>} />
      <Route path=":licenseId" element={<Details />}>
        <Route path="info" element={<LicenseInfo/>} />
        <Route path="info/edit" element={<EditLicense/>} />
        <Route path="info/upgrade" element={<UpgradeLicense/>} />
      </Route>
      <Route path="*" element={<Overview />}/>
    </Routes>
  );
}

export default Licenses;
