import { AuthorisedUser, useAuth } from '@wacp/auth';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTheme } from '../../../theme-provider/theme-provider';
import styles from './user-settings.module.scss';
import { humanise, titleCase, WolkTooltip } from '../../../../index';

/* eslint-disable-next-line */
export interface UserSettingsProps {}

export function UserSettings(props: UserSettingsProps) {
  const auth = useAuth();
  const theme = useTheme();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();

  useEffect(() => {
    if (auth) {
      auth.getActiveUser().then((user) => {
        if (user) {
          setAuthorisedUser(user);
        }
      });
    }
  }, [auth]);

  return (
    <div
      className={
        styles['settings-panel'] +
        ` p-2 d-flex flex-column justify-content-center align-items-stretch`
      }
    >
      {authorisedUser && (
        <div>
          <h4>{(authorisedUser.name) ? titleCase(authorisedUser.name) : ''}</h4>
          <WolkTooltip message={authorisedUser.userName}>
            <h6 style={{ maxWidth: '200px' }} className={styles['email']}>{authorisedUser.userName}</h6>
          </WolkTooltip>
          {/*<h6>{humanise(authorisedUser.role)}</h6>*/}
        </div>
      )}

      <div className="d-flex flex-row justify-content-between align-items-center mt-1">
        {/*<Button onClick={() => theme.changeTheme()}>Change Theme</Button>*/}
        <Button onClick={() => auth?.logOut()}>Log Out</Button>
      </div>
    </div>
  );
}

export default UserSettings;
