import { AppConfig, BaseRoute } from "@wacp/shared-components";
import React from "react";
import { RequestNotifications } from '@wacp/requests';
import { UserRole } from '@wacp/api';
import { LicenceNotifications } from '@wacp/licenses';

const MyOrganisation = React.lazy(() => import('@wacp/my-organisation'))
const Organisations = React.lazy(() => import('@wacp/organisations'))
const Licenses = React.lazy(() => import('@wacp/licenses'))
const Requests = React.lazy(() => import('@wacp/requests'))
const Resources = React.lazy(() => import('@wacp/resources'))
const Discounts = React.lazy(() => import('@wacp/discounts'))
const Logs = React.lazy(() => import('@wacp/logs'))

export const appsConfig: AppConfig[] = [
  {
    name: 'My Organisation',
    path: BaseRoute.MY_ORGANISATION,
    component: <MyOrganisation/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.CUSTOMER
    ]
  },
  {
    name: 'Organisations',
    path: BaseRoute.ORGANISATIONS,
    component: <Organisations/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'Licences',
    path: BaseRoute.LICENSES,
    component: <Licenses/>,
    notificationComponent: <LicenceNotifications path={BaseRoute.LICENSES}/>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'Resources',
    path: BaseRoute.RESOURCES,
    component: <Resources/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'My Resources',
    path: BaseRoute.RESOURCES,
    component: <Resources/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.CUSTOMER
    ]
  },
  {
    name: 'Discounts',
    path: BaseRoute.DISCOUNTS,
    component: <Discounts/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'Requests',
    path: BaseRoute.REQUESTS,
    component: <Requests/>,
    notificationComponent: <RequestNotifications path={BaseRoute.REQUESTS}/>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'My Requests',
    path: BaseRoute.REQUESTS,
    component: <Requests/>,
    notificationComponent: <RequestNotifications path={BaseRoute.REQUESTS}/>,
    visibleForRoles: [
      UserRole.CUSTOMER
    ]
  },
  {
    name: 'Event logs',
    path: BaseRoute.LOGS,
    component: <Logs/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.BD
    ]
  },
  {
    name: 'My Event logs',
    path: BaseRoute.LOGS,
    component: <Logs/>,
    notificationComponent: <></>,
    visibleForRoles: [
      UserRole.CUSTOMER
    ]
  },
]
