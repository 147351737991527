import { PageRequest, SortDirection } from './index';

export class CustomerLicencePageRequest extends PageRequest {
  searchString: string;
  licenceStatus: string;
  expiringSoon: boolean;

  constructor(page: number, size: number, sort: string, direction: SortDirection, searchString: string, licenceStatus: string, expiringSoon: boolean) {
    super(page, size, sort, direction);

    this.searchString = searchString;
    this.licenceStatus = licenceStatus;
    this.expiringSoon = expiringSoon;
  }
}
