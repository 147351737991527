import { Customer, ModuleResponseDto, Product, User } from "@wacp/api";

export interface LicenseFormModule {
  module: ModuleResponseDto;
  price: number;
  discountPrice: number;
  value: boolean;
}

export interface LicenseFormStructureData {
  products: Product[];
  licenseTypes: string[];
  licenseStatuses: string[];
}

export interface LicenseFormObjectData {
  customer?: Customer,
  issuedByUser?: User,
  requestedByUser?: User
}

export interface LicenseForm {
  customer: string;
  product?: number;
  licenseType?: string;
  renewalType?: string;
  licenseStatus?: string;
  comment: string;
  requestedByUser: string;
  issuedByUser: string;
  requestDate: string;
  validFromDate: string;
  expirationDate: string;
  advancedModules?: LicenseFormModule[];
  additionalDiscount?: number;
  endCustomer?: string;
}

export enum LicenseFormModes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  UPGRADE = 'UPGRADE',
  VIEW = 'VIEW'
}
