import { ApiResponse, IApiClient, PageResponse } from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { PageRequest } from '../+shared';
import { Logs } from '../+shared/model/logs.model';

export interface ILogsApiClient {
  listPaged(
    pageRequest: PageRequest
  ): Promise<ApiResponse<PageResponse<Logs[]>>>;
}

export class LogsApi implements ILogsApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.LOGS;
    this.apiClient = apiClient;
  }

  async listPaged(
    pageRequest: PageRequest
  ): Promise<ApiResponse<PageResponse<Logs[]>>> {
    return await this.apiClient.get<PageResponse<Logs[]>>(
      `${this.apiBase}/paged`,
      { params: new URLSearchParams({ ...pageRequest }) }
    );
  }
}
