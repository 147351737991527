import { Customer, User, UserRole } from '@wacp/api';
import { useEffect, useState } from 'react';
import { DetailsInfoDisplay } from './details-info-display/details-info-display';
import { BaseRoute, useBreadcrumbs, OrganisationUpsert } from '../../../index';
import { useOutletContext } from 'react-router-dom';
import { AuthorisedUser } from '@wacp/auth';

/* eslint-disable-next-line */
export interface OrganisationInfoProps {
  // organisation: Customer;
  // reloadPage: () => void;
}

export function DetailsInfo(props: OrganisationInfoProps) {
  const [editMode, setEditMode] = useState(false);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { organisation, currentUser, authorisedUser, reloadPage } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
      reloadPage: () => void;
    }>();

  useEffect(() => {
    if (organisation && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
      setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: `${organisation.fullName}`,
          path: BaseRoute.MY_ORGANISATION,
        },
      ]);
    } else {
      setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Organisations',
          path: BaseRoute.ORGANISATIONS,
        },
        {
          name: `${organisation.fullName}`,
          path: `${organisation.id}`,
        },
      ]);
    }
  }, [organisation, currentUser, authorisedUser, setBreadcrumbs]);

  const render = () => {
    if (organisation) {
      if (editMode) {
        return (
          <div className={`layout layout-full`}>
            <OrganisationUpsert
              organisation={organisation}
              isInViewMode={false}
              onComplete={() => {
                setEditMode(false);
                reloadPage();
              }}
            />
          </div>
        );
      } else {
        return (
          <DetailsInfoDisplay
            organisation={organisation}
            currentUser={currentUser}
            authorisedUser={authorisedUser}
            startEditing={() => setEditMode(true)}
          />
        );
      }
    } else {
      return <></>
    }
  };

  return render();
}

export default DetailsInfo;
