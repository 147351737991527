import { PageRequest, SortDirection } from './index';

export class DiscountPageRequest extends PageRequest {
  partnershipLevelType: string;

  constructor(page: number, size: number, sort: string, direction: SortDirection, name: string) {
    super(page, size, sort, direction);

    this.partnershipLevelType = name;
  }
}
