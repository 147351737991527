import { ReactElement} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function WolkTooltip({children, message}: {
  children: ReactElement;
  message: string
}) {
  if (!children) {
    throw Error('WolkTooltip must contain one child');
  }

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

export default WolkTooltip;
