import {
  CustomerLicenceDetailsResponseDto,
  LicenceStatus,
  RenewalType,
  User,
  useRest,
  UserRole,
} from '@wacp/api';
import {
  ArrowClockwise,
  ArrowUpCircle,
  Download,
  Pencil,
  Receipt,
  XCircle,
} from 'react-bootstrap-icons';
import moment from 'moment';
import { saveAs } from 'file-saver';
import WolkModal from '../modal/wolk-modal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AuthorisedUser, useAuth } from '@wacp/auth';
import WolkTooltip from '../tooltip/wolk-tooltip';
import { LicenseFormModes } from '../upsert-license/upsert-license.model';
import UpsertLicense from '../upsert-license/upsert-license';

/* eslint-disable-next-line */
export interface LicenseDetailsProps {
  license: CustomerLicenceDetailsResponseDto;
  reloadPage?: () => void;
}

export function LicenseDetails({ license, reloadPage }: LicenseDetailsProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showDownloadBillOfLadingModal, setShowDownloadBillOfLadingModal] =
    useState<boolean>(false);
  const [showRenewModal, setShowRenewModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();
  const auth = useAuth();
  const rest = useRest();
  const navigate = useNavigate();
  const deleteModalConfig = {
    title: 'Terminate license',
    description: `Are you sure you want to terminate license ${license?.customerLicence.licenceId}?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Terminate',
  };

  const renewModalConfig = {
    title: 'Renew license',
    description: `Are you sure you want to renew license ${license?.customerLicence.licenceId}?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Renew',
  };

  const downloadModalConfig = {
    title: 'Download license key',
    description: `Downloading sensitive data. Are you sure you want to download ${license?.customerLicence.licenceId} license key?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Download',
  };

  const downloadBillOfLadingModalConfig = {
    title: 'Download Licence Sheet',
    description: `Downloading sensitive data. Are you sure you want to download ${license?.customerLicence.licenceId} Licence Sheet?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Download',
  };

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          });
        }
      });
    }
  }, [rest, auth]);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const openRenewModal = () => {
    setShowRenewModal(true);
  };

  const closeRenewModal = () => {
    setShowRenewModal(false);
  };

  const openDownloadModal = () => {
    setShowDownloadModal(true);
  };

  const closeDownloadModal = () => {
    setShowDownloadModal(false);
  };

  const openDownloadBillOfLadingModal = () => {
    setShowDownloadBillOfLadingModal(true);
  };

  const closeDownloadBillOfLadingModal = () => {
    setShowDownloadBillOfLadingModal(false);
  };

  const terminateLicense = () => {
    if (currentUser) {
      rest.customerLicenceApi
        .changeLicenceStatus(license?.customerLicence.id, {
          licenceStatus: LicenceStatus.TERMINATED,
          terminationDate: moment().valueOf(),
          userId: currentUser.id,
        })
        .then(() => {
          setShowDeleteModal(false);
          if (reloadPage) reloadPage();
          toast('License successfully terminated!', {
            position: 'bottom-center',
            type: 'success',
            autoClose: 3000,
          });
        });
    }
  };

  const startEditing = () => {
    navigate(`edit`);
  };

  const startUpgrading = () => {
    navigate(`upgrade`);
  };

  const downloadLicense = () => {
    rest?.customerLicenceApi
      .downloadLicence(license?.customerLicence?.id)
      .then(({ data: key }) => {
        const fileBlob = new Blob([key.licenceKey], {
          type: 'application/txt',
        });
        saveAs(fileBlob, `license_${key.licenceKeyId}.txt`);
      });
    setShowDownloadModal(false);
  };

  const downloadBillOfLading = () => {
    rest?.customerLicenceApi.downloadBillOfLading(license?.customerLicence?.id);
    setShowDownloadBillOfLadingModal(false);
  };

  const renewLicense = () => {
    rest?.customerLicenceApi
      .renewLicence(license?.customerLicence?.licenceId)
      .then(({ data: key }) => {
        setShowRenewModal(false);
        if (reloadPage) reloadPage();
        toast('License successfully renewed!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
      });
  };

  return (
    <div>
      <WolkModal
        openModal={showDeleteModal}
        displayData={deleteModalConfig}
        onSubmit={terminateLicense}
        onClose={closeDeleteModal}
      />
      <WolkModal
        openModal={showRenewModal}
        displayData={renewModalConfig}
        onSubmit={renewLicense}
        onClose={closeRenewModal}
      />
      <WolkModal
        openModal={showDownloadModal}
        displayData={downloadModalConfig}
        onSubmit={downloadLicense}
        onClose={closeDownloadModal}
      />
      <WolkModal
        openModal={showDownloadBillOfLadingModal}
        displayData={downloadBillOfLadingModalConfig}
        onSubmit={downloadBillOfLading}
        onClose={closeDownloadBillOfLadingModal}
      />
      <div className="row gx-5">
        <div className="col d-flex flex-col justify-content-between">
          <h3>{license?.customerLicence.licenceId}</h3>
          <span>Status: {license?.customerLicence.licenceStatus}</span>
        </div>
        <div className="col d-flex flex-row justify-content-between">
          <div></div>
          <div>
            {authorisedUser?.role === UserRole.BD &&
              license?.customerLicence?.renewalType !==
              RenewalType.ONE_TIME  && 
              license?.customerLicence?.licenceStatus !==
              LicenceStatus.TERMINATED && (
                <WolkTooltip message="Renew License">
                  <ArrowClockwise
                    className={'action-item mb-1 p-1'}
                    onClick={openRenewModal}
                    role={'button'}
                    size="30"
                  />
                </WolkTooltip>
              )}
            {authorisedUser?.role === UserRole.BD &&
              license?.customerLicence?.licenceStatus !==
                LicenceStatus.TERMINATED && (
                <WolkTooltip message="Edit License">
                  <Pencil
                    className={'action-item mb-1 p-1'}
                    onClick={startEditing}
                    role={'button'}
                    size="30"
                  />
                </WolkTooltip>
              )}
            {authorisedUser?.role === UserRole.CUSTOMER &&
              license?.customerLicence?.licenceStatus !==
                LicenceStatus.TERMINATED && (
                <WolkTooltip message="Upgrade License">
                  <ArrowUpCircle
                    className={'action-item mb-1 p-1'}
                    onClick={startUpgrading}
                    role={'button'}
                    size="30"
                  />
                </WolkTooltip>
              )}
            <WolkTooltip message="Download License Key">
              <Download
                className={'action-item mb-1 p-1'}
                onClick={openDownloadModal}
                role={'button'}
                size="30"
              />
            </WolkTooltip>
            {!!license?.customerLicence?.fileName && (
              <WolkTooltip message="Download Licence Sheet">
                <Receipt
                  className={'action-item mb-1 p-1'}
                  onClick={openDownloadBillOfLadingModal}
                  role={'button'}
                  size="30"
                />
              </WolkTooltip>
            )}
            {authorisedUser?.role === UserRole.BD &&
              license?.customerLicence?.licenceStatus !==
                LicenceStatus.TERMINATED && (
                <WolkTooltip message="Terminate License">
                  <XCircle
                    className={'action-item action-item-danger mb-1 p-1'}
                    onClick={openDeleteModal}
                    role={'button'}
                    size="30"
                  />
                </WolkTooltip>
              )}
          </div>
        </div>
      </div>

      {!!license && !!currentUser && !!authorisedUser && (
        <UpsertLicense
          license={license}
          formMode={LicenseFormModes.VIEW}
          user={currentUser}
          authorisedUser={authorisedUser}
          onComplete={() => {
            return;
          }}
        />
      )}
    </div>
  );
}

export default LicenseDetails;
