/* eslint-disable-next-line */
import { Customer, User, useRest, UserRole, UserStatus } from "@wacp/api";
import { BaseRoute, humanise, LabeledValue, useBreadcrumbs, WolkModal, WolkTooltip } from "../../../../index";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthorisedUser } from '@wacp/auth';
import { Pencil, Trash } from 'react-bootstrap-icons';
import UpsertUser from '../upsert-user/upsert-user';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserInfoProps {
}

export function UserInfo(props: UserInfoProps) {
  const [user, setUser] = useState<User | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const rest = useRest();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const modalConfig = {
    title: 'Delete user',
    description: `Are you sure you want to delete user?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };

  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  useEffect(() => {
    if (user && organisation) {
      if (currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `/${BaseRoute.MY_ORGANISATION}`,
          },
          {
            name: `${user.name}`,
            path: `/${BaseRoute.MY_ORGANISATION}/${organisation.id}/users/${user.id}`,
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: BaseRoute.ORGANISATIONS,
          },
          {
            name: `${organisation?.fullName}`,
            path: `${BaseRoute.ORGANISATIONS}/${organisation.id}`,
          },
          {
            name: `${user.name}`,
            path: `${BaseRoute.ORGANISATIONS}/${organisation.id}/users/${user.id}`,
          },
        ]);
      }
    }
  }, [setBreadcrumbs, organisation, user, currentUser, authorisedUser]);

  const editUser = () => {
    setUser(null);
    navigate('edit');
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const deleteUser = () => {
    const routeState = Number(userId);
    rest.userApi.delete(routeState).then(() => {
      toast('User successfully deleted!', {
        position: 'bottom-center',
        type: 'success',
        autoClose: 3000,
      });
      navigate(-1);
    });
  };

  useEffect(() => {
    if (!rest) {
      console.debug('User details - no api yet');
      return;
    }

    fetchUser();
  }, [rest]);

  const fetchUser = () => {
    const routeState = Number(userId);
    rest?.userApi.read(routeState).then(({ data }) => {
      setUser(data);
    });
  }

  return (
    <div>
      {
        !!user &&
        <div>
          <WolkModal
            openModal={showModal}
            displayData={modalConfig}
            onSubmit={deleteUser}
            onClose={closeModal}
          />
          <div className='d-flex flex-row justify-content-between'>
            <h3>
              {user.name ? user.name : '-'}
            </h3>
            {
              user.userStatus !== UserStatus.DELETED &&
              <div>
                <WolkTooltip message='Edit User'>
                  <Pencil
                    className={'action-item mb-1 p-1'}
                    onClick={editUser}
                    role={'button'}
                    size='30'
                  />
                </WolkTooltip>
                {
                  !!authorisedUser &&
                  authorisedUser.role === UserRole.BD &&
                  <WolkTooltip message='Delete User'>
                    <Trash className={'action-item action-item-danger mb-1 p-1'} onClick={openModal} role={'button'} size='30'/>
                  </WolkTooltip>
                }
              </div>
            }
          </div>

          <UpsertUser isInViewMode={true} />
        </div>
      }
    </div>
  );
}
