import { PageRequest, SortDirection } from './index';

export class LogsPageRequest extends PageRequest {
  searchString: string;

  constructor(
    page: number,
    size: number,
    sort: string,
    direction: SortDirection,
    searchString: string
  ) {
    super(page, size, sort, direction);

    this.searchString = searchString;
  }
}
