import {Button, Form} from "react-bootstrap";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import styles from './wolk-table.module.scss';

export interface WolkTableFilterProps {
  onClearFilter: () => void,
  onApplyFilter: (event: FormEvent) => void,
  filterValues?: any[];
  children: ReactElement | ReactElement[]
}

export function WolkTableFilter(props: WolkTableFilterProps) {
  const [canResetFilter, setCanResetFilter] = useState<boolean>();

  useEffect(() => {
    setCanResetFilter(props.filterValues?.some(value => value));
  }, [props.filterValues]);

  return (
    <Form onSubmit={props.onApplyFilter}>
      <div className={`${styles['filter']} d-flex flex-column flex-lg-row justify-content-between align-items-center`}>
        <div className={`${styles['fields']} d-flex flex-row mb-3 mb-lg-0`}>
          {
            React.Children.map(props.children, (child, index) => {
              const margin = index === 0 ? '' : ' ms-3';
              return React.cloneElement(child, {
                className: `${child.props.className} ${styles['field']} ${margin}`
              });
            })
          }
        </div>
        <div className={`${styles['buttons']} d-flex flex-row`}>
          <Button variant={`primary`} className={`${styles['button']}`} onClick={props.onClearFilter} disabled={!canResetFilter}>Clear filter</Button>
          <Button type={`submit`} variant={`primary`} className={`${styles['button']} ms-3`}>Apply filter</Button>
        </div>
      </div>
    </Form>
  );
}

export default WolkTableFilter;
