import Axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { ApiResponse, IApiClient, RequestConfig } from './api-client';
import { saveAs } from 'file-saver';

export class AxiosApiClient implements IApiClient {
  private client: AxiosInstance;

  constructor(baseUrl: string, token: string) {
    this.client = Axios.create({
      baseURL: `${baseUrl}`,
      // responseType: 'json' as const, // TODO check if it's always json
      headers: {
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: 10 * 1000,
    });
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<ApiResponse<TResponse>> {
    return this.client.post<TResponse>(path, payload, config).then((response) => {
      return { data: response.data, headers: response.headers };
    }).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest
  ): Promise<ApiResponse<TResponse>> {
    return this.client.patch<TResponse>(path, payload).then((response) => {
      return { data: response.data, headers: response.headers };
    }).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<ApiResponse<TResponse>> {
    return this.client.put<TResponse>(path, payload, config).then((response) => {
      return { data: response.data, headers: response.headers };
    }).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  }

  async get<TResponse>(
    path: string,
    config?: RequestConfig
  ): Promise<ApiResponse<TResponse>> {
    return this.client.get<TResponse>(path, config).then((response) => {
      return { data: response.data, headers: response.headers };
    }).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  }

  async delete<TResponse>(path: string): Promise<ApiResponse<TResponse>> {
    return this.client.delete<TResponse>(path).then((response) => {
      return { data: response.data, headers: response.headers };
    }).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  }

  downloadFile(path: string): void {
    this.client.get(path, { responseType: 'blob' }).then((response) => {
      const contentDispositionHeader = response.headers['content-disposition'];
      const fileName = contentDispositionHeader.split('filename=')[1];

      saveAs(response.data, fileName);
    });
  }

  handleError(error: any) {
    const statusCode = error.response.status;
    let errorMessage;
    if (error.response && error.response?.data?.messages[0] !== '') {
      errorMessage = error.response?.data?.messages[0];
    } else {
      errorMessage = error.response?.data?.message || error.message;
    }

    let message;
    if (isNaN(statusCode)) {
      message = errorMessage;
    } else {
      message = `${statusCode} - ${errorMessage}`;
    }

    toast(message, {
      position: 'bottom-center',
      type: 'error',
      autoClose: 3000,
    });
  }
}
