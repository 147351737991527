import { BaseRoute, LicenseFormModes, LoadingIndicator, UpsertLicense, useBreadcrumbs } from "../../../../index";
import { useEffect, useState } from "react";
import { Customer, CustomerLicenceDetailsResponseDto, User, useRest, UserRole } from "@wacp/api";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AuthorisedUser, useAuth } from "@wacp/auth";

/* eslint-disable-next-line */
export interface UpgradeLicenseProps {}

export function UpgradeLicense(props: UpgradeLicenseProps) {
  const [license, setLicense] = useState<CustomerLicenceDetailsResponseDto>();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { licenseId } = useParams();
  const rest = useRest();
  const navigate = useNavigate();
  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  useEffect(() => {
    if (!rest || !licenseId) {
      console.debug('License details - no api yet');
      return;
    }

    const routeState = Number(licenseId);
    rest?.customerLicenceApi.details(routeState).then(({ data }) => {
      setLicense(data);
    });
  }, [licenseId, rest]);

  useEffect(() => {
    if (license && !!setBreadcrumbs) {
      if (organisation && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `/${BaseRoute.MY_ORGANISATION}`,
          },
          {
            name: license?.customerLicence.licenceId,
            path: `/${BaseRoute.MY_ORGANISATION}/licences/${license?.customerLicence.id}`,
          },
          {
            name: 'Upgrade',
            path: 'upgrade',
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: `/${BaseRoute.ORGANISATIONS}`,
          },
          {
            name: license?.customerLicence.customer.fullName,
            path: `/${BaseRoute.ORGANISATIONS}/${license?.customerLicence.customer.id}`,
          },
          {
            name: license?.customerLicence.licenceId,
            path: `/${BaseRoute.ORGANISATIONS}/${license?.customerLicence.customer.id}/licenses/${license?.customerLicence.id}`,
          },
          {
            name: 'Upgrade',
            path: 'upgrade',
          },
        ]);
      }
    }
  }, [license, organisation, currentUser, authorisedUser, setBreadcrumbs]);

  return (
    <>
      {license && (
        <UpsertLicense
          license={license}
          organisation={organisation}
          formMode={LicenseFormModes.UPGRADE}
          user={currentUser}
          authorisedUser={authorisedUser}
          onComplete={() => navigate(-1)}
        />
      )}
      {!license && <LoadingIndicator />}
    </>
  );
}

export default UpgradeLicense;
