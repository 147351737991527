import { ApiResponse, IApiClient, PageResponse} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { DiscountCreationDto } from './model';
import { Discount, PageRequest } from '../+shared';

export interface IDiscountApiClient {
  create(data: DiscountCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<Discount[]>>;
  listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Discount[]>>>;
  read(id: number): Promise<ApiResponse<Discount>>;
  update(id: number, data: DiscountCreationDto): Promise<ApiResponse<void>>;
}

export class DiscountApi implements IDiscountApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.DISCOUNT;
    this.apiClient = apiClient;
  }

  async create(data: DiscountCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<DiscountCreationDto, number>(
      this.apiBase,
      data
    );
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<Discount[]>> {
    return await this.apiClient.get<Discount[]>(this.apiBase);
  }

  async listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Discount[]>>> {
    return await this.apiClient.get<PageResponse<Discount[]>>(`${this.apiBase}/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async read(id: number): Promise<ApiResponse<Discount>> {
    return await this.apiClient.get<Discount>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: DiscountCreationDto, file?: any): Promise<ApiResponse<void>> {
    return await this.apiClient.put<DiscountCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
