import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import {
  CustomerLicenceModulePricesDto,
  LicenceTypeSearchParams,
  ModuleCreationDto,
  ModuleStatusUpdateDto
} from './model';
import { LicenceType, Module, ModuleDetailsResponseDto } from '../+shared';
import { Feature } from '../feature';
import {ModulePriceListDto} from "../+shared/model/module-pricelist-response-dto.model";

export interface IModuleApiClient {
  changeModuleStatus(id: number, data: ModuleStatusUpdateDto): Promise<ApiResponse<void>>;
  create(data: ModuleCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  details(id: number): Promise<ApiResponse<ModuleDetailsResponseDto>>;
  findFeaturesForModule(id: number): Promise<ApiResponse<Feature[]>>;
  list(): Promise<ApiResponse<Module[]>>;
  read(id: number): Promise<ApiResponse<Module>>;
  update(id: number, data: ModuleCreationDto): Promise<ApiResponse<void>>;
  getPriceList(productId: number, customerId: number, params: LicenceTypeSearchParams): Promise<ApiResponse<ModulePriceListDto[]>>;
  getDetailsPriceList(licenceId: number): Promise<ApiResponse<CustomerLicenceModulePricesDto[]>>;
}

export class ModuleApi implements IModuleApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.MODULE;
    this.apiClient = apiClient;
  }

  async changeModuleStatus(id: number, data: ModuleStatusUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<ModuleStatusUpdateDto, void>(`${this.apiBase}/${id}/changeStatus`, data);
  }

  async create(data: ModuleCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<ModuleCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async details(id: number): Promise<ApiResponse<ModuleDetailsResponseDto>> {
    return await this.apiClient.get<ModuleDetailsResponseDto>(`${this.apiBase}/${id}/details`);
  }

  async findFeaturesForModule(id: number): Promise<ApiResponse<Feature[]>> {
    return await this.apiClient.get<Feature[]>(`${this.apiBase}/${id}/features`);
  }

  async list(): Promise<ApiResponse<Module[]>> {
    return await this.apiClient.get<Module[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<Module>> {
    return await this.apiClient.get<Module>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: ModuleCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<ModuleCreationDto, void>(`${this.apiBase}/${id}`, data);
  }

  async getPriceList(productId: number, customerId: number, params: LicenceTypeSearchParams): Promise<ApiResponse<ModulePriceListDto[]>> {
    return await this.apiClient.get<ModulePriceListDto[]>(`${this.apiBase}/priceLists/customerId/${customerId}/productId/${productId}`, { params: new URLSearchParams({ ...params }) });
  }

  async getDetailsPriceList(licenceId: number): Promise<ApiResponse<CustomerLicenceModulePricesDto[]>> {
    return await this.apiClient.get<CustomerLicenceModulePricesDto[]>(`${this.apiBase}/readPriceLists/customerLicenceId/${licenceId}`);
  }
}
