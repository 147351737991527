import React from 'react';
import { PersonFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import WolkBreadcrumbs from '../../breadcrumbs/breadcrumbs';
import { useTheme } from '../../theme-provider/theme-provider';
import WolkPopover from '../popover/popover';
import styles from './header.module.scss';
import UserSettings from './user-settings/user-settings';

/* eslint-disable-next-line */
export interface HeaderProps {}

export function WolkHeader(props: HeaderProps) {
  const { activeTheme } = useTheme();

  return (
    <header
      className={`${styles['shell-header']} ${activeTheme} d-flex flex-row w-100 justify-content-between align-items-center`}
    >
      <div className={`d-flex flex-row`}>
        <div className={styles['logo']}>
          <Link to="/">
            <img src="/assets/logo.svg" alt="WolkAbout Logo" />
          </Link>
        </div>

        <div
          className={`d-none d-md-flex flex-row justify-content-start align-items-center ms-lg-3`}
        >
          <WolkBreadcrumbs />
        </div>
      </div>

      <div>
        <WolkPopover placement="bottom-end" buttonTitle={<PersonFill />}>
          <UserSettings />
        </WolkPopover>
      </div>
    </header>
  );
}

export default WolkHeader;
