import styles from './request-notifications.module.scss';
import { Badge, Stack } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RequestStatus, User, useRest, UserRole } from '@wacp/api';
import { AuthorisedUser, useAuth } from '@wacp/auth';

export interface RequestNotificationsProps {
  path: string;
}

export function RequestNotifications({path}: RequestNotificationsProps) {
  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();
  const [newOrganisations, setNewOrganisations] = useState<number | null>(null);
  const [newLicences, setNewLicences] = useState<number | null>(null);
  // const [newUsers, setNewUsers] = useState<number | null>(null);

  const rest = useRest();
  const auth = useAuth();

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);

  useEffect(() => {
    if (!rest || !currentUser || !authorisedUser) {
      return;
    }

    if (authorisedUser.role === UserRole.BD) {
      rest.requestApi.countNewOrganisations().then((response) => {setNewOrganisations(response.data)});
      rest.requestApi.countNewLicences().then((response) => {setNewLicences(response.data)});
      // rest.requestApi.countNewUsers().then((response) => {setNewUsers(response.data)});
      return;
    }

    if (!currentUser?.customer?.id) {
      return;
    }

    // rest.requestApi.countNewOrganisationsForCustomer(currentUser.customer.id).then((response) => {setNewOrganisations(response.data)});
    rest.requestApi.countNewLicencesForCustomer(currentUser.customer.id).then((response) => {setNewLicences(response.data)});
    // rest.requestApi.countNewUsersForCustomer(currentUser.customer.id).then((response) => {setNewUsers(response.data)});
  }, [rest, currentUser, authorisedUser]);

  return (
    <Stack>
      {
        !!newOrganisations &&
          <Link to={`${path}/organisations?status=${RequestStatus.PENDING}`} className={`${styles['link']}`}>
            <span><Badge bg="success">{newOrganisations}</Badge> organisation</span>
          </Link>
      }
      {
        !!newLicences &&
          <Link to={`${path}/licences?status=${RequestStatus.PENDING}`} className={`${styles['link']}`}>
            <span><Badge bg="success">{newLicences}</Badge> licence</span>
          </Link>
      }
      {/*{*/}
      {/*  !!newUsers &&*/}
      {/*  <Link to={`${path}/users?status=${RequestStatus.PENDING}`} className={`${styles['link']}`}>*/}
      {/*    <span><Badge bg="success">{newUsers}</Badge> new user{newUsers > 1 ? 's' : ''}</span>*/}
      {/*  </Link>*/}
      {/*}*/}
    </Stack>
  );
}

export default RequestNotifications;
