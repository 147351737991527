import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { InstallationVariableCreationDto } from './model';
import { InstallationVariable } from '../+shared';

export interface IInstallationVariableApiClient {
  create(data: InstallationVariableCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<InstallationVariable[]>>;
  read(id: number): Promise<ApiResponse<InstallationVariable>>;
  update(id: number, data: InstallationVariableCreationDto): Promise<ApiResponse<void>>;
}

export class InstallationVariableApi implements IInstallationVariableApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.INSTALLATION_VARIABLE;
    this.apiClient = apiClient;
  }

  async create(data: InstallationVariableCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<InstallationVariableCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<InstallationVariable[]>> {
    return await this.apiClient.get<InstallationVariable[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<InstallationVariable>> {
    return await this.apiClient.get<InstallationVariable>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: InstallationVariableCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<InstallationVariableCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
