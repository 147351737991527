/* eslint-disable-next-line */
import { Customer, CustomerStatus, User, useRest, UserRole } from "@wacp/api";
import { BaseRoute, humanise, LabeledValue, OrganisationUpsert, WolkModal, WolkTooltip } from "../../../../index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthorisedUser } from '@wacp/auth';
import { Pencil, Trash } from 'react-bootstrap-icons';

export interface DetailsInfoDisplayProps {
  organisation: Customer;
  currentUser: User;
  authorisedUser: AuthorisedUser;
  startEditing: () => void;
}

export function DetailsInfoDisplay({
  organisation,
  currentUser,
  authorisedUser,
  startEditing,
}: DetailsInfoDisplayProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const rest = useRest();
  const navigate = useNavigate();
  const modalConfig = {
    title: 'Delete organisation',
    description: `Are you sure you want to delete organisation ${organisation.fullName}?`,
    cancelButtonTitle: 'Cancel',
    submitButtonTitle: 'Confirm',
  };

  const openEditForm = () => {
    startEditing();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const deleteOrganisation = () => {
    rest.customerApi.delete(organisation.id).then(() => {
      toast('Organisation successfully deleted!', {
        position: 'bottom-center',
        type: 'success',
        autoClose: 3000,
      });
      navigate(-1);
    });
  };

  return (
    <div>
      <WolkModal
        openModal={showModal}
        displayData={modalConfig}
        onSubmit={deleteOrganisation}
        onClose={closeModal}
      />
      <div className='d-flex flex-row justify-content-between'>
        <h3>
          {organisation.fullName ? organisation.fullName : '-'}
        </h3>
        {
          organisation.customerStatus !== CustomerStatus.DELETED &&
          <div>
            <WolkTooltip message='Edit Organisation'>
              <Pencil
                className={'action-item mb-1 p-1'}
                onClick={openEditForm}
                role={'button'}
                size='30'
              />
            </WolkTooltip>
            {
              !!authorisedUser &&
              authorisedUser.role === UserRole.BD &&
              <WolkTooltip message='Delete Organisation'>
                <Trash className={'action-item action-item-danger mb-1 p-1'} onClick={openModal} role={'button'} size='30'/>
              </WolkTooltip>
            }
          </div>
        }
      </div>

      <OrganisationUpsert
        organisation={organisation}
        isInViewMode={true}
        onComplete={() => navigate(`/${BaseRoute.ORGANISATIONS}`)}
      />
    </div>
  );
}
