import { ReactNode } from 'react';

export function WolkTab({
  eventKey,
  title,
  disabled,
  children,
}: {
  eventKey: string;
  title: string;
  disabled?: boolean;
  path: string;
  children: ReactNode;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
