export enum ApiEndpoint {
  CUSTOMER = 'api/customers',
  CUSTOMER_COMMENT = 'api/customerComments',
  CUSTOMER_LICENCE = 'api/customerLicences',
  CUSTOMER_LICENCE_MODULE = 'api/customerLicenceModule',
  CUSTOMER_PACKAGE_CREDENTIALS = 'api/customerPackageCredentials',
  CUSTOMER_REPO = 'api/customerRepo',
  FEATURE = 'api/features',
  INSTALLATION_GUIDE = 'api/installationGuides',
  INSTALLATION_STEP = 'api/installationSteps',
  INSTALLATION_VARIABLE = 'api/installationVariables',
  LICENCE_KEY = 'api/licenceKeys',
  MODULE = 'api/modules',
  PRODUCT = 'api/products',
  PRODUCT_PACKAGE = 'api/productPackages',
  RENEWABLE_LICENCE_KEY = 'api/renewableLicences',
  REPO = 'api/repo',
  USER = 'api/users',
  VERSION = 'api/versions',
  REQUEST = 'api/requests',
  RESOURCE = 'api/resources',
  DISCOUNT = 'api/discounts',
  LOGS = 'api/logs',
}
