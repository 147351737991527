import styles from './details.module.scss';
import { useLocation, useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomerLicenceDetailsResponseDto, User, useRest } from "@wacp/api";
import { BaseRoute, TabulatedLayout, WolkTab } from "@wacp/shared-components";
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface DetailsProps {}

export function Details(props: DetailsProps) {
  const location = useLocation();
  const { licenseId } = useParams();
  const rest = useRest();
  const auth = useAuth();

  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();

  const [license, setLicense] = useState<CustomerLicenceDetailsResponseDto>();
  const [shouldReload, setShouldReload] = useState<boolean>(false);

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);


  const setReload = () => {
    setShouldReload(true);
  }

  const fetchData = () => {
    const routeState = Number(licenseId);
    rest?.customerLicenceApi.details(routeState).then(({ data }) => {
      setLicense(data);
      setShouldReload(false)
    });
  }

  useEffect(() => {
    const navState = (location.state as { license?: CustomerLicenceDetailsResponseDto })?.license;
    if (navState) {
      setLicense(navState);
    } else {
      if (!rest) {
        console.debug('License details - no api yet');
        return;
      }
      fetchData()
    }
  }, [location, licenseId, rest]);

  useEffect(() => {
    if (shouldReload) {
      fetchData();
    }
  }, [shouldReload])

  return (
    <div className={`layout layout-standard d-flex flex-column`}>
      {
        !!license &&
        !!currentUser &&
        !!authorisedUser &&
        <TabulatedLayout outletContext={{ license, currentUser, authorisedUser, reloadPage: setReload }}>
          <WolkTab path={`/${BaseRoute.LICENSES}/${licenseId}/info`} eventKey="info" title="Licence info">
          </WolkTab>
        </TabulatedLayout>
      }
    </div>
  );
}

export default Details;
