import { useEffect } from "react";
import { CustomerLicenceDetailsResponseDto } from "@wacp/api";
import { BaseRoute, LicenseDetails, useBreadcrumbs } from "@wacp/shared-components";
import { useOutletContext } from "react-router-dom";

/* eslint-disable-next-line */
export interface LicenseInfoWrapperProps {
}

export function LicenseInfo(props: LicenseInfoWrapperProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { license, reloadPage } =
    useOutletContext<{
      license: CustomerLicenceDetailsResponseDto;
      reloadPage: () => void;
    }>();

  useEffect(() => {
    if (license && !!setBreadcrumbs) {
      setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Licences',
          path: BaseRoute.LICENSES,
        },
        {
          name: license?.customerLicence.licenceId,
          path: `${license?.customerLicence.id}`,
        },
      ]);
    }
  }, [license, setBreadcrumbs]);

  return <LicenseDetails license={license} reloadPage={reloadPage}/>;
}

export default LicenseInfo;
