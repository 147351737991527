import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { InstallationGuideCreationDto } from './model';
import { InstallationGuide, InstallationStep } from '../+shared';

export interface IInstallationGuideApiClient {
  create(data: InstallationGuideCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  findAllStepsForGuide(id: number): Promise<ApiResponse<InstallationStep[]>>;
  list(): Promise<ApiResponse<InstallationGuide[]>>;
  read(id: number): Promise<ApiResponse<InstallationGuide>>;
  update(id: number, data: InstallationGuideCreationDto): Promise<ApiResponse<void>>;
}

export class InstallationGuideApi implements IInstallationGuideApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.INSTALLATION_GUIDE;
    this.apiClient = apiClient;
  }

  async create(data: InstallationGuideCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<InstallationGuideCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async findAllStepsForGuide(id: number): Promise<ApiResponse<InstallationStep[]>> {
    return await this.apiClient.get<InstallationStep[]>(`${this.apiBase}/${id}/steps`);
  }

  async list(): Promise<ApiResponse<InstallationGuide[]>> {
    return await this.apiClient.get<InstallationGuide[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<InstallationGuide>> {
    return await this.apiClient.get<InstallationGuide>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: InstallationGuideCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<InstallationGuideCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
