import styles from './upsert-license.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { humanise } from '../util/string-utils';
import {
  Customer,
  CustomerLicenceDetailsResponseDto,
  CustomerType,
  LicenceCreationDto,
  LicenceStatus,
  LicenceType,
  ModuleType,
  RenewalType,
  User,
  useRest,
  UserRole
} from '@wacp/api';
import { LicenseForm, LicenseFormModes, LicenseFormStructureData } from './upsert-license.model';
import { AuthorisedUser } from '@wacp/auth';
import { useEffect, useState } from 'react';
import { Option } from 'react-bootstrap-typeahead/types/types';
import moment from 'moment';
import { mapCreationDTOToForm, mapDTOToForm, mapModulesDtoToForm } from './upsert-license.utils';
import WolkCurrency from '../currency/wolk-currency';

export interface UpsertLicenseFormProps {
  user?: User;
  authorisedUser?: AuthorisedUser;
  formMode?: LicenseFormModes;
  license?: CustomerLicenceDetailsResponseDto;
  licenseCreationDto?: LicenceCreationDto;
  organisation?: Customer;
  defaultValues: LicenseForm;
  isFormDisabled?: boolean;
  onCustomerSelected: (customer: Customer | undefined) => void;
  requestInsertUser?: User;
  requestUpdateUser?: User;
}

export function UpsertLicenseForm({
                                    user,
                                    authorisedUser,
                                    formMode,
                                    license,
                                    licenseCreationDto,
                                    organisation,
                                    defaultValues,
                                    isFormDisabled,
                                    onCustomerSelected,
                                    requestInsertUser,
                                    requestUpdateUser,
                                  }: UpsertLicenseFormProps) {

  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
  const [formStructureData, setFormStructureData] = useState<LicenseFormStructureData | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [additionalDiscount, setAdditionalDiscount] = useState<number>(0);

  const rest = useRest();

  const {
    reset,
    control,
    clearErrors,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields } = useFieldArray({
    name: 'advancedModules',
  });

  const customerFieldValue = watch('customer');
  const productFieldValue = watch('product');
  const renewalTypeFieldValue = watch('renewalType');
  const advancedModulesFieldValues = watch('advancedModules');
  const validFromFieldValue = watch('validFromDate');
  const expirationDateFieldValue = watch('expirationDate');
  const licenseTypeFieldValue = watch('licenseType');
  const additionalDiscountFieldValue = watch('additionalDiscount');

  useEffect(() => {
    setIsAdmin(authorisedUser?.role === UserRole.BD);
  }, [authorisedUser]);

  useEffect(() => {
    rest.productApi.list().then(({ data: products }) => {
      const formStructureData: LicenseFormStructureData = {
        products,
        licenseStatuses: Object.keys(LicenceStatus),
        licenseTypes: Object.keys(LicenceType),
      };
      setFormStructureData(formStructureData);
    });

    rest.customerApi.list().then(({ data }) => {
      setCustomerList(data);
    });
  }, []);

  useEffect(() => {
    if (!validFromFieldValue || formMode === LicenseFormModes.VIEW) {
      return;
    }

    const today = moment().valueOf();
    const convertedFieldValue = moment(validFromFieldValue).valueOf();

    if (convertedFieldValue > today) {
      setValue('licenseStatus', LicenceStatus.PENDING);
    }
    if (convertedFieldValue <= today) {
      setValue('licenseStatus', LicenceStatus.VALID);
    }
  }, [validFromFieldValue]);

  useEffect(() => {
    if (!productFieldValue) {
      return;
    }

    if (organisation?.customerType !== CustomerType.CUSTOMER) {
      return;
    }

    setValue('endCustomer', organisation?.fullName);
  },[organisation, productFieldValue]);

  useEffect(() => {
    if (formStructureData && user) {
      if (!license && !licenseCreationDto) {
        reset(defaultValues);
        setValue('product', formStructureData.products[0].id);
        setValue('requestedByUser', user.email);
      } else if (license) {
        const mappedData = mapDTOToForm(license);
        reset(mappedData);
        setValue('requestedByUser', license.customerLicence.insertUser?.email);
        setValue('issuedByUser', license.customerLicence.updateUser?.email);
      } else if (licenseCreationDto) {
        const mappedData = mapCreationDTOToForm(licenseCreationDto);
        reset(mappedData);

        if (requestInsertUser) {
          setValue('requestedByUser', requestInsertUser.email);
        }

        if (requestUpdateUser) {
          setValue('issuedByUser', requestUpdateUser.email);
        }
      }

    }
  }, [formStructureData]);

  useEffect(() => {
    if (!expirationDateFieldValue || formMode === LicenseFormModes.VIEW) {
      return;
    }

    const today = moment().valueOf();
    const convertedFieldValue = moment(expirationDateFieldValue).valueOf();

    if (convertedFieldValue < today) {
      setValue('licenseStatus', LicenceStatus.EXPIRED);
    }

    if (convertedFieldValue >= today) {
      setValue('licenseStatus', LicenceStatus.VALID);
    }
  }, [expirationDateFieldValue]);

  useEffect(() => {
    if (!renewalTypeFieldValue || formMode === LicenseFormModes.VIEW) {
      return;
    }

    if (renewalTypeFieldValue === RenewalType.RECURRING) {
      setValue('licenseStatus', LicenceStatus.VALID);
      setValue('expirationDate', moment(validFromFieldValue).startOf("month").add(2, 'month').add(4, "day").format('yyyy-MM-DD'));
    } else {
      setValue(
        'expirationDate',
        formMode === 'CREATE'
          ? moment(validFromFieldValue)
              .startOf('day')
              .add(2, 'year')
              .format('yyyy-MM-DD')
          : moment(expirationDateFieldValue).format('yyyy-MM-DD')
      );
    }
  }, [renewalTypeFieldValue, validFromFieldValue]);

  useEffect(() => {
    if (customerList) {
      const customerValue = customerList.find(
        (c: Customer) =>
          c.fullName === customerFieldValue || c.id === Number(organisation?.id)
      );

      if (customerValue) {
        setSelectedCustomer(customerValue);
        onCustomerSelected(customerValue);
      }
    }
  }, [customerList, customerFieldValue, organisation]);

  useEffect(() => {
    if (!rest || !productFieldValue || !licenseTypeFieldValue || !renewalTypeFieldValue) {
      return;
    }

    if (!formStructureData && !license && !licenseCreationDto) {
      return;
    }

    const initialProductId =
      license?.customerLicence.product.id
      ?? formStructureData?.products[0].id
      ?? licenseCreationDto?.productId;

    const productId = Number(initialProductId ?? productFieldValue);

    const customerId = selectedCustomer !== undefined ? selectedCustomer.id : 0;
    rest?.moduleApi.getPriceList(productId, customerId, { licenceType: licenseTypeFieldValue, licenceRenewalType: renewalTypeFieldValue }).then(({ data }) => {
      const dtoModules = data.map(dto => dto.module);
      const modules = dtoModules.filter(module => (licenseCreationDto !== undefined && licenseCreationDto.modules.includes(module.id))
        || (license !== undefined && license.modules.map(module => module.id).includes(module.id)));

      const formModules = mapModulesDtoToForm(data, modules);

      const basicModule = formModules.find(formModule => formModule.module.moduleType === ModuleType.BASIC);
      if (basicModule) {
        formModules.splice(formModules.indexOf(basicModule), 1);
        formModules.unshift(basicModule);
      }

      setValue('advancedModules', formModules);
    })

  }, [selectedCustomer, productFieldValue, license, licenseCreationDto, formStructureData, licenseTypeFieldValue, renewalTypeFieldValue]);

  const onCustomerChange = (selection: Option[]) => {
    setSelectedCustomer(undefined);
    onCustomerSelected(undefined);
    const [customer] = selection as Customer[];
    if (customer) {
      setValue('customer', customer.fullName);
      setSelectedCustomer(customer);
      onCustomerSelected(customer);
    }
  };

  const isModuleDisabled = (moduleId: number): boolean => {
    if (license) {
      return license.modules.map((m) => m.id).includes(moduleId);
    } else if (licenseCreationDto) {
      return licenseCreationDto.modules.includes(moduleId) && formMode === LicenseFormModes.UPGRADE;
    } else {
      return false;
    }
  }

  const addTwoDecimals = (event: any) => {
    setValue('additionalDiscount', parseFloat(event.target.value).toFixed(2));
  }

  useEffect(() => {
    if (advancedModulesFieldValues.length > 0) {
      calculateInitialTotal();
    }
  }, [advancedModulesFieldValues]);

  const calculateInitialTotal = () => {
    const total = advancedModulesFieldValues
      .filter((item: any) => item.value).map((item: any) => item.price)
      .reduce((partialSum : number, a : number) => partialSum + a, 0);

    const totalDiscount = advancedModulesFieldValues
      .filter((item: any) => item.value).map((item: any) => item.discountPrice)
      .reduce((partialSum : number, a : number) => partialSum + a, 0);

    setTotal(total);
    setTotalDiscount(totalDiscount);
    setGrandTotal(totalDiscount - additionalDiscount);
  }

  const recalculate = (checked: boolean, price: number, discountPrice: number) => {
    const newTotal = checked ? total + price : total - price;
    const newTotalDiscount = checked ? totalDiscount + discountPrice : totalDiscount - discountPrice;

    setTotal(newTotal);
    setTotalDiscount(newTotalDiscount);
    setGrandTotal(newTotalDiscount - additionalDiscount);
  }

  useEffect(() => {
    setAdditionalDiscount(additionalDiscountFieldValue)
    setGrandTotal(totalDiscount - additionalDiscountFieldValue);
  }, [additionalDiscountFieldValue]);

    return (
    <form className={`${styles['d-flex']} flex-row h-100 w-100`}>
      <div className={`${styles['d-flex']} flex-column ${styles['w-50']} mb-4`}>
        <div className={`${styles['d-flex']} flex-column`}>
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Organisation:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="customer"
                control={control}
                rules={{ required: !license && !licenseCreationDto && !organisation }}
                render={({ field }) => (
                  <Typeahead
                    id="customer_selection"
                    labelKey="fullName"
                    options={customerList ?? []}
                    positionFixed={true}
                    onFocus={() => clearErrors("customer")}
                    disabled={
                      !!license ||
                      !! licenseCreationDto ||
                      !!organisation ||
                      isFormDisabled ||
                      formMode === LicenseFormModes.UPGRADE
                    }
                    selected={selectedCustomer ? [selectedCustomer] : []}
                    {...{ ...field, onChange: onCustomerChange }}
                  />
                )}
              />
              {errors['customer']?.type === 'required' && (
                <Form.Text className={`form-error`}>
                  Organisation name is required
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Product:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="product"
                control={control}
                render={({ field }) => (
                  <Form.Select
                    placeholder="Product"
                    {...field}
                    disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                  >
                    {formStructureData?.products?.map((p) => (
                      <option key={`${p.name}_${p.id}`} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Col>
          </Form.Group>

          {
            !!authorisedUser && authorisedUser.role === UserRole.BD &&
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Type:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="licenseType"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      placeholder="Type"
                      {...field}
                      disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                    >
                      {formStructureData?.licenseTypes?.map((t, i) => (
                        <option key={`${t}_${i}`} value={t}>
                          {humanise(t)}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Col>
            </Form.Group>
          }

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              End Customer:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="endCustomer"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="End Customer"
                    onFocus={() => clearErrors("endCustomer")}
                    {...field}
                    disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE || organisation?.customerType === CustomerType.CUSTOMER}
                  />
                )}
              />
              {errors['endCustomer']?.type === 'required' && (
                <Form.Text className={`form-error`}>End customer is required</Form.Text>
              )}
            </Col>
          </Form.Group>

          <Row className="w-100 mb-3">
            <Col sm="4">
              <Form.Label>
                Payment option:
              </Form.Label>
            </Col>
            <Col sm="8">
              <div className="d-flex flex-row flex-wrap">
                <Form.Group>
                  <Controller
                    name="renewalType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Check
                        inline
                        label="Recurring/Monthly"
                        type="radio"
                        disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                        checked={renewalTypeFieldValue === RenewalType.RECURRING}
                        id="Recurring"
                        {...{ ...field, value: RenewalType.RECURRING }}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="renewalType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Check
                        inline
                        label="One-time"
                        type="radio"
                        disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                        checked={renewalTypeFieldValue === RenewalType.ONE_TIME}
                        id="One-time"
                        {...{ ...field, value: RenewalType.ONE_TIME }}
                      />
                    )}
                  />
                </Form.Group>
                {errors['renewalType']?.type === 'required' && (
                  <Form.Text className={`form-error`}>License type is required</Form.Text>
                )}
              </div>
            </Col>
          </Row>

          {/*<div className={`${styles['d-flex']} flex-row mb-3`}>*/}
          {/*  */}
          {/*</div>*/}
        </div>

        <div className={`${styles['d-flex']} flex-column flex-fill`}>
          <div className={`${styles['d-flex']} flex-column h-100 w-100 overflow-auto`}>
            <hr className={`me-4`} />
            <table className={`${styles['advanced-module-table']}`}>
              <tbody>
              <tr>
                <td className={`${styles['advanced-module-table-title']}`}>
                  Modules:
                </td>
                <td className={`${styles['advanced-module-table-cell']}`}>
                  List<br/>price (€)
                </td>
                {
                  !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    Partner<br/>price (€)
                  </td>
                }
              </tr>

              {(fields)?.map((item: any , index) => (
                <tr key={index}>
                  <td>
                    <Form.Group key={item.id}>
                      <Controller
                        name={`advancedModules.${index}.value`}
                        control={control}
                        defaultValue={item.value}
                        render={({ field }) => (
                          <Form.Check
                            inline
                            label={item.module.name}
                            disabled={isFormDisabled || isModuleDisabled(item.module.id) || index === 0}
                            checked={!!advancedModulesFieldValues?.[index].value}
                            type="checkbox"
                            onClick={(event: any) => {
                              trigger(`advancedModules.${index}.value`);
                              recalculate(event.target.checked, item.price, item.discountPrice);
                            }}
                            id={item.module.name}
                            {...{ ...field, value: String(item.value) }}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={item.price} />
                  </td>
                  {
                    !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}>
                      <WolkCurrency price={item.discountPrice} />
                    </td>
                  }
                </tr>
              ))}
                <tr>
                  <td colSpan={3}><hr/></td>
                </tr>
                <tr>
                  <td>Total (€):</td>
                  {
                    !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={totalDiscount} />
                  </td>
                </tr>
              {
                !!authorisedUser &&
                authorisedUser.role === UserRole.BD &&
                (formMode === LicenseFormModes.EDIT || formMode === LicenseFormModes.CREATE) &&
                !isFormDisabled &&
                <tr>
                  <td>Additional Discount (€):</td>
                  {
                    !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']} d-flex`}>
                    <Controller
                      name="additionalDiscount"
                      control={control}
                      rules={{
                        required: false,
                        min: 0,
                        max: totalDiscount,
                      }}
                      defaultValue={parseFloat('0').toFixed(2)}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          className={`${styles['advanced-module-discount-input']}`}
                          size="sm"
                          disabled={isFormDisabled}
                          onFocus={() => clearErrors("additionalDiscount")}
                          {...field}
                          onBlur={addTwoDecimals}
                        />
                      )}
                    />

                  </td>
                  {errors['additionalDiscount']?.type === 'min' && (
                    <Form.Text className={`form-error`}>Additional Discount cannot be negative</Form.Text>
                  )}
                  {errors['additionalDiscount']?.type === 'max' && (
                    <Form.Text className={`form-error`}>Additional Discount cannot be above the total price</Form.Text>
                  )}
                </tr>
              }
              {
                (isFormDisabled || formMode === LicenseFormModes.VIEW) &&
                <tr>
                  <td>Additional Discount (€):</td>
                  {
                    !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={additionalDiscount} />
                  </td>
                </tr>
              }
                <tr>
                  <td colSpan={3}><hr/></td>
                </tr>
                <tr>
                  <td>{ renewalTypeFieldValue === RenewalType.RECURRING ? 'Monthly' : 'Grand' } Total (€):</td>
                  {
                    !!selectedCustomer && selectedCustomer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={grandTotal} />
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <hr className={`me-4`} />
        }
        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <div className={`${styles['d-flex']} flex-column`}>
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Requested by:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="requestedByUser"
                  control={control}
                  rules={{ required: authorisedUser?.role !== UserRole.BD }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Requested by"
                      disabled
                      {...field}
                    />
                  )}
                />
                {errors['requestedByUser']?.type === 'required' && (
                  <Form.Text className={`form-error`}>
                    Requesting user is required
                  </Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Issued by:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="issuedByUser"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Issued by"
                      disabled
                      {...field}
                    />
                  )}
                />
                {errors['issuedByUser']?.type === 'required' && (
                  <Form.Text className={`form-error`}>
                    Issuing user address is required
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
          </div>
        }

      </div>

      <div className={`${styles['d-flex']} flex-column ${styles['w-50']} mb-4 ${styles['right-column']}`}>
        {/*{*/}
        {/*  !!authorisedUser && authorisedUser.role === UserRole.BD &&*/}
        {/*  <Form.Group as={Row} className={`w-100 mb-3`}>*/}
        {/*    <Form.Label column sm="4">*/}
        {/*      Status:*/}
        {/*    </Form.Label>*/}
        {/*    <Col sm="8">*/}
        {/*      <Controller*/}
        {/*        name="licenseStatus"*/}
        {/*        control={control}*/}
        {/*        render={({ field }) => (*/}
        {/*          <Form.Select*/}
        {/*            placeholder="Status"*/}
        {/*            disabled={true}*/}
        {/*            {...field}*/}
        {/*          >*/}
        {/*            {formStructureData?.licenseStatuses?.map((s, i) => (*/}
        {/*              <option key={`${s}_${i}`} value={s}>*/}
        {/*                {humanise(s)}*/}
        {/*              </option>*/}
        {/*            ))}*/}
        {/*          </Form.Select>*/}
        {/*        )}*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Form.Group>*/}
        {/*}*/}

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Requested on:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="requestDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    disabled={true}
                    placeholder="Requested on"
                    {...field}
                  />
                )}
              />
              {errors['requestDate']?.type === 'required' && (
                <Form.Text className={`form-error`}>
                  Date of request is required
                </Form.Text>
              )}
            </Col>
          </Form.Group>
        }

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Valid from:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="validFromDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    placeholder="Valid from"
                    disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                    onFocus={() => clearErrors("validFromDate")}
                    {...field}
                  />
                )}
              />
              {errors['validFromDate']?.type === 'required' && (
                <Form.Text className={`form-error`}>Validity date is required</Form.Text>
              )}
            </Col>
          </Form.Group>
        }

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Expires on:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="expirationDate"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    disabled={isFormDisabled || renewalTypeFieldValue === RenewalType.RECURRING}
                    placeholder="Expires on"
                    onFocus={() => clearErrors("expirationDate")}
                    {...field}
                  />
                )}
              />
              {errors['expirationDate']?.type === 'required' && (
                <Form.Text className={`form-error`}>
                  Expiration date is required
                </Form.Text>
              )}
              {errors['expirationDate']?.type === 'validate' && (
                <Form.Text className={`form-error`}>
                  Expiration date should be set at least a month days from
                  validity date
                </Form.Text>
              )}
            </Col>
          </Form.Group>
        }

        <Form.Group as={Row} className={`w-100 mb-3`}>
          <Form.Label column sm="4">
            Note:
          </Form.Label>
          <Col sm="8">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Form.Control
                  rows={10}
                  cols={10}
                  as="textarea"
                  disabled={isFormDisabled || formMode === LicenseFormModes.UPGRADE}
                  placeholder="Add a note regarding the license"
                  {...field}
                />
              )}
            />
          </Col>
        </Form.Group>



      </div>
    </form>
  );
}
