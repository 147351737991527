import { OrganisationUpsertForm } from "./organisation-upsert.model";
import { Customer, CustomerCreationDto, CustomerType, User } from "@wacp/api";

export function mapDTOToOrganisationForm(customer: Customer): OrganisationUpsertForm {
  return {
    fullName: customer.fullName,
    address: customer.address,
    customerType: customer.customerType as CustomerType,
    partnershipLevelType: customer.partnershipLevelType?.id,
    contactPersonPhone: customer.contactPersonPhone,
    billingAddress: customer.billingAddress,
    vat: customer.vat,
    comment: customer.comment
  }
}

export function mapOrganisationFormToDTO(organisationForm: OrganisationUpsertForm, currentUser: User): CustomerCreationDto {
  return {
    fullName: organisationForm.fullName,
    address: organisationForm.address,
    customerType: organisationForm.customerType as CustomerType,
    partnershipLevelType: organisationForm.partnershipLevelType,
    contactPersonPhone: organisationForm.contactPersonPhone,
    billingAddress: organisationForm.billingAddress,
    vat: organisationForm.vat,
    comment: organisationForm.comment,
    insertUser: currentUser.id,
  }
}
