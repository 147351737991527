import style from "./wolk-table.module.scss"
import React, {ReactElement} from "react";
import WolkTableAction from "./wolk-table-action";
import styles from "./wolk-table.module.scss";

export interface WolkTableActionsProps {
  children: ReactElement | ReactElement[]
}

export function WolkTableActions({ children }: WolkTableActionsProps) {
  const childArray = Array.isArray(children) ? children : [children];
  childArray.forEach(child => {
    if (child.type !== WolkTableAction) {
      throw Error(`WolkTableActions can contain only children of type 'WolkTableAction'. Currently contains ${child.type}`);
    }
  });

  return (
    <div className={`${style['actions']} align-items-center`}>
      {
        childArray
      }
    </div>
  );
}

export default WolkTableActions;
