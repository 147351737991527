import { Button, Form, Modal } from 'react-bootstrap';
import React, { ReactElement } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import styles from './wolk-modal.module.scss';

export interface WolkModalType {
  displayData?: {
    title?: string;
    description?: string;
    content?: ReactElement;
    hasInput?: boolean;
    cancelButtonTitle?: string;
    submitButtonTitle?: string;
  };
  openModal: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onFormSubmit?: SubmitHandler<FieldValues>;
}

export function WolkModal(props: WolkModalType) {
  const {
    handleSubmit,
    control,
  } = useForm();

  return (
    <Modal show={props.openModal} onHide={props.onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.displayData?.title ?? 'Modal title'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.displayData?.description ?? 'Modal description'}
        {
          props.displayData?.hasInput &&
          <form className={`${styles['form']}`}>
            <Controller
              name="input"
              control={control}
              render={({ field }) => (
                <Form.Control
                  rows={10}
                  cols={10}
                  as="textarea"
                  placeholder="Type here..."
                  {...field}
                />
              )}
            />
          </form>
        }
      </Modal.Body>
      <Modal.Footer>
        {props.displayData?.cancelButtonTitle && (
          <Button variant="secondary" onClick={props.onClose}>
            {props.displayData?.cancelButtonTitle ?? 'Cancel'}
          </Button>
        )}
        {
          props.displayData?.hasInput &&
          !!props.onFormSubmit &&
          <Button variant="primary" onClick={handleSubmit(props.onFormSubmit)}>
            {props.displayData?.submitButtonTitle ?? 'Submit'}
          </Button>
        }
        {
          !props.displayData?.hasInput &&
          <Button variant="primary" onClick={props.onSubmit}>
            {props.displayData?.submitButtonTitle ?? 'Submit'}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default WolkModal;
