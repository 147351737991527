import {
  BaseRoute,
  humanise,
  PHONE_REGEX,
  PHONE_REGEX_EXAMPLE,
  useBreadcrumbs,
  WolkUpsertWrapper
} from "../../index";
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Customer,
  CustomerCreationDto,
  CustomerType, Discount,
  User,
  useRest,
  UserRole,
} from '@wacp/api';
import { OrganisationUpsertForm } from './organisation-upsert.model';
import { mapDTOToOrganisationForm, mapOrganisationFormToDTO, } from './organisation-upsert.utils';
import { toast } from 'react-toastify';
import { AuthorisedUser, useAuth } from '@wacp/auth';

/* eslint-disable-next-line */
export interface OrganisationUpsertProps {
  organisation?: Customer;
  isInViewMode: boolean;
  onComplete: () => void;
}

const defaultValues: OrganisationUpsertForm = {
  fullName: '',
  address: '',
  customerType: CustomerType.PROSPECT,
  contactPersonPhone: '',
  billingAddress: '',
  vat: '',
  comment: ''
};

export function OrganisationUpsert({
  organisation,
  isInViewMode,
  onComplete,
}: OrganisationUpsertProps) {
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const auth = useAuth();
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [partnershipLevelTypeList, setPartnershipLevelTypeList] = useState<Discount[]>([]);
  const {
    handleSubmit,
    reset,
    control,
    watch,
    clearErrors,
    setValue,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const customerTypeFieldValue = watch('customerType');

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          setIsAdmin(activeUser?.role === UserRole.BD);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);

  useEffect(() => {
    if (organisation && currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
      breadcrumbs.setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: organisation.fullName,
          path: BaseRoute.MY_ORGANISATION,
        },
      ]);
    } else {
      breadcrumbs.setBreadcrumbs([
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Organisations',
          path: BaseRoute.ORGANISATIONS,
        },
        {
          name: organisation ? organisation.ourName : 'New organisation',
          path: organisation ? `${organisation?.id}` : '',
        },
      ]);
    }

    fetchPartnershipLevelTypes();

    if (organisation) {
      const mappedData = mapDTOToOrganisationForm(organisation);
      reset(mappedData);
    }
  }, [organisation, currentUser, authorisedUser]);

  useEffect(() => {
    if (partnershipLevelTypeList.length > 0) {
      if (customerTypeFieldValue === CustomerType.PARTNER) {
        setValue('partnershipLevelType', partnershipLevelTypeList[0].id);
      } else {
        setValue('partnershipLevelType', undefined);
      }
    }
  }, [customerTypeFieldValue])

  const handleOnCancel = () => {
    reset();
    onComplete();
  };

  const fetchPartnershipLevelTypes = () => {
    const partnershipLevelTypeId = organisation?.partnershipLevelType?.id;
    rest?.discountApi.list().then(({ data }) => {
      setPartnershipLevelTypeList(data);
      setValue('partnershipLevelType', partnershipLevelTypeId ?? data[0].id);
    });
  }

  const handleOnSubmit = (formValue: OrganisationUpsertForm) => {
    if (currentUser && authorisedUser) {
      setRequestInProgress(true);

      const dto = mapOrganisationFormToDTO(formValue, currentUser);

      if (organisation) {
        updateAsAdmin(organisation.id, dto);
      } else {
        isAdmin ? createAsAdmin(dto) : createAsCustomer(dto);
      }
    }
  };

  const createAsAdmin = (dto: CustomerCreationDto) => {
    rest?.customerApi
      .create(dto)
      .then((value) => {
        setRequestInProgress(false);
        reset();
        toast('Organisation successfully created!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
        onComplete();
      })
      .catch((reason) => {
        setRequestInProgress(false);
      });
  }

  const createAsCustomer = (dto: CustomerCreationDto) => {
    rest?.requestApi
      .createRequestCustomer(dto)
      .then((value) => {
        setRequestInProgress(false);
        reset();
        toast('Request successfully created!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
        navigate(`/${BaseRoute.REQUESTS}/organisations`);
      })
      .catch((reason) => {
        setRequestInProgress(false);
      });
  }

  const updateAsAdmin = (id: number, dto: CustomerCreationDto) => {
    rest?.customerApi
      .update(id, dto)
      .then((value) => {
        setRequestInProgress(false);
        reset();
        toast('Organisation successfully updated!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
        onComplete();
      })
      .catch((reason) => {
        setRequestInProgress(false);
      });
  }

  const getButtonTitle = (): string => {
    if (organisation?.id) {
      return 'Update organisation';
    } else {
      return isAdmin ? 'Add organisation' : 'Create request';
    }
  }

  return (
    <div className={`layout layout-full`}>
      <WolkUpsertWrapper>
        <form className={`d-flex flex-column w-100`}>
          {
            !isInViewMode &&
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Organisation name:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="fullName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder={isInViewMode ? '' : 'Organisation name'}
                      onFocus={() => clearErrors("fullName")}
                      {...field}
                      disabled={isInViewMode || !isAdmin}
                    />
                  )}
                />
                {errors['fullName']?.type === 'required' && (
                  <Form.Text className={`form-error`}>
                    Organisation name is required
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
          }

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Organisation Type:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="customerType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Select
                    placeholder={isInViewMode ? '' : 'Organisation type'}
                    {...field}
                    disabled={isInViewMode || !isAdmin}>
                    {Object.values(CustomerType)
                      .filter((type) => type !== CustomerType.APPLICANT)
                      .map((type) => (
                        <option key={type} value={type}>
                          {humanise(type)}
                        </option>
                      ))}
                  </Form.Select>
                )}
              />
              {errors['customerType']?.type === 'required' && (
                <Form.Text className={`form-error`}>
                  Organisation type is required
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          {
            customerTypeFieldValue === CustomerType.PARTNER &&
            !!partnershipLevelTypeList &&
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Partnership level:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="partnershipLevelType"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      placeholder={isInViewMode ? '' : 'Partnership level'}
                      {...field}
                      disabled={isInViewMode || !isAdmin || customerTypeFieldValue !== CustomerType.PARTNER}
                    >
                      {partnershipLevelTypeList.map((p) => (
                        <option key={`${p.partnershipLevelType}_${p.id}`} value={p.id}>
                          {p.partnershipLevelType}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Col>
            </Form.Group>
          }

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Business address:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="address"
                control={control}
                rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder={isInViewMode ? '' : 'Business address'}
                    disabled={isInViewMode}
                    {...field}
                  />
                )}
              />
              {errors['address']?.type === 'required' && (
                <Form.Text className={`form-error`}>Business address is required</Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Billing address:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="billingAddress"
                control={control}
                rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder={isInViewMode ? '' : 'Billing address'}
                    onFocus={() => clearErrors("billingAddress")}
                    disabled={isInViewMode}
                    {...field}
                  />
                )}
              />
              {errors['billingAddress']?.type === 'required' && (
                <Form.Text className={`form-error`}>Billing address is required</Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Phone number:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="contactPersonPhone"
                control={control}
                rules={{
                  pattern: PHONE_REGEX
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder={isInViewMode ? '' : `Include an international country code (+ or 00), e.g. ${PHONE_REGEX_EXAMPLE}`}
                    onFocus={() => clearErrors("contactPersonPhone")}
                    disabled={isInViewMode}
                    {...field}
                  />
                )}
              />
              {errors['contactPersonPhone']?.type === 'required' && (
                <Form.Text className={`form-error`}>Phone number is required</Form.Text>
              )}
              {errors['contactPersonPhone']?.type === 'pattern' && (
                <Form.Text className={`form-error`}>
                  Please enter a phone number that contains the international
                  country code, e.g.
                  {PHONE_REGEX_EXAMPLE}
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              VAT number:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="vat"
                control={control}
                rules={{ required: customerTypeFieldValue !== CustomerType.PROSPECT }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder={isInViewMode ? '' : 'VAT number'}
                    onFocus={() => clearErrors("vat")}
                    disabled={isInViewMode}
                    {...field}
                  />
                )}
              />
              {errors['vat']?.type === 'required' && (
                <Form.Text className={`form-error`}>VAT number is required</Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
          <Form.Label column sm="4">
            Note:
          </Form.Label>
          <Col sm="8">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Form.Control
                  rows={4}
                  cols={10}
                  as="textarea"
                  placeholder={isInViewMode ? '' : 'Add a note regarding the customer'}
                  disabled={isInViewMode}
                  {...field}
                />
              )}
            />
          </Col>
        </Form.Group>
        </form>
        {
          !isInViewMode &&
          <div
            className={`d-flex flex-row justify-content-end align-items-center`}
          >
            {requestInProgress && (
              <div
                className={`d-flex flex-row justify-content-center align-items-center me-1`}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <Button
              variant={`secondary`}
              disabled={requestInProgress}
              onClick={handleOnCancel}
              className={`me-1`}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={`primary`}
              disabled={!isDirty || !isValid || requestInProgress}
              onClick={handleSubmit(handleOnSubmit)}
            >
              {getButtonTitle()}
            </Button>
          </div>
        }
      </WolkUpsertWrapper>
    </div>
  );
}

export default OrganisationUpsert;
