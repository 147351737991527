/* eslint-disable-next-line */
import React, {useState} from 'react';

export interface ThemeContextType {
  activeTheme: string;
  changeTheme: () => void;
}

const THEME_STORAGE_KEY = 'wacpActiveTheme'
const THEME_PREFIX = 'shell-theme__';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ThemeContext = React.createContext<ThemeContextType>(null!);

export function ThemeProvider({children}: { children: React.ReactNode }) {
  const [activeTheme, setActiveTheme] = useState(localStorage.getItem(THEME_STORAGE_KEY) ?? `${THEME_PREFIX}light`)

  const changeTheme = () => {
    const newTheme = activeTheme === `${THEME_PREFIX}light` ? `${THEME_PREFIX}dark` : `${THEME_PREFIX}light`;
    localStorage.setItem(THEME_STORAGE_KEY, newTheme);
    setActiveTheme(newTheme);
  };

  return (
    <ThemeContext.Provider
      value={{activeTheme, changeTheme}}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return React.useContext(ThemeContext);
}
