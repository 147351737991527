import { ApiResponse, IApiClient, PageResponse } from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { CustomerLicenceDetailsResponseDto, LicenceStatusUpdateDto, LicenceUpgradeDto } from './model';
import { CustomerLicence, LicenceCreationDto, LicenceDetailsResponseDto, LicenceKey, PageRequest } from '../+shared';

export interface ICustomerLicenceApiClient {
  changeLicenceStatus(id: number, data: LicenceStatusUpdateDto): Promise<ApiResponse<void>>;
  countApplications(): Promise<ApiResponse<number>>;
  countExpiring(): Promise<ApiResponse<number>>;
  create(data: LicenceCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  details(id: number): Promise<ApiResponse<CustomerLicenceDetailsResponseDto>>;
  getLicenceKey(id: string): Promise<ApiResponse<LicenceKey>>;
  list(): Promise<ApiResponse<CustomerLicence[]>>;
  listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<CustomerLicence[]>>>;
  listOfLicenceKeysForLicence1(id: string): Promise<ApiResponse<CustomerLicenceDetailsResponseDto>>;
  listOfLicenceKeysForLicence2(id: number): Promise<ApiResponse<LicenceKey>>;
  listOfLicences(): Promise<ApiResponse<LicenceDetailsResponseDto>>;
  read(id: number): Promise<ApiResponse<CustomerLicence>>;
  update(id: number, data: LicenceCreationDto): Promise<ApiResponse<void>>;
  upgradeLicence(id: number, data: LicenceUpgradeDto): Promise<ApiResponse<void>>;
  downloadLicence(id: number): Promise<ApiResponse<LicenceKey>>;
  renewLicence(licenceId: string): Promise<ApiResponse<LicenceKey>>;
  downloadBillOfLading(id: number): void;
}

export class CustomerLicenceApi implements ICustomerLicenceApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER_LICENCE;
    this.apiClient = apiClient;
  }

  async changeLicenceStatus(id: number, data: LicenceStatusUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<LicenceStatusUpdateDto, void>(`${this.apiBase}/${id}/licenceStatus`, data);
  }

  async countApplications(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/applications`);
  }

  async countExpiring(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/expiringLicences`);
  }

  async create(data: LicenceCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<LicenceCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async details(id: number): Promise<ApiResponse<CustomerLicenceDetailsResponseDto>> {
    return await this.apiClient.get<CustomerLicenceDetailsResponseDto>(`${this.apiBase}/${id}/details`);
  }

  async getLicenceKey(id: string): Promise<ApiResponse<LicenceKey>> {
    return await this.apiClient.get<LicenceKey>(`${this.apiBase}/${id}/renew`);
  }

  async list(): Promise<ApiResponse<CustomerLicence[]>> {
    return await this.apiClient.get<CustomerLicence[]>(this.apiBase);
  }

  async listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<CustomerLicence[]>>> {
    return await this.apiClient.get<PageResponse<CustomerLicence[]>>(`${this.apiBase}/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async listOfLicenceKeysForLicence1(id: string): Promise<ApiResponse<CustomerLicenceDetailsResponseDto>> {
    return await this.apiClient.get<CustomerLicenceDetailsResponseDto>(`${this.apiBase}/${id}/licenceDetails`);
  }

  async listOfLicenceKeysForLicence2(id: number): Promise<ApiResponse<LicenceKey>> {
    return await this.apiClient.get<LicenceKey>(`${this.apiBase}/${id}/licenceKey`);
  }

  async listOfLicences(): Promise<ApiResponse<LicenceDetailsResponseDto>> {
    return await this.apiClient.get<LicenceDetailsResponseDto>(`${this.apiBase}/details`);
  }

  async read(id: number): Promise<ApiResponse<CustomerLicence>> {
    return await this.apiClient.get<CustomerLicence>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: LicenceCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<LicenceCreationDto, void>(`${this.apiBase}/${id}`, data);
  }

  async upgradeLicence(id: number, data: LicenceUpgradeDto): Promise<ApiResponse<void>> {
    return await this.apiClient.post<LicenceUpgradeDto, void>(`${this.apiBase}/${id}/upgrade`, data);
  }

  async downloadLicence(id: number): Promise<ApiResponse<LicenceKey>> {
    return await this.apiClient.get<LicenceKey>(`${this.apiBase}/${id}/licenceKey`);
  }

  async renewLicence(licenceId: string): Promise<ApiResponse<LicenceKey>> {
    return await this.apiClient.post(`${this.apiBase}/${licenceId}/renewLicences`, null);
  }

  downloadBillOfLading(id: number): void {
    this.apiClient.downloadFile(`${this.apiBase}/files/${id}`);
  }
}
