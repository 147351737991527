import styles from './upsert-license.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { humanise } from '../util/string-utils';
import {
  Customer,
  CustomerLicenceDetailsResponseDto, CustomerLicenceModulePricesDto,
  CustomerType,
  LicenceStatus,
  LicenceType, ModuleResponseDto,
  ModuleType,
  RenewalType,
  User,
  useRest,
  UserRole
} from '@wacp/api';
import { LicenseFormModule, LicenseFormStructureData } from './upsert-license.model';
import { AuthorisedUser } from '@wacp/auth';
import { useEffect, useState } from 'react';
import { mapDTOToForm } from './upsert-license.utils';
import WolkCurrency from '../currency/wolk-currency';

function mapModulesDtoToForm(
  modules: CustomerLicenceModulePricesDto[],
  licenseModules?: ModuleResponseDto[]
): LicenseFormModule[] {
  return modules.map((modulePriceListDto) => {
    const licenseModule = licenseModules?.find(licenseModule => licenseModule.id === modulePriceListDto.module.id);
    const isBasicModule = modulePriceListDto.module.moduleType === ModuleType.BASIC;

    return {
      module: modulePriceListDto.module,
      value: !!licenseModule || isBasicModule,
      price: modulePriceListDto.listPrice,
      discountPrice: modulePriceListDto.listPriceDiscount,
    };
  });
}

export interface DetailsLicenseFormProps {
  user?: User;
  authorisedUser?: AuthorisedUser;
  license: CustomerLicenceDetailsResponseDto;
  organisation?: Customer;
}

export function DetailsLicenseForm({
                                    user,
                                    authorisedUser,
                                    license,
                                  }: DetailsLicenseFormProps) {

  const [formStructureData, setFormStructureData] = useState<LicenseFormStructureData | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [additionalDiscount, setAdditionalDiscount] = useState<number>(0);

  const rest = useRest();

  const {
    reset,
    control,
    watch,
    setValue,
  } = useFormContext();
  const { fields } = useFieldArray({
    name: 'advancedModules',
  });

  const advancedModulesFieldValues = watch('advancedModules');

  useEffect(() => {
    setIsAdmin(authorisedUser?.role === UserRole.BD);
  }, [authorisedUser]);

  useEffect(() => {
    rest.productApi.list().then(({ data: products }) => {
      const formStructureData: LicenseFormStructureData = {
        products,
        licenseStatuses: Object.keys(LicenceStatus),
        licenseTypes: Object.keys(LicenceType),
      };
      setFormStructureData(formStructureData);
    });
  }, []);

  useEffect(() => {
    if (formStructureData && user && license) {
      const mappedData = mapDTOToForm(license);
      reset(mappedData);
      setValue('customer', license.customerLicence.customer.fullName);
      setValue('requestedByUser', license.customerLicence.insertUser?.email);
      setValue('issuedByUser', license.customerLicence.updateUser?.email);
    }
  }, [formStructureData, license]);


  useEffect(() => {
    if (!rest || !license) {
      return;
    }

    rest?.moduleApi.getDetailsPriceList(license.customerLicence.id).then(({ data }) => {
      const dtoModules = data.map(dto => dto.module);
      const modules = dtoModules.filter(module => license !== undefined && license.modules.map(module => module.id).includes(module.id));

      const formModules = mapModulesDtoToForm(data, modules);

      const basicModule = formModules.find(formModule => formModule.module.moduleType === ModuleType.BASIC);
      if (basicModule) {
        formModules.splice(formModules.indexOf(basicModule), 1);
        formModules.unshift(basicModule);
      }

      setValue('advancedModules', formModules);
      setAdditionalDiscount(data[0].additionalDiscount);
      setTotalDiscount(data[0].totalDiscountPrice);
      setGrandTotal(data[0].totalGrandPrice);
    });

  }, [license, formStructureData]);

  return (
    <form className={`${styles['d-flex']} flex-row h-100 w-100`}>
      <div className={`${styles['d-flex']} flex-column ${styles['w-50']} mb-4`}>
        <div className={`${styles['d-flex']} flex-column`}>
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Organisation:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="customer"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    {...field}
                    disabled
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Product:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="product"
                control={control}
                render={({ field }) => (
                  <Form.Select
                    placeholder="Product"
                    disabled
                    {...field}
                  >
                    {formStructureData?.products?.map((p) => (
                      <option key={`${p.name}_${p.id}`} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Col>
          </Form.Group>

          {
            !!authorisedUser && authorisedUser.role === UserRole.BD &&
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Type:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="licenseType"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      placeholder="Type"
                      {...field}
                      disabled
                    >
                      {formStructureData?.licenseTypes?.map((t, i) => (
                        <option key={`${t}_${i}`} value={t}>
                          {humanise(t)}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Col>
            </Form.Group>
          }

          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              End Customer:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="endCustomer"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="End Customer"
                    {...field}
                    disabled
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Row className="w-100 mb-3">
            <Col sm="4">
              <Form.Label>
                Payment option:
              </Form.Label>
            </Col>
            <Col sm="8">
              <div className="d-flex flex-row flex-wrap">
                <Form.Group>
                  <Controller
                    name="renewalType"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        inline
                        label="Recurring/Monthly"
                        type="radio"
                        disabled
                        checked={license.customerLicence.renewalType === RenewalType.RECURRING}
                        id="Recurring"
                        {...{ ...field, value: RenewalType.RECURRING }}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="renewalType"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        inline
                        label="One-time"
                        type="radio"
                        disabled
                        checked={license.customerLicence.renewalType === RenewalType.ONE_TIME}
                        id="One-time"
                        {...{ ...field, value: RenewalType.ONE_TIME }}
                      />
                    )}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>

        <div className={`${styles['d-flex']} flex-column flex-fill`}>
          <div className={`${styles['d-flex']} flex-column h-100 w-100 overflow-auto`}>
            <hr className={`me-4`} />
            <table className={`${styles['advanced-module-table']}`}>
              <tbody>
              <tr>
                <td className={`${styles['advanced-module-table-title']}`}>
                  Modules:
                </td>
                <td className={`${styles['advanced-module-table-cell']}`}>
                  List<br/>price (€)
                </td>
                {
                  license.customerLicence.customer.customerType === CustomerType.PARTNER &&
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    Partner<br/>price (€)
                  </td>
                }
              </tr>

              {(fields)?.map((item: any , index) => (
                <tr key={index}>
                  <td>
                    <Form.Group key={item.id}>
                      <Controller
                        name={`advancedModules.${index}.value`}
                        control={control}
                        defaultValue={item.value}
                        render={({ field }) => (
                          <Form.Check
                            inline
                            label={item.module.name}
                            disabled
                            checked={!!advancedModulesFieldValues?.[index].value}
                            type="checkbox"
                            id={item.module.name}
                            {...{ ...field, value: String(item.value) }}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={item.price} />
                  </td>
                  {
                    license.customerLicence.customer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}>
                      <WolkCurrency price={item.discountPrice} />
                    </td>
                  }
                </tr>
              ))}
                <tr>
                  <td colSpan={3}><hr/></td>
                </tr>
                <tr>
                  <td>Total (€):</td>
                  {
                    license.customerLicence.customer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={totalDiscount} />
                  </td>
                </tr>
                <tr>
                  <td>Additional Discount (€):</td>
                  {
                    license.customerLicence.customer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={additionalDiscount} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}><hr/></td>
                </tr>
                <tr>
                  <td>{ license.customerLicence.renewalType === RenewalType.RECURRING ? 'Monthly' : 'Grand' } Total (€):</td>
                  {
                    license.customerLicence.customer.customerType === CustomerType.PARTNER &&
                    <td className={`${styles['advanced-module-table-cell']}`}></td>
                  }
                  <td className={`${styles['advanced-module-table-cell']}`}>
                    <WolkCurrency price={grandTotal} />
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <hr className={`me-4`} />
        }
        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <div className={`${styles['d-flex']} flex-column`}>
            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Requested by:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="requestedByUser"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Requested by"
                      disabled
                      {...field}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Issued by:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="issuedByUser"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Issued by"
                      disabled
                      {...field}
                    />
                  )}
                />
              </Col>
            </Form.Group>
          </div>
        }

      </div>

      <div className={`${styles['d-flex']} flex-column ${styles['w-50']} mb-4 ${styles['right-column']}`}>

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Requested on:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="requestDate"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    disabled
                    placeholder="Requested on"
                    {...field}
                  />
                )}
              />
            </Col>
          </Form.Group>
        }

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Valid from:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="validFromDate"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    placeholder="Valid from"
                    disabled
                    {...field}
                  />
                )}
              />
            </Col>
          </Form.Group>
        }

        {
          !!authorisedUser && authorisedUser.role === UserRole.BD &&
          <Form.Group as={Row} className={`w-100 mb-3`}>
            <Form.Label column sm="4">
              Expires on:
            </Form.Label>
            <Col sm="8">
              <Controller
                name="expirationDate"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    disabled
                    placeholder="Expires on"
                    {...field}
                  />
                )}
              />
            </Col>
          </Form.Group>
        }

        <Form.Group as={Row} className={`w-100 mb-3`}>
          <Form.Label column sm="4">
            Note:
          </Form.Label>
          <Col sm="8">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Form.Control
                  rows={10}
                  cols={10}
                  as="textarea"
                  disabled
                  placeholder="Add a note regarding the license"
                  {...field}
                />
              )}
            />
          </Col>
        </Form.Group>
      </div>
    </form>
  );
}
