import { Customer, CustomerCreationDto, CustomerLicence, PageRequest, User } from '../+shared';
import { ApiResponse, IApiClient, PageResponse } from '../api-client';
import {
  CustomerDetailsResponseDto,
  CustomerDetailsSearchParams,
} from './model';
import { ApiEndpoint } from '../api-endpoint.enum';

export interface ICustomerApiClient {
  countApplications(): Promise<ApiResponse<number>>;
  create(data: CustomerCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  getDetailsById(id: number): Promise<ApiResponse<CustomerDetailsResponseDto>>;
  getDetailsByParams(
    params: CustomerDetailsSearchParams
  ): Promise<ApiResponse<CustomerDetailsResponseDto>>;
  findCustomersUsers(id: number): Promise<ApiResponse<User[]>>;
  findLicencesForCustomer(id: number): Promise<ApiResponse<CustomerLicence[]>>;
  list(): Promise<ApiResponse<Customer[]>>;
  listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Customer[]>>>;
  read(id: number): Promise<ApiResponse<Customer>>;
  update(id: number, data: CustomerCreationDto): Promise<ApiResponse<void>>;
}

export class CustomerApi implements ICustomerApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.CUSTOMER;
    this.apiClient = apiClient;
  }

  async countApplications(): Promise<ApiResponse<number>> {
    return await this.apiClient.get<number>(`${this.apiBase}/applications`);
  }

  async create(data: CustomerCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<CustomerCreationDto, number>(
      this.apiBase,
      data
    );
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async getDetailsById(
    id: number
  ): Promise<ApiResponse<CustomerDetailsResponseDto>> {
    return await this.apiClient.get<CustomerDetailsResponseDto>(
      `${this.apiBase}/${id}/details`
    );
  }

  async getDetailsByParams(
    params: CustomerDetailsSearchParams
  ): Promise<ApiResponse<CustomerDetailsResponseDto>> {
    return await this.apiClient.get<CustomerDetailsResponseDto>(
      `${this.apiBase}/details`,
      { params: new URLSearchParams({ ...params }) }
    );
  }

  async findCustomersUsers(id: number): Promise<ApiResponse<User[]>> {
    return await this.apiClient.get<User[]>(`${this.apiBase}/${id}/users`);
  }

  async findLicencesForCustomer(
    id: number
  ): Promise<ApiResponse<CustomerLicence[]>> {
    return await this.apiClient.get<CustomerLicence[]>(
      `${this.apiBase}/${id}/licences`
    );
  }

  async list(): Promise<ApiResponse<Customer[]>> {
    return await this.apiClient.get<Customer[]>(this.apiBase);
  }

  async listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Customer[]>>> {
    return await this.apiClient.get<PageResponse<Customer[]>>(`${this.apiBase}/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async read(id: number): Promise<ApiResponse<Customer>> {
    return await this.apiClient.get<Customer>(`${this.apiBase}/${id}`);
  }

  async update(
    id: number,
    data: CustomerCreationDto
  ): Promise<ApiResponse<void>> {
    return await this.apiClient.put<CustomerCreationDto, void>(
      `${this.apiBase}/${id}`,
      data
    );
  }
}
