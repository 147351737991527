import { Customer, CustomerLicence, LicenceStatus, RenewalType, User, useRest, UserRole } from "@wacp/api";
import {
  BaseRoute,
  containsIgnoreCase,
  humanise,
  OverviewLayout,
  tableSortCompare,
  useBreadcrumbs,
  WolkTable,
  WolkTableAction,
  WolkTableFilter,
  WolkTableSortDirection
} from "../../../index";
import {FormEvent, useContext, useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import { AuthorisedUser } from '@wacp/auth';
import {ConfigContext} from "@wacp/context";

/* eslint-disable-next-line */
export interface OrganizationDetailsLicencesProps {
}

export function OrganizationDetailsLicences(props: OrganizationDetailsLicencesProps) {
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const appConfig = useContext(ConfigContext);
  const navigate = useNavigate();
  const { organisation, currentUser, authorisedUser } = useOutletContext<{
    organisation: Customer ;
    currentUser: User;
    authorisedUser: AuthorisedUser;
  }>();

  const [data, setData] = useState<CustomerLicence[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [makeFilterSortRequest, setMakeFilterSortRequest] = useState(false);
  const [sort, setSort] = useState({
    name: 'validToDate',
    direction: WolkTableSortDirection.DESCENDING,
  });

  useEffect(() => {
    if (organisation) {
      if (currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        breadcrumbs.setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: `${organisation.fullName}`,
            path: BaseRoute.MY_ORGANISATION,
          },
        ]);
      } else {
        breadcrumbs.setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: BaseRoute.ORGANISATIONS,
          },
          {
            name: `${organisation.fullName}`,
            path: `${organisation.id}`,
          },
        ]);
      }

      fetchData();
    }
  }, [organisation, currentUser, authorisedUser]);

  useEffect(() => {
    if (makeFilterSortRequest) {
      fetchData();
      setMakeFilterSortRequest(false);
    }
  }, [makeFilterSortRequest]);

  const openDetails = (rowData: CustomerLicence) => {
    navigate(`${rowData.id}`);
  };

  const addNewLicence = () => {
    navigate(`create`)
  };

  const clearFilter = () => {
    setNameFilter('');
    setStatusFilter('');
    setMakeFilterSortRequest(true);
  };

  const applyFilter = (event: FormEvent) => {
    event.preventDefault();
    setMakeFilterSortRequest(true);
  };

  const onSort = (field: string, direction: WolkTableSortDirection) => {
    setSort({
      name: field,
      direction: direction,
    });
    setMakeFilterSortRequest(true);
  };

  // TODO Remove and let backend deal with this.
  const mapSortAccessor = (
    sortName: string
  ): ((key: CustomerLicence) => string) => {
    switch (sortName) {
      case 'licenceId':
        return (key) => key.licenceId;
      case 'customer.ourName':
        return (key) => key.customer.ourName;
      case 'product.name':
        return (key) => key.product.name;
      case 'validFromDate':
        return (key) => String(key.validFromDate);
      case 'validToDate':
        return (key) => String(key.validToDate);
      case 'licenceStatus':
        return (key) => key.licenceStatus;
      case 'licenceType':
        return (key) => key.licenceType;
      default:
        return (key) => '' + key.id;
    }
  };

  function fetchData() {
    rest?.customerApi
      .findLicencesForCustomer(organisation.id)
      .then(({ data }) => {
        const finalData = data
          .filter((customerLicence) =>
            containsIgnoreCase(customerLicence.licenceId, nameFilter)
          )
          .filter(
            (customerLicence) =>
              statusFilter === 'All' ||
              containsIgnoreCase(customerLicence.licenceStatus, statusFilter)
          )
          .sort((first, second) =>
            tableSortCompare(
              first,
              second,
              mapSortAccessor(sort.name),
              sort.direction
            )
          );

        setData(finalData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={'layout layout-full p-0'}>
      <OverviewLayout
        actions={[
          <WolkTableAction icon={<PlusLg />} onClick={addNewLicence} />,
        ]}
        filter={
          <WolkTableFilter
            onClearFilter={clearFilter}
            onApplyFilter={applyFilter}
            filterValues={[nameFilter, statusFilter]}
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={nameFilter}
              onChange={(event) => setNameFilter(event.target.value)}
            />
            <Form.Select
              value={statusFilter}
              onChange={(event) => {
                setStatusFilter(event.target.value);
                applyFilter(event);
              }}
            >
              <option>All</option>
              {Object.keys(LicenceStatus).map((value, index) => (
                <option key={`${value}_${index}`}>{humanise(value)}</option>
              ))}
            </Form.Select>
          </WolkTableFilter>
        }
        table={
          <WolkTable
            columnConfigurations={[
              {
                header: 'Licence ID',
                mapper: (rowData) => rowData.licenceId,
                onSort: (direction) => onSort('licenceId', direction),
              },
              {
                header: 'Product',
                mapper: (rowData) => rowData.product.name,
                onSort: (direction) => onSort('product.name', direction),
              },
              {
                header: 'Valid From',
                mapper: (rowData) => rowData.insertDate ? moment(rowData.insertDate).format('DD/MM/yyyy') : '-',
                onSort: (direction) => onSort('insertDate', direction),
              },
              {
                header: 'Expiry Date',
                mapper: (rowData) => rowData.validToDate ? moment(rowData.validToDate).format('DD/MM/yyyy') : '-',
                onSort: (direction) => onSort('validToDate', direction),
                backgroundColor: (rowData) => {
                  const thresholdInDays = appConfig?.expiringSoonThresholdInDays ?? 7;
                  const current = moment().startOf('day');
                  const diff = moment(rowData.validToDate).diff(current, "days");
                  return rowData.renewalType === RenewalType.ONE_TIME
                     && diff < thresholdInDays && diff > 0 ? "orange" : undefined;
                },
              },
              {
                header: 'Renewal Type',
                mapper: (rowData) => humanise(rowData.renewalType),
                onSort: (direction) => onSort('renewalType', direction),
              },
              {
                header: 'Status',
                mapper: (rowData) => humanise(rowData.licenceStatus),
                onSort: (direction) => onSort('licenceStatus', direction),
              },
              {
                header: 'Type',
                mapper: (rowData) => humanise(rowData.licenceType),
                onSort: (direction) => onSort('licenceType', direction),
              },
            ]}
            data={data}
            onRowClicked={openDetails}
          />
        }
      />
    </div>
  );
}

export default OrganizationDetailsLicences;
