import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { Feature, FeatureCreationDto, FeatureUpdateDto } from './model';

export interface IFeatureApiClient {
  changeModuleStatus(id: number, data: FeatureUpdateDto): Promise<ApiResponse<void>>;
  create(data: FeatureCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<Feature[]>>;
  read(id: number): Promise<ApiResponse<Feature>>;
  update(id: number, data: FeatureCreationDto): Promise<ApiResponse<void>>;
}

export class FeatureApi implements IFeatureApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.FEATURE;
    this.apiClient = apiClient;
  }

  async changeModuleStatus(id: number, data: FeatureUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<FeatureUpdateDto, void>(`${this.apiBase}/${id}/update`, data);
  }

  async create(data: FeatureCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<FeatureCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<Feature[]>> {
    return await this.apiClient.get<Feature[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<Feature>> {
    return await this.apiClient.get<Feature>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: FeatureCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<FeatureCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
