import { ApiResponse, IApiClient, PageResponse } from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { EmailSearchParams, PageRequest, User } from '../+shared';
import { UserCreationDto } from './model';

export interface IUserApiClient {
  create(data: UserCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<User[]>>;
  listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<User[]>>>;
  read(id: number): Promise<ApiResponse<User>>;
  update(id: number, data: UserCreationDto): Promise<ApiResponse<void>>;
  getCurrentUser(): Promise<ApiResponse<User>>;
  checkIfEmailExists(params: EmailSearchParams): Promise<ApiResponse<boolean>>;
}

export class UserApi implements IUserApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.USER;
    this.apiClient = apiClient;
  }

  async create(data: UserCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<UserCreationDto, number>(
      this.apiBase,
      data
    );
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<User[]>> {
    return await this.apiClient.get<User[]>(this.apiBase);
  }

  async listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<User[]>>> {
    return await this.apiClient.get<PageResponse<User[]>>(`${this.apiBase}/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async read(id: number): Promise<ApiResponse<User>> {
    return await this.apiClient.get<User>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: UserCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<UserCreationDto, void>(
      `${this.apiBase}/${id}`,
      data
    );
  }

  async getCurrentUser(): Promise<ApiResponse<User>> {
    return await this.apiClient.get<User>(`${this.apiBase}/user`);
  }

  async checkIfEmailExists(params: EmailSearchParams): Promise<ApiResponse<boolean>> {
    return await this.apiClient.get<boolean>(`${this.apiBase}/emailExists`, { params: new URLSearchParams({ ...params }) });
  }
}
