import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import {
  VersionCompareResponseDto,
  VersionCreationDto, VersionOfAvailableModulesForCustomerQueryParameters, VersionOfFeaturesInVersionQueryParameters,
  VersionOfQueryParameters,
  VersionStatusUpdateDto
} from './model';
import { Module, ModuleResponseDto, Version } from '../+shared';
import { Feature } from '../feature';

export interface IVersionApiClient {
  changeReleaseStatus(id: number, data: VersionStatusUpdateDto): Promise<ApiResponse<void>>;
  create(data: VersionCreationDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<Version[]>>;
  listOf(params: VersionOfQueryParameters): Promise<ApiResponse<VersionCompareResponseDto[]>>;
  listOfAvailableModulesForCustomer(id: number, params: VersionOfAvailableModulesForCustomerQueryParameters): Promise<ApiResponse<Module[]>>;
  listOfFeaturesInVersion(id: number, params: VersionOfFeaturesInVersionQueryParameters): Promise<ApiResponse<Feature[]>>;
  listOfModulesInVersion(id: number): Promise<ApiResponse<ModuleResponseDto[]>>;
  read(id: number): Promise<ApiResponse<Version>>;
  update(id: number, data: VersionCreationDto): Promise<ApiResponse<void>>;
}

export class VersionApi implements IVersionApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.VERSION;
    this.apiClient = apiClient;
  }

  async changeReleaseStatus(id: number, data: VersionStatusUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<VersionStatusUpdateDto, void>(`${this.apiBase}/${id}/changeStatus`, data);
  }

  async create(data: VersionCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<VersionCreationDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<Version[]>> {
    return await this.apiClient.get<Version[]>(this.apiBase);
  }

  async listOf(params: VersionOfQueryParameters): Promise<ApiResponse<VersionCompareResponseDto[]>> {
    return await this.apiClient.get<VersionCompareResponseDto[]>(`${this.apiBase}/compare`, { params: new URLSearchParams({ ...params }) });
  }

  async listOfAvailableModulesForCustomer(id: number, params: VersionOfAvailableModulesForCustomerQueryParameters): Promise<ApiResponse<Module[]>> {
    return await this.apiClient.get<Module[]>(`${this.apiBase}/${id}/availableModules`, { params: new URLSearchParams({ ...params }) });
  }

  async listOfFeaturesInVersion(id: number, params: VersionOfFeaturesInVersionQueryParameters): Promise<ApiResponse<Feature[]>> {
    return await this.apiClient.get<Feature[]>(`${this.apiBase}/${id}/features`, { params: new URLSearchParams({ ...params }) });
  }

  async listOfModulesInVersion(id: number): Promise<ApiResponse<ModuleResponseDto[]>> {
    return await this.apiClient.get<ModuleResponseDto[]>(`${this.apiBase}/${id}/modules`);
  }

  async read(id: number): Promise<ApiResponse<Version>> {
    return await this.apiClient.get<Version>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: VersionCreationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<VersionCreationDto, void>(`${this.apiBase}/${id}`, data);
  }
}
