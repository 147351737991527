import styles from './app-card.module.scss';
import { Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import { ReactElement } from 'react';

/* eslint-disable-next-line */
export interface AppCardProps {
  name: string;
  path: string;
  notificationComponent: ReactElement;
}

export function AppCard({
    name,
    path,
    notificationComponent
  }: AppCardProps) {
  return (
    <Link to={path} className={`${styles['app-link']}`}>
      <Card className={`${styles['app-card']}`}>
        <Card.Body>
          <Card.Title className={`text-center`}>{name}</Card.Title>
          <Card.Text as="div" className={`${styles['app-card-text']}`}>
            {notificationComponent}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}

export default AppCard;
