import NumberFormat from 'react-number-format';

export function WolkCurrency({price}: {
  price: number
}) {
  return (
    <NumberFormat value={price}
                  displayType={'text'}
                  thousandSeparator='.'
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale={true} />
  );
}

export default WolkCurrency;
