import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import { Placement } from 'react-bootstrap/types';
import styles from './popover.module.scss';

/* eslint-disable-next-line */
export interface WolkPopoverProps {
  children: React.ReactElement;
  buttonTitle: string | React.ReactElement;
  trigger?: OverlayTriggerType;
  placement?: Placement;
}

export function WolkPopover({
  children,
  buttonTitle,
  trigger = 'focus',
  placement = 'bottom',
}: WolkPopoverProps) {
  const popover = (
    <Popover id="wolk-popover" className={`${styles['popover']}`}>
      <Popover.Body className={`${styles['popover-body']}`}>
        {children}
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger trigger={trigger} placement={placement} overlay={popover}>
      <Button variant="dark">{buttonTitle}</Button>
    </OverlayTrigger>
  );
}

export default WolkPopover;
