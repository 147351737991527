import { ApiResponse, IApiClient, PageResponse} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { Resource, ResourceCreationDto } from './model';
import { PageRequest } from '../+shared';

export interface IResourceApiClient {
  create(data: ResourceCreationDto): Promise<ApiResponse<number>>;
  createWithFile(data: ResourceCreationDto, file: any): Promise<ApiResponse<void>>;
  delete(id: number): Promise<ApiResponse<void>>;
  list(): Promise<ApiResponse<Resource[]>>;
  listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Resource[]>>>;
  read(id: number): Promise<ApiResponse<Resource>>;
  update(id: number, data: ResourceCreationDto): Promise<ApiResponse<void>>;
  updateWithFile(id: number, data: ResourceCreationDto, file: any): Promise<ApiResponse<void>>;
  downloadFile(id: number): void;
}

export class ResourceApi implements IResourceApiClient {
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.RESOURCE;
    this.apiClient = apiClient;
  }

  async create(data: ResourceCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<ResourceCreationDto, number>(
      this.apiBase,
      data
    );
  }

  async createWithFile(data: ResourceCreationDto, file: any): Promise<ApiResponse<void>> {
    const formData = new FormData();
    formData.append('file',file);
    formData.append('dto', new Blob([JSON.stringify(data)], { type: 'application/json' }));

    return await this.apiClient.post<FormData, void>(`${this.apiBase}/uploadFiles`, formData);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  async list(): Promise<ApiResponse<Resource[]>> {
    return await this.apiClient.get<Resource[]>(this.apiBase);
  }

  async listPaged(pageRequest: PageRequest): Promise<ApiResponse<PageResponse<Resource[]>>> {
    return await this.apiClient.get<PageResponse<Resource[]>>(`${this.apiBase}/paged`, { params: new URLSearchParams({ ...pageRequest }) });
  }

  async read(id: number): Promise<ApiResponse<Resource>> {
    return await this.apiClient.get<Resource>(`${this.apiBase}/${id}`);
  }

  async update(id: number, data: ResourceCreationDto, file?: any): Promise<ApiResponse<void>> {
    return await this.apiClient.put<ResourceCreationDto, void>(`${this.apiBase}/${id}`, data);
  }

  async updateWithFile(id: number, data: ResourceCreationDto, file: any): Promise<ApiResponse<void>> {
    const formData = new FormData();
    formData.append('file',file);
    formData.append('dto', new Blob([JSON.stringify(data)], { type: 'application/json' }));

    return await this.apiClient.put<FormData, void>(`${this.apiBase}/${id}/uploadFiles`, formData);
  }

  downloadFile(id: number): void {
    this.apiClient.downloadFile(`${this.apiBase}/files/${id}`);
  }
}
