import { Customer, User, useRest, UserRole } from '@wacp/api';
import {
  BaseRoute,
  containsIgnoreCase,
  OverviewLayout,
  tableSortCompare, useBreadcrumbs,
  WolkTable,
  WolkTableAction,
  WolkTableFilter,
  WolkTableSortDirection,
} from '../../../index';
import {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';
import { PlusLg } from "react-bootstrap-icons";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AuthorisedUser } from '@wacp/auth';

/* eslint-disable-next-line */
export interface OrganizationDetailsUsersProps {
  // organisation: Customer;
}

export function OrganizationDetailsUsers(props: OrganizationDetailsUsersProps) {
  const [nameFilter, setNameFilter] = useState('');
  const [data, setData] = useState<User[]>([]);
  const [sort, setSort] = useState({
    name: 'name',
    direction: WolkTableSortDirection.ASCENDING,
  });

  const breadcrumbs = useBreadcrumbs();
  const navigation = useNavigate();
  const rest = useRest();
  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  useEffect(() => {
    if (organisation) {
      if (currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        breadcrumbs.setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `/${BaseRoute.MY_ORGANISATION}`,
          },
        ]);
      } else {
        breadcrumbs.setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: BaseRoute.ORGANISATIONS,
          },
          {
            name: `${organisation.fullName}`,
            path: `${organisation.id}`,
          },
        ]);
      }

      fetchData();
    }
  }, [organisation, currentUser, authorisedUser])

  const openDetails = (rowData: User) => {
    navigation(`${rowData.id}`, { state: { user: rowData, organisation: organisation } });
  };

  const addNewUser = () => {
    navigation('invite');
  }

  const clearFilter = () => {
    setNameFilter('');
    fetchData();
  };

  const applyFilter = () => {
    fetchData();
  };

  const onSort = (field: string, direction: WolkTableSortDirection) => {
    setSort({
      name: field,
      direction: direction,
    });

    fetchData();
  };

  const mapSortAccessor = (sortName: string): ((key: User) => string) => {
    switch (sortName) {
      case 'name':
        return (key) => key.name;
      case 'email':
        return (key) => key.email;
      default:
        return (key) => '' + key.id;
    }
  };

  function fetchData() {
    rest?.customerApi.findCustomersUsers(organisation.id).then(({ data }) => {
      const finalData = data.filter(
        (user) =>
          containsIgnoreCase(user.name, nameFilter) ||
          containsIgnoreCase(user.email, nameFilter)
      ).sort((first, second) =>
        tableSortCompare(first, second, mapSortAccessor(sort.name), sort.direction)
      );

      setData(finalData);
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className={'layout layout-full p-0'}>
      <OverviewLayout
        actions={[
          <WolkTableAction icon={<PlusLg/>} onClick={addNewUser}/>
        ]}
        filter={
          <WolkTableFilter
            onClearFilter={clearFilter}
            onApplyFilter={applyFilter}
            filterValues={[nameFilter]}
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={nameFilter}
              onChange={(event) => setNameFilter(event.target.value)}
            />
          </WolkTableFilter>
        }
        table={
          <WolkTable
            columnConfigurations={[
              {
                header: 'Name',
                mapper: (rowData) => rowData.name,
                onSort: (direction) => onSort('name', direction),
              },
              {
                header: 'Email',
                mapper: (rowData) => rowData.email,
                onSort: (direction) => onSort('email', direction),
              },
              {
                header: 'Job title',
                mapper: (rowData) => rowData.jobTitle,
                onSort: (direction) => onSort('jobTitle', direction),
              },
              {
                header: 'Status',
                mapper: (rowData) => rowData.userStatus,
                onSort: (direction) => onSort('userStatus', direction),
              },
            ]}
            data={data}
            onRowClicked={openDetails}
          />
        }
      />
    </div>
  );
}

export default OrganizationDetailsUsers;
