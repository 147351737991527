import styles from './upsert-wrapper.module.scss';
import React from "react";

/* eslint-disable-next-line */
export interface UpsertWrapperProps {
  children: [
    React.ReactElement,
    React.ReactElement | false
  ];
}

export function WolkUpsertWrapper({ children: [form, buttonWrapper] }: UpsertWrapperProps) {
  return (
    <div className={`d-flex flex-column w-100 h-100`}>
      <div className={`${styles['upsert-form-wrapper']} flex-fill`}>
        <div className={`overflow-auto h-100 w-100`}>
          {!!form && form}
        </div>
      </div>
      {
        !!buttonWrapper &&
        <div
          className={`${styles['upsert-buttons-wrapper']} d-flex flex-row justify-content-end align-items-center`}
        >
          {buttonWrapper}
        </div>
      }
    </div>
  );
}

export default WolkUpsertWrapper;
