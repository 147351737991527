import { ApiResponse, IApiClient, RequestConfig } from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { RepoDto } from './model';
import { saveAs } from 'file-saver';
import { Repo } from '../+shared';

export interface IRepoApiClient {
  create(data: RepoDto): Promise<ApiResponse<number>>;
  delete(id: number): Promise<ApiResponse<void>>;
  downloadCredentialsFile(id: number): void;
  list(): Promise<ApiResponse<Repo[]>>;
  read(id: number): Promise<ApiResponse<Repo>>;
  setCredentials(id: number, file: Blob): Promise<ApiResponse<void>>;
  update(id: number, data: RepoDto): Promise<ApiResponse<void>>;
}

export class RepoApi implements IRepoApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.REPO;
    this.apiClient = apiClient;
  }

  async create(data: RepoDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<RepoDto, number>(this.apiBase, data);
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    return await this.apiClient.delete<void>(`${this.apiBase}/${id}`);
  }

  downloadCredentialsFile(id: number): void {
    this.apiClient.downloadFile(`${this.apiBase}/${id}/downloadCredentialsFile`);
  }

  async list(): Promise<ApiResponse<Repo[]>> {
    return await this.apiClient.get<Repo[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<Repo>> {
    return await this.apiClient.get<Repo>(`${this.apiBase}/${id}`);
  }

  async setCredentials(id: number, file: Blob): Promise<ApiResponse<void>> {
    const formData = new FormData();
    formData.append('file',file);
    const config: RequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return await this.apiClient.post<FormData, void>(`${this.apiBase}/${id}/setCredentials`, formData, config);
  }

  async update(id: number, data: RepoDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<RepoDto, void>(`${this.apiBase}/${id}`, data);
  }
}
