import {WolkTableColumnConfiguration} from "@wacp/shared-components";

export interface WolkTableRowProps<T> {
  rowData: T;
  columnConfigurations: WolkTableColumnConfiguration<T>[];
  onClick: (rowData: T) => void;
}

export function WolkTableRow<T>(props: WolkTableRowProps<T>) {
  const totalWeight = props.columnConfigurations.reduce((sum, config) => sum + (config.weight ?? 1), 0);
  return (
    <tr onClick={() => !!props.onClick && props.onClick(props.rowData)} role={!!props.onClick && 'button'}>
      {
        props.columnConfigurations.filter(config => !config.hideRow).map((columnConfiguration, index) => {
          return (
            <td key={index} style={{width: `${((columnConfiguration.weight ?? 1) / totalWeight) * 100}%`, backgroundColor: `${!!columnConfiguration.backgroundColor && columnConfiguration.backgroundColor(props.rowData) !== undefined ? columnConfiguration.backgroundColor(props.rowData) : ''}` }}>
              {columnConfiguration.mapper(props.rowData)}
            </td>
          )
        })
      }
    </tr>
  );
}

export default WolkTableRow;
