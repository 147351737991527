import style from "./wolk-table.module.scss"
import {ReactNode} from "react";
import {Button} from "react-bootstrap";

export interface WolkTableActionProps {
  icon: ReactNode,
  onClick: () => void
}

export function WolkTableAction({ icon, onClick }: WolkTableActionProps) {
  return (
    <Button onClick={onClick} className={`${style['action']} d-flex flex-row align-items-center`}>
      {
        icon
      }
    </Button>
  );
}

export default WolkTableAction;
