import { ReactElement, useEffect, useState } from 'react';
import { useBreadcrumbs } from '../breadcrumbs/breadcrumbs-provider';
import AppCard from './app-card/app-card';
import { User, useRest, UserRole } from '@wacp/api';
import { AuthorisedUser, useAuth } from '@wacp/auth';

export interface AppConfig {
  name: string;
  path: string;
  component: ReactElement;
  notificationComponent: ReactElement;
  visibleForRoles: UserRole[];
}

/* eslint-disable-next-line */
export interface AppsProps {
  children: AppConfig[];
}

export function Apps({ children }: AppsProps) {
  const [currentUser, setCurrentUser] = useState<User>();
  const [authorisedUser, setAuthorisedUser] = useState<AuthorisedUser>();
  const breadcrumbs = useBreadcrumbs();
  const rest = useRest();
  const auth = useAuth();

  useEffect(() => {
    if (rest && auth) {
      auth.getActiveUser().then((activeUser) => {
        if (activeUser) {
          setAuthorisedUser(activeUser);
          rest.userApi.getCurrentUser().then(({ data: user }) => {
            if (user) {
              setCurrentUser(user);
            }
          })
        }
      });
    }
  }, [rest, auth]);

  useEffect(() => {
    breadcrumbs.setBreadcrumbs([]);
  }, []);

  return (
    <div className={`layout layout-full d-flex justify-content-center align-items-center`}>
      {
        !!authorisedUser &&
        <div className={`d-flex align-items-stretch`}>
          {children.filter(child => child.visibleForRoles.includes(authorisedUser.role)).map((app: AppConfig, i: number) => (
            <AppCard key={`${app.name}_${i}`} name={app.name} path={app.path} notificationComponent={app.notificationComponent} />
          ))}
        </div>
      }
    </div>
  );
}

export default Apps;
