import { AppConfig, Apps, LoadingIndicator } from '@wacp/shared-components';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './app';
import { appsConfig } from './apps.config';
import { MainConfig } from '@wacp/context';

export default function AppRouter({ config }: { config: MainConfig }) {
  const apps: AppConfig[] = appsConfig;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App config={config} />}>
          <Route index element={<Apps>{apps}</Apps>} />
          {apps.map((a, i) => {
            return (
              <Route
                key={`${a.name}_${i}`}
                path={`${a.path}/*`}
                element={
                  <React.Suspense fallback={<LoadingIndicator/>}>
                    {a.component}
                  </React.Suspense>
                }
              />
            );
          })}
          <Route path="*" element={<Apps>{apps}</Apps>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
