import {
  BaseRoute,
  EMAIL_REGEX,
  humanise, PHONE_REGEX,
  PHONE_REGEX_EXAMPLE,
  useBreadcrumbs,
  WolkUpsertWrapper
} from "../../../../index";
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Customer, User, UserCreationDto, useRest, UserRole, UserStatus, } from '@wacp/api';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AuthorisedUser, useAuth } from '@wacp/auth';

export interface UserUpsertForm {
  name: string;
  email: string;
  phone: string;
  jobTitle: string;
}

export function mapUserFormToDTO(userForm: UserUpsertForm, organisationId: number, insertUserId: number, updateUserId?: number): UserCreationDto {
  return {
    userRole: UserRole.CUSTOMER,
    customerId: organisationId,
    name: userForm.name,
    email: userForm.email,
    phone: userForm.phone,
    jobTitle: userForm.jobTitle,
    userStatus: 'ACTIVE',
    insertUser: insertUserId,
    updateUser: updateUserId,
  }
}

/* eslint-disable-next-line */
export interface UpsertUserProps {
  isInViewMode?: boolean;
}

export function UpsertUser({ isInViewMode }: UpsertUserProps) {
  const { userId } = useParams();
  const [loadingUser, setLoadingUser] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const rest = useRest();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const { organisation, currentUser, authorisedUser } =
    useOutletContext<{
      organisation: Customer;
      currentUser: User;
      authorisedUser: AuthorisedUser;
    }>();

  const {
    handleSubmit,
    reset,
    clearErrors,
    control,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setIsAdmin(authorisedUser?.role === UserRole.BD);
  }, [authorisedUser]);

  useEffect(() => {
    if (organisation) {
      if (currentUser && authorisedUser && authorisedUser.role === UserRole.CUSTOMER) {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: organisation.fullName,
            path: `/${BaseRoute.MY_ORGANISATION}`,
          },
          {
            name: userId ? 'Edit User' : 'Invite User',
            path: userId ? `users/${userId}/edit` : 'users/invite',
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Organisations',
            path: BaseRoute.ORGANISATIONS,
          },
          {
            name: `${organisation?.fullName}`,
            path: `${BaseRoute.ORGANISATIONS}/${organisation?.id}`,
          },
          {
            name: userId ? 'Edit User' : 'Invite User',
            path: userId ? 'edit' : 'invite',
          },
        ]);
      }
    }
  }, [setBreadcrumbs, organisation, currentUser, authorisedUser]);

  useEffect(() => {
    if (!userId) {
      setLoadingUser(false);
    } else {
      rest?.userApi
        .read(+userId)
        .then(({ data }) => {
          reset(data);
          setLoadingUser(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, rest]);

  const handleOnCancel = () => {
    reset();
    goBack();
  };

  const handleOnSubmit = (formValue: any) => {
    if (organisation && currentUser && authorisedUser) {
      setRequestInProgress(true);

      const userDto = mapUserFormToDTO(formValue, organisation.id, currentUser.id);

      if (userId) {
        updateUserAsAdmin(+userId, userDto);
      } else {
        inviteUserAsAdmin(userDto);
      }
    }
  };

  function inviteUserAsAdmin(userDto: UserCreationDto) {
    rest?.userApi
      .create(userDto)
      .then(({ data }) => {
        setRequestInProgress(false);
        goBack();
        toast('User successfully invited!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        setRequestInProgress(false);
      });
  }

  function updateUserAsAdmin(id: number, userDto: UserCreationDto) {
    rest?.userApi
      .update(id, userDto)
      .then(({ data }) => {
        setRequestInProgress(false);
        goBack();
        toast('User successfully updated!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        setRequestInProgress(false);
      });
  }

  const emailIsUnique = async (email: string) => {
    if (userId) {
      return true;
    } else {
      const response = await rest.userApi.checkIfEmailExists({ email });
      return !response.data;
    }
  };

  function goBack() {
    navigate(-1);
  }

  const getButtonTitle = (): string => {
    if (userId) {
      return 'Update user';
    } else {
      return 'Invite user';
    }
  }

  return (
    <div className={`layout layout-full`}>
      {!loadingUser && (
        <WolkUpsertWrapper>
          <form className={`d-flex flex-column w-100`}>
            {
              !isInViewMode &&
              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  Name:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        onFocus={() => clearErrors("name")}
                        {...field}
                      />
                    )}
                  />
                  {errors['name']?.type === 'required' && (
                    <Form.Text className={`form-error`}>Name is required</Form.Text>
                  )}
                </Col>
              </Form.Group>
            }

            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Email:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: EMAIL_REGEX,
                    validate: emailIsUnique
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <Form.Control type="text"
                                  placeholder="Email" {...field}
                                  onFocus={() => clearErrors("email")}
                                  disabled={isInViewMode || userId !== undefined}/>
                  )}
                />
                {errors['email']?.type === 'required' && (
                  <Form.Text className={`form-error`}>Email is required</Form.Text>
                )}
                {errors['email']?.type === 'pattern' && (
                  <Form.Text className={`form-error`}>
                    Wrong email format
                  </Form.Text>
                )}
                {errors['email']?.type === 'validate' && (
                  <Form.Text className={`form-error`}>
                    This email address already exists
                  </Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Phone number:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={''}
                  rules={{
                    pattern: PHONE_REGEX,
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder={`Include an international country code (+ or 00), e.g. ${PHONE_REGEX_EXAMPLE}`}
                      onFocus={() => clearErrors("phone")}
                      disabled={isInViewMode}
                      {...field}
                    />
                  )}
                />
                {errors['phone']?.type === 'required' && (
                  <Form.Text className={`form-error`}>Phone number is required</Form.Text>
                )}
                {errors['phone']?.type === 'pattern' && (
                  <Form.Text className={`form-error`}>
                    Please enter a phone number that contains the international
                    country code, e.g.
                    {PHONE_REGEX_EXAMPLE}
                  </Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`w-100 mb-3`}>
              <Form.Label column sm="4">
                Job title:
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="jobTitle"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={''}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Job title"
                      disabled={isInViewMode}
                      {...field}
                    />
                  )}
                />
                {errors['jobTitle']?.type === 'required' && (
                  <Form.Text className={`form-error`}>Job title is required</Form.Text>
                )}
              </Col>
            </Form.Group>

            {
              isInViewMode &&
              !!authorisedUser &&
              authorisedUser.role === UserRole.BD &&
              <Form.Group as={Row} className={`w-100 mb-3`}>
                <Form.Label column sm="4">
                  User status:
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="userStatus"
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        placeholder="User Status"
                        {...field}
                        disabled={true}
                      >
                        {Object.keys(UserStatus).map((t, i) => (
                          <option key={`${t}_${i}`} value={t}>
                            {humanise(t)}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                </Col>
              </Form.Group>
            }
          </form>
          {
            !isInViewMode &&
            <div
              className={`d-flex flex-row justify-content-end align-items-center`}
            >
              {requestInProgress && (
                <div
                  className={`d-flex flex-row justify-content-center align-items-center me-1`}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              <Button
                variant={`secondary`}
                disabled={requestInProgress}
                onClick={handleOnCancel}
                className={`me-1`}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant={`primary`}
                disabled={!isDirty || !isValid || requestInProgress}
                onClick={handleSubmit(handleOnSubmit)}
              >
                {getButtonTitle()}
              </Button>
            </div>
          }
        </WolkUpsertWrapper>
      )}
      {!!loadingUser && (
        <div
          className={`w-100 h-100 d-flex flex-column justify-content-center align-items-center`}
        >
          <Spinner animation="border" variant="primary" />
          <span className="">Loading user...</span>
        </div>
      )}
    </div>
  );
}

export default UpsertUser;
