import { Spinner } from "react-bootstrap";

/* eslint-disable-next-line */
export interface LoadingIndicatorProps {}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <div
      className={`w-100 h-100 d-flex flex-column justify-content-center align-items-center`}
    >
      <Spinner animation="border" variant="primary" />
      <span className="">Loading...</span>
    </div>
  );
}

export default LoadingIndicator;
