import styles from './licence-notifications.module.scss';
import { Badge, Stack } from "react-bootstrap";
import { LicenceStatus, useRest } from '@wacp/api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface LicenceNotificationsProps {
  path: string;
}

export function LicenceNotifications({path}: LicenceNotificationsProps) {
  const [newRequests, setNewRequests] = useState<number | null>(null);
  const [expiringSoon, setExpiringSoon] = useState<number | null>(null);

  const rest = useRest();

  useEffect(() => {
    rest.customerLicenceApi.countApplications().then((response) => {setNewRequests(response.data)});
    rest.customerLicenceApi.countExpiring().then((response) => {setExpiringSoon(response.data)});
  }, [rest]);

  return (
    <Stack className={`text-center`}>
      {
        !!newRequests &&
          <Link to={`${path}?status=${LicenceStatus.REQUESTED}`} className={`${styles['link']}`}>
            <span><Badge bg="success">{newRequests}</Badge> new request{newRequests > 1 ? 's' : ''}</span>
          </Link>
      }
      {
        !!expiringSoon &&
          <Link to={`${path}?expiringSoon=true`} className={`${styles['link']}`}>
            <span><Badge bg="warning">{expiringSoon}</Badge> expiring soon</span>
          </Link>
      }
    </Stack>
  );
}

export default LicenceNotifications;
