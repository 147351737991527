import { ReactNode, useState } from 'react';
import { Table } from 'react-bootstrap';
import { compare } from '../util/string-utils';
import WolkTableRow from './wolk-table-row';
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import styles from './wolk-table.module.scss';

export interface WolkTableProps<T> {
  columnConfigurations: WolkTableColumnConfiguration<T>[];
  data: T[];
  onRowClicked?: (rowData: T) => void;
}

export interface WolkTableColumnConfiguration<T> {
  header: string;
  mapper: (rowData: T) => ReactNode;
  onSort?: (direction: WolkTableSortDirection) => void;
  weight?: number;
  backgroundColor?: (rowData: T) => string | undefined;
  hideRow?: boolean;
}

export enum WolkTableSortDirection {
  NONE = 'NONE',
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

interface Sort {
  header: string;
  direction: WolkTableSortDirection;
}

export const tableSortCompare = <R,>(
  first: R,
  second: R,
  accessor: (value: R) => string,
  sortDirection: WolkTableSortDirection
): number => {
  if (sortDirection === WolkTableSortDirection.NONE) {
    return 0;
  }

  const directionModifier =
    sortDirection === WolkTableSortDirection.ASCENDING ? 1 : -1;
  return directionModifier * compare(accessor(first), accessor(second));
};

export function WolkTable<T>(props: WolkTableProps<T>) {
  const [sort, setSort] = useState({
    header: '',
    direction: WolkTableSortDirection.NONE,
  });

  const createSort = (header: string): Sort => {
    if (
      sort.header !== header ||
      sort.direction === WolkTableSortDirection.NONE
    ) {
      return {
        header: header,
        direction: WolkTableSortDirection.ASCENDING,
      };
    } else if (sort.direction === WolkTableSortDirection.ASCENDING) {
      return {
        header: header,
        direction: WolkTableSortDirection.DESCENDING,
      };
    } else if (sort.direction === WolkTableSortDirection.DESCENDING) {
      return {
        header: header,
        direction: WolkTableSortDirection.NONE,
      };
    } else {
      throw Error('Unhandled sort direction: ' + sort.direction);
    }
  };

  const onHeaderClicked = (config: WolkTableColumnConfiguration<T>): void => {
    if (!config.onSort) {
      return;
    }

    const sort = createSort(config.header);
    setSort(sort);
    config.onSort(sort.direction);
  };

  const createSortDirectionIndicator = () => {
    const { NONE, ASCENDING, DESCENDING } = WolkTableSortDirection;
    switch (sort.direction) {
      case ASCENDING:
        return <ArrowDown />;
      case DESCENDING:
        return <ArrowUp />;
      case NONE:
        return <div />;
      default:
        throw Error(`Unhandled sort direction: ${sort.direction}`);
    }
  };

  return (
    <Table striped bordered hover className={`${styles['wolk-table']}`}>
      <thead className={`${styles['header']}`}>
        <tr>
          {props.columnConfigurations.filter((config) => !config.hideRow).map((config) => {
            return (
              <th
                key={config.header}
                onClick={() => onHeaderClicked(config)}
                role={config.onSort ? 'button' : ''}
              >
                <div
                  className={`d-flex flex-row justify-content-between align-items-center`}
                >
                  {config.header}
                  {sort.header === config.header &&
                    createSortDirectionIndicator()}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={`${styles['body']}`}>
        {props.data.map((data, index) => {
          return (
            <WolkTableRow
              key={index}
              rowData={data}
              columnConfigurations={props.columnConfigurations}
              onClick={() => !!props.onRowClicked && props.onRowClicked(data)}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

export default WolkTable;
