import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './page-wrapper.module.scss';
import { useTheme } from "@wacp/shared-components";

/* eslint-disable-next-line */
export interface PageWrapperProps {}

export function PageWrapper(props: PageWrapperProps) {
  const { activeTheme } = useTheme();

  return (
    <div className={`${styles['page-wrapper']} ${activeTheme}`}>
      <Outlet />
    </div>
  );
}

export default PageWrapper;
