import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { ProductPackage} from '../+shared';
import {
  PackageCreationDto,
  PackageLocationDto,
  ProductPackageGetRepoDataQueryParameters, ProductPackageUpdateDto,
  RepoBasicDataDto
} from './model';

export interface IProductPackageApiClient {
  create(data: PackageCreationDto): Promise<ApiResponse<number>>;
  getRepoData(id: number, params: ProductPackageGetRepoDataQueryParameters): Promise<ApiResponse<RepoBasicDataDto>>;
  list(): Promise<ApiResponse<ProductPackage[]>>;
  read(id: number): Promise<ApiResponse<ProductPackage>>;
  setPackageLocation(id: number, data: PackageLocationDto): Promise<ApiResponse<void>>;
  updatePackageStatus(id: number, data: ProductPackageUpdateDto): Promise<ApiResponse<void>>
}

export class ProductPackageApi implements IProductPackageApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.PRODUCT_PACKAGE;
    this.apiClient = apiClient;
  }

  async create(data: PackageCreationDto): Promise<ApiResponse<number>> {
    return await this.apiClient.post<PackageCreationDto, number>(this.apiBase, data);
  }

  async getRepoData(id: number, params: ProductPackageGetRepoDataQueryParameters): Promise<ApiResponse<RepoBasicDataDto>> {
    return await this.apiClient.get<RepoBasicDataDto>(
      `${this.apiBase}/${id}/repo`,
      { params: new URLSearchParams({ ...params }) }
    );
  }

  async list(): Promise<ApiResponse<ProductPackage[]>> {
    return await this.apiClient.get<ProductPackage[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<ProductPackage>> {
    return await this.apiClient.get<ProductPackage>(`${this.apiBase}/${id}`);
  }

  async setPackageLocation(id: number, data: PackageLocationDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<PackageLocationDto, void>(`${this.apiBase}/${id}/setPackageLocation`, data);
  }

  async updatePackageStatus(id: number, data: ProductPackageUpdateDto): Promise<ApiResponse<void>> {
    return await this.apiClient.put<ProductPackageUpdateDto, void>(`${this.apiBase}/${id}`, data);
  }
}
