import { ApiResponse, IApiClient} from '../api-client';
import { ApiEndpoint } from '../api-endpoint.enum';
import { LicenceKey} from '../+shared';

export interface ILicenceKeyApiClient {
  list(): Promise<ApiResponse<LicenceKey[]>>;
  read(id: number): Promise<ApiResponse<LicenceKey>>;
}

export class LicenceKeyApi implements ILicenceKeyApiClient{
  apiBase: string;
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiBase = ApiEndpoint.LICENCE_KEY;
    this.apiClient = apiClient;
  }

  async list(): Promise<ApiResponse<LicenceKey[]>> {
    return await this.apiClient.get<LicenceKey[]>(this.apiBase);
  }

  async read(id: number): Promise<ApiResponse<LicenceKey>> {
    return await this.apiClient.get<LicenceKey>(`${this.apiBase}/${id}`);
  }
}
