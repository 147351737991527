import { Navigate, Route, Routes } from 'react-router-dom';
import Overview from "./overview/overview";
import LicenseList from './license-list/license-list';
import OrganisationList from "./organisation-list/organisation-list";
import OrganisationDetails from './organisation-details/organisation-details';
import LicenseDetails from './license-details/license-details';

/* eslint-disable-next-line */
export interface RequestsProps {}

export function Requests(props: RequestsProps) {

  return (
    <Routes>
      <Route index element={<Navigate to={'licences'}/>} />
      <Route element={<Overview />} >
        <Route path="licences" element={<LicenseList/>} />
        <Route path="licences/:requestId" element={<LicenseDetails/>} />
        <Route path="organisations" element={<OrganisationList/>} />
        <Route path="organisations/:requestId" element={<OrganisationDetails/>} />
      </Route>
      <Route path="*" element={<Overview />}/>
    </Routes>
  );
}

export default Requests;
